import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.primary,
  },
}));

function NotFound() {
  const classes = useStyle();

  return (
    <Typography variant="h3" className={classes.text}>
      No data found
    </Typography>
  );
}

export default NotFound;
