import React from "react";
import { IconButton } from "@material-ui/core";
import { Brightness7, Brightness4 } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import CustomAvatar from "./CustomAvatar";
import { setDarkMode } from "../Redux/Reducers/userReducer";

const useStyle = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 999,
    backgroundColor: theme.palette.background.paper,
    paddingRight: 12,
    boxShadow: "0px 2px 4px rgba(192, 192, 192, 0.25)",
  },
  headerContainerLogo: {
    marginLeft: 16,
  },
  headerRightContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerIconContainer: {
    margin: 8,
    cursor: "pointer",
  },
}));

function Header({ openSignOut, setOpenSignOut }) {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const resetAll = () => {
    setOpenSignOut(false);
  };

  return (
    <div className={classes.headerContainer}>
      <img
        src="/Cw_logo.png"
        alt="WorkAccess_Logo"
        className={classes.headerContainerLogo}
      />

      <div className={classes.headerRightContainer}>
        {/* <div className="inputContainer">
          <input placeholder="Search" />

          <Search />
        </div> */}
        <IconButton
          size="small"
          onClick={() => {
            dispatch(setDarkMode());
          }}
        >
          {userReducerState?.darkMode ? (
            <Brightness7 stye={{ fontSize: 16 }} />
          ) : (
            <Brightness4 stye={{ fontSize: 16 }} />
          )}
        </IconButton>

        <div className={classes.headerIconContainer}>
          <CustomAvatar
            name={userReducerState?.user?.name || ""}
            onClick={(e) => {
              e.stopPropagation();
              resetAll();
              setOpenSignOut(!openSignOut);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
