import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Delete,
  Add,
  ArrowForwardIos,
  Refresh,
  CheckBoxOutlineBlank,
  CheckBox,
  GetApp,
  Publish,
} from "@material-ui/icons";
import {
  useHistory,
  // useLocation,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import {
//   allApplications,
//   allIdpUsers,
//   allRoles,
//   allUsers,
// } from "../../Data/data";
import NotFound from "../NotFound";
import RolesDetail from "./RolesDetail";
import { authVerify } from "../../Utility/auth";
import { getAllRoles } from "../../Actions/action";
import {
  setResponseMessage,
  setRoles,
} from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import { findApplicationById } from "../../Utility/basic";
import { Autocomplete } from "@material-ui/lab";
import { downloadFile, roleFileHeading } from "../../Utility/file";
import UploadFile from "../UploadFile";
import DeletionMessageBox from "../DeletionMessageBox";
import { zipkinFetch } from "../../Utility/browser";

const useStyle = makeStyles((theme) => ({
  newRoleDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newRoleDialogContentSwitchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: 6,
  },
  newRoleDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  rolesContainer: {
    padding: 10,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  rolesHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
  },
  roleHeadeTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  rolesHeaderDropdown: {
    // margin: "2px 0px",
    width: 150,
    marginRight: 6,
  },
  rolesHeaderAddButton: {
    marginLeft: 10,
    borderRadius: 50,
  },
  rolesTableContainer: {
    height: "80vh",
    // overflow: "overlay",
    width: "100%",
  },
  rolesTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  rolesTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  rolesTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  rolesTableBodyRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  rolesTableBodyRowSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  rolesTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  rolesTableBodyTextHide: {
    overflow: "hidden",
    maxWidth: 180,
    textOverflow: "ellipsis",
  },
}));

const NewRole = ({ open, onClose }) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [load, setLoad] = useState(false);
  const initialRole = {
    name: "",
    label: "",
    description: "",
    applicationId: "",
    active: true,
    hasExpiry: true,
    noOfExpiryDays: 0,
    expiryMailTriggerDays: 0,
    isComposite: false,
    isDefault: false,
    associateRoles: "",
    status: "Active",
    isActive: 1,
    isDeleted: 0,
    createdBy: userReducerState?.user?.preferred_username,
    createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    updatedBy: userReducerState?.user?.preferred_username,
    updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  };
  const [newRole, setNewRole] = useState(initialRole);
  const dispatch = useDispatch();

  const insertNewRole = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const insertRoleUrl = `${process.env.REACT_APP_WAServices}/api/v1/roles`;
      const insertRolePayload = {
        ...newRole,
        status: newRole?.status ? 1 : 0,
        hasExpiry: newRole?.hasExpiry ? 1 : 0,
        isComposite: newRole?.isComposite ? 1 : 0,
        isDefault: newRole?.isDefault ? 1 : 0,
        associateRoles: newRole?.associateRoles
          ?.map((role) => role?.id)
          .join(","),
      };
      const insertRoleRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(insertRolePayload),
      };
      // console.log(insertRolePayload);
      zipkinFetch(insertRoleUrl, insertRoleRequestParam)
        .then((response) => response.json())
        .then((data) => {
          setLoad(false);
          setNewRole(initialRole);
          getAllRoles(
            userReducerState.token,
            () => {
              setLoad(true);
            },
            (data) => {
              dispatch(setRoles(data?.data || []));
              setLoad(false);
              onClose();
            },
            (err) => {
              setLoad(false);
              onClose();
            }
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newRoleDialogTitle}>New Role</DialogTitle>

      <DialogContent style={{ position: "relative" }}>
        <Loading load={load} />

        <TextField
          variant="standard"
          fullWidth
          required
          size="small"
          label="Name"
          value={newRole?.name}
          onChange={(e) => {
            setNewRole({ ...newRole, name: e.target.value });
          }}
          error={basicReducerState?.roles?.find(
            (role) =>
              role?.name?.toLowerCase() === newRole?.name?.toLowerCase() &&
              role?.applicationId === newRole?.applicationId
          )}
          helperText={
            basicReducerState?.roles?.find(
              (role) =>
                role?.name?.toLowerCase() === newRole?.name?.toLowerCase() &&
                role?.applicationId === newRole?.applicationId
            ) && "Role name already exists."
          }
        />

        <TextField
          variant="standard"
          fullWidth
          required
          size="small"
          label="Label"
          value={newRole?.label}
          onChange={(e) => {
            setNewRole({ ...newRole, label: e.target.value });
          }}
        />

        <TextField
          variant="standard"
          fullWidth
          required
          size="small"
          label="Description"
          value={newRole?.description}
          onChange={(e) => {
            setNewRole({ ...newRole, description: e.target.value });
          }}
        />

        <FormControl variant="standard" fullWidth size="small" required>
          <InputLabel>Application</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newRole?.applicationId}
            onChange={(e) => {
              setNewRole({
                ...newRole,
                applicationId: e.target.value,
                associateRoles: [],
              });
            }}
          >
            {basicReducerState?.applications?.map((application, index) => (
              <MenuItem
                key={`${application?.id}-${index}`}
                value={application?.id}
                style={{ fontSize: 12 }}
              >
                {application?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className={classes.newRoleDialogContentSwitchContainer}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={newRole?.active}
                  onChange={(e) => {
                    setNewRole({
                      ...newRole,
                      active: e.target.checked,
                    });
                  }}
                />
              }
              label="Active"
            />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={newRole?.hasExpiry}
                  onChange={(e) => {
                    setNewRole({
                      ...newRole,
                      hasExpiry: e.target.checked,
                    });
                  }}
                />
              }
              label="Has Expiry"
            />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={newRole?.isComposite}
                  onChange={(e) => {
                    setNewRole({
                      ...newRole,
                      isComposite: e.target.checked,
                      associateRoles: [],
                    });
                  }}
                />
              }
              label="Is Composite"
            />
          </FormGroup>
        </div>

        {newRole?.isComposite && (
          <Autocomplete
            multiple
            size="small"
            style={{ fontSize: 12 }}
            value={newRole?.associateRoles}
            onChange={(e, roles) => {
              setNewRole({
                ...newRole,
                associateRoles: roles,
              });
            }}
            options={basicReducerState?.roles?.filter(
              (role) =>
                role?.isComposite !== 1 &&
                role?.applicationId === newRole?.applicationId
            )}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox color="primary" fontSize="small" />}
                  checked={selected}
                />
                <Typography style={{ fontSize: 12 }}>{option.name}</Typography>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Associate Roles"
                style={{ fontSize: 12 }}
              />
            )}
          />
        )}

        <TextField
          variant="standard"
          fullWidth
          required
          type="number"
          size="small"
          label="No of expiry days"
          value={newRole?.noOfExpiryDays}
          onChange={(e) => {
            setNewRole({ ...newRole, noOfExpiryDays: e.target.value });
          }}
        />

        <TextField
          variant="standard"
          fullWidth
          required
          type="number"
          size="small"
          label="Expiry mail trigger days"
          value={newRole?.expiryMailTriggerDays}
          onChange={(e) => {
            setNewRole({ ...newRole, expiryMailTriggerDays: e.target.value });
          }}
        />
      </DialogContent>

      <DialogActions className={classes.newRoleDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ borderRadius: 50 }}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={insertNewRole}
          style={{ borderRadius: 50 }}
          disabled={
            load ||
            newRole?.name?.length === 0 ||
            newRole?.label?.length === 0 ||
            newRole?.description?.length === 0 ||
            newRole?.applicationId?.length === 0 ||
            (newRole?.isComposite && newRole?.associateRoles?.length === 0) ||
            basicReducerState?.roles?.find(
              (role) =>
                role?.name === newRole?.name &&
                role?.applicationId === newRole?.applicationId
            )
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Roles = () => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [load, setLoad] = useState(true);
  const [selectAplication, setSelectApplication] = useState(-1);
  const [roles, setroles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [openNewRoleDialog, setOpenNewRoleDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [openRoleFileDialog, setOpenRoleFileDialog] = useState(false);
  const [selectingFileRoleApplications, setSelectingFileRoleApplications] =
    useState([]);
  const [deletingRole, setDeletingRole] = useState(null);
  const history = useHistory();
  // const params = useParams();
  const [params, setParams] = useState({});
  const dispatch = useDispatch();

  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState.applications
    );
    return application?.name || "-";
  };
  const deleteRole = (roleId) => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const disableRoleUrl = `${process.env.REACT_APP_WAServices}/api/v1/roles/deactivate?id=${roleId}`;
      const disableRoleRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      zipkinFetch(disableRoleUrl, disableRoleRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          dispatch(
            setRoles(
              basicReducerState?.roles?.filter(
                (role) => role?.id !== Number(roleId)
              ) || []
            )
          );
          setroles(roles?.filter((role) => role?.id !== Number(roleId)) || []);
          setFilteredRoles(
            filteredRoles?.filter((role) => role?.id !== Number(roleId)) || []
          );
          setDeletingRole(null);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.message?.toString(),
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const editRole = (roleId) => {
    // history.push(`/roles/${roleId}`);
    setParams({ roleId: roleId });
  };
  const getRoles = () => {
    getAllRoles(
      userReducerState.token,
      () => {
        setLoad(true);
      },
      (data) => {
        dispatch(setRoles(data?.data || []));
        setLoad(false);
        // dispatch(
        //   setResponseMessage({
        //     open: true,
        //     status: data?.status ? "success" : "error",
        //     message: data?.message?.toString(),
        //   })
        // );
      },
      (err) => {
        setLoad(false);
      }
    );
  };
  const updateDefaultRole = (roleDet) => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const oldRoleId = basicReducerState?.roles?.find(
        (role) =>
          Number(role?.applicationId) === Number(roleDet?.applicationId) &&
          Number(role?.isDefault) === 1
      );
      const updateDefRoleUrl = `${process.env.REACT_APP_WAServices}/api/v1/roles/enableDefaultRole/modify?oldId=${
        oldRoleId?.id || 0
      }&newId=${roleDet?.id}&applicationId=${roleDet?.applicationId}`;
      const updateDefRoleRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState?.token}`,
        },
        body: JSON.stringify({}),
      };
      zipkinFetch(updateDefRoleUrl, updateDefRoleRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          setroles(
            basicReducerState?.roles
              ?.filter((role) => role?.isActive === 1)
              ?.map((role) =>
                Number(role?.id) === Number(roleDet?.id)
                  ? { ...role, isDefault: 1 }
                  : Number(role?.id) === Number(oldRoleId?.id)
                  ? { ...role, isDefault: 0 }
                  : { ...role }
              )
          );
          if (selectAplication === -1) {
            setFilteredRoles(
              basicReducerState?.roles?.filter((role) => role?.isActive === 1)
            );
          } else {
            setFilteredRoles(
              roles
                ?.filter(
                  (role) =>
                    role?.applicationId === selectAplication &&
                    role?.isActive === 1
                )
                ?.map((role) =>
                  Number(role?.id) === Number(roleDet?.id)
                    ? { ...role, isDefault: 1 }
                    : Number(role?.id) === Number(oldRoleId?.id)
                    ? { ...role, isDefault: 0 }
                    : { ...role }
                ) || []
            );
          }
          dispatch(
            setRoles(
              basicReducerState?.roles
                ?.filter((role) => role?.isActive === 1)
                ?.map((role) =>
                  Number(role?.id) === Number(roleDet?.id)
                    ? { ...role, isDefault: 1 }
                    : Number(role?.id) === Number(oldRoleId?.id)
                    ? { ...role, isDefault: 0 }
                    : { ...role }
                )
            )
          );

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.message?.toString(),
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const uploadRolesFile = () => {
    if (authVerify(userReducerState.token)) {
      if (!file) {
        console.log("no file found");
        return;
      }
      setLoad(true);
      // const applicationName = selectingFileRoleApplications?.map(
      //   (application) => application?.id
      // );
      const applicationName = selectingFileRoleApplications?.id;
      const url = `${process.env.REACT_APP_WAServices}/api/v1/roles/addRolesUsingCsv?applicationId=${applicationName}`;
      // const url = `${process.env.REACT_APP_WAServices}/api/v1/roles/addRolesUsingCsv?applicationId=${applicationName.join(
      //   ","
      // )}`;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      const requestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: formData,
      };
      zipkinFetch(url, requestParam)
        .then((res) => {
          console.log(res);
        })
        .then((data) => {
          setLoad(false);
          setOpenRoleFileDialog(false);
          setFile(null);
          setSelectingFileRoleApplications([]);
          getRoles();

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.message?.toString(),
            })
          );
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };

  useEffect(() => {
    setroles(basicReducerState?.roles?.filter((role) => role?.isActive === 1));
    if (selectAplication === -1) {
      setFilteredRoles(
        basicReducerState?.roles?.filter((role) => role?.isActive === 1)
      );
    } else {
      setFilteredRoles(
        roles?.filter(
          (role) =>
            role?.applicationId === selectAplication && role?.isActive === 1
        ) || []
      );
    }
    setLoad(false);
  }, [basicReducerState.roles]);

  return (
    <div className={classes.rolesContainer}>
      <Loading load={load} />

      <>
        <NewRole
          open={openNewRoleDialog}
          onClose={() => {
            setOpenNewRoleDialog(false);
            setSelectApplication(-1);
          }}
        />

        <UploadFile
          open={openRoleFileDialog}
          onClose={() => {
            setOpenRoleFileDialog(false);
            setFile(null);
          }}
          onUpload={() => {
            uploadRolesFile();
          }}
          file={file}
          setFile={setFile}
          disableCondition={
            !file || selectingFileRoleApplications?.length === 0
          }
          load={load}
        >
          <Autocomplete
            multiple={false}
            size="small"
            style={{ fontSize: 12 }}
            value={selectingFileRoleApplications}
            onChange={(e, applications) => {
              setSelectingFileRoleApplications(applications);
            }}
            options={basicReducerState?.applications}
            getOptionLabel={(option) => option?.name}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox color="primary" fontSize="small" />}
                  checked={selected}
                />

                <Typography style={{ fontSize: 12 }}>{option?.name}</Typography>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Applications"
                style={{ fontSize: 12 }}
              />
            )}
          />

          <Typography
            color="primary"
            style={{
              fontSize: 12,
              marginTop: 6,
              textAlign: "center",
            }}
          >
            !!! If no default role is set for application, then first role in
            sheet will be considered as default !!!
          </Typography>
        </UploadFile>

        <DeletionMessageBox
          open={deletingRole ? true : false}
          onClose={() => setDeletingRole(null)}
          onDelete={() => {
            deleteRole(deletingRole);
          }}
          load={load}
        />

        <div className={classes.rolesHeaderContainer}>
          <Typography className={classes.roleHeadeTitle}>Roles</Typography>

          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl
              variant="standard"
              size="small"
              required
              className={classes.rolesHeaderDropdown}
            >
              <InputLabel>Application name</InputLabel>
              <Select
                size="small"
                style={{ fontSize: 12 }}
                value={selectAplication}
                onChange={(e) => {
                  setSelectApplication(e.target.value);
                  if (e.target.value !== -1) {
                    setFilteredRoles(
                      roles?.filter(
                        (role) => role?.applicationId === e.target.value
                      ) || []
                    );
                  } else {
                    setFilteredRoles(roles);
                  }
                  // setSearchText("");
                }}
              >
                <MenuItem value={-1} style={{ fontSize: 12 }}>
                  All
                </MenuItem>
                {basicReducerState?.applications?.map((application, index) => (
                  <MenuItem
                    key={`${application?.id}-${index}`}
                    value={application?.id}
                    style={{ fontSize: 12 }}
                  >
                    {application?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Tooltip title="Download template">
              <IconButton
                size="small"
                // color="primary"
                style={{ marginLeft: 4 }}
                disabled={load}
                onClick={(e) => {
                  downloadFile({
                    data: roleFileHeading,
                    fileName: "cw_roless.csv",
                    fileType: "text/csv",
                  });
                }}
              >
                <GetApp style={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Upload file">
              <IconButton
                size="small"
                // color="primary"
                style={{ marginLeft: 4 }}
                disabled={load}
                onClick={() => {
                  setOpenRoleFileDialog(true);
                }}
              >
                <Publish style={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Refresh">
              <IconButton
                size="small"
                style={{ marginLeft: 4 }}
                disabled={load}
                onClick={getRoles}
              >
                <Refresh style={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>

            <Button
              size="small"
              variant="contained"
              color="primary"
              className={classes.rolesHeaderAddButton}
              onClick={() => setOpenNewRoleDialog(true)}
              startIcon={<Add />}
              disabled={load}
            >
              Add
            </Button>
          </div>
        </div>

        {filteredRoles?.length === 0 ? (
          load ? null : (
            <NotFound />
          )
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={params?.roleId ? 6 : 12}>
              <TableContainer
                component={Paper}
                className={`${classes.rolesTableContainer} waScroll`}
              >
                <Table
                  size="small"
                  //   className="table-sort table-arrows"
                >
                  <TableHead className={classes.rolesTableHead}>
                    <TableRow>
                      <TableCell
                        // align="center"
                        className={classes.rolesTableHeadCell}
                      >
                        Role Name
                      </TableCell>

                      {/* <TableCell
                        // align="center"
                        className={classes.rolesTableHeadCell}
                      >
                        Label
                      </TableCell> */}

                      {/* <TableCell
                        // align="center"
                        className={classes.rolesTableHeadCell}
                      >
                        Description
                      </TableCell> */}

                      <TableCell
                        // align="center"
                        className={classes.rolesTableHeadCell}
                      >
                        Application
                      </TableCell>

                      <TableCell
                        // align="center"
                        className={classes.rolesTableHeadCell}
                      >
                        Type
                      </TableCell>

                      <TableCell
                        align="center"
                        className={classes.rolesTableHeadCell}
                      >
                        Is Default
                      </TableCell>

                      <TableCell
                        // align="center"
                        className={classes.rolesTableHeadCell}
                      >
                        Status
                      </TableCell>

                      <TableCell
                        align="center"
                        className={classes.rolesTableHeadCell}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className={`${classes.rolesTableBody} waScroll`}>
                    {filteredRoles?.map((role, index) => {
                      return (
                        <TableRow
                          key={`${role?.id}-${index}`}
                          className={`${classes.rolesTableBodyRow} ${
                            Number(params?.roleId) === Number(role?.id) &&
                            classes.rolesTableBodyRowSelected
                          }`}
                          onClick={() => {
                            editRole(role.id);
                          }}
                        >
                          <TableCell
                            // align="center"
                            className={classes.rolesTableBodyCell}
                          >
                            {role?.name}
                          </TableCell>

                          {/* <TableCell
                            // align="center"
                            className={classes.rolesTableBodyCell}
                          >
                            {role?.label}
                          </TableCell> */}

                          {/* <TableCell
                            // align="center"
                            className={`${classes.rolesTableBodyCell} ${classes.rolesTableBodyTextHide}`}
                          >
                            {role?.description}
                          </TableCell> */}

                          <TableCell
                            // align="center"
                            className={classes.rolesTableBodyCell}
                          >
                            {getApplicationNameById(role?.applicationId)}
                          </TableCell>

                          <TableCell
                            // align="center"
                            className={classes.rolesTableBodyCell}
                          >
                            {role?.isComposite === 1 ? "Composite" : "Single"}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.rolesTableBodyCell}
                          >
                            <Switch
                              color="primary"
                              size="small"
                              checked={role?.isDefault === 1 ? true : false}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (role?.isDefault !== 1) {
                                  updateDefaultRole(role);
                                }
                              }}
                              disabled={load}
                            />
                          </TableCell>

                          <TableCell
                            // align="center"
                            className={classes.rolesTableBodyCell}
                          >
                            {role?.status}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.rolesTableBodyCell}
                          >
                            {/* <Tooltip title="Edit">
                              <IconButton
                                aria-label="Edit"
                                color="primary"
                                onClick={() => editRole(role.id)}
                              >
                                <ArrowForwardIos style={{ fontSize: 16 }} />
                              </IconButton>
                            </Tooltip> */}
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="Delete"
                                color="secondary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // deleteRole(role.id);
                                  setDeletingRole(role?.id);
                                }}
                                disabled={load || role?.isDefault === 1}
                              >
                                <Delete style={{ fontSize: 16 }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {params?.roleId && (
              <Grid item xs={params?.roleId ? 6 : false}>
                <RolesDetail params={params} setParams={setParams} />
              </Grid>
            )}
          </Grid>
        )}
      </>
    </div>
  );
};

export default Roles;
