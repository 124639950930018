import { createSlice } from "@reduxjs/toolkit";

export const basicReducer = createSlice({
  name: "basicReducer",
  initialState: {
    users: [],
    idpUsers: [],
    applications: [],
    entities: [],
    apis: [],
    activities: [],
    payloads: [],
    realms: [],
    licenses: [],
    sessionUsers: [],
    responseMessage: {
      open: false,
      status: "",
      message: "",
    },
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setIdpUsers: (state, action) => {
      state.idpUsers = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setApplications: (state, action) => {
      state.applications = action.payload;
    },
    setEntities: (state, action) => {
      state.entities = action.payload;
    },
    setApis: (state, action) => {
      state.apis = action.payload;
    },
    setActivities: (state, action) => {
      state.activities = action.payload;
    },
    setPayloads: (state, action) => {
      state.payloads = action.payload;
    },
    setRealms: (state, action) => {
      state.realms = action.payload;
    },
    setLienses: (state, action) => {
      state.licenses = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setSessionUser: (state, action) => {
      state.sessionUsers = action.payload;
    },
    setResponseMessage: (state, action) => {
      state.responseMessage = action.payload;
    },
  },
});

export const {
  setUsers,
  setIdpUsers,
  setRoles,
  setApplications,
  setEntities,
  setApis,
  setActivities,
  setPayloads,
  setRealms,
  setLienses,
  setGroups,
  setSessionUser,
  setResponseMessage,
} = basicReducer.actions;

export default basicReducer.reducer;
