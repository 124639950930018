import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import moment from "moment";
import { getAllApplications } from "../../Actions/action";
import { setApplications } from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import { Autocomplete } from "@material-ui/lab";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { zipkinFetch } from "../../Utility/browser";

const useStyle = makeStyles((theme) => ({
  newApplicationDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newApplicationDialogActionsContainer: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },
}));

const NewApplication = ({
  open,
  onClose,
  title,
  update,
  updatingApplication,
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();

  const [load, setLoad] = useState(false);
  const initialState = {
    name: "",
    label: "",
    description: "",
    applicationType: "",
    applications: [],
    ownersEmails: [],
  };
  const [newApplication, setNewApplication] = useState(initialState);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCreateApplication = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const insertApplicationUrl = `${process.env.REACT_APP_WAServices}/api/v1/applications`;
      const insertApplicationPayload = {
        createdBy: userReducerState?.user?.preferred_username,
        createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        description: newApplication?.description,
        isActive: 1,
        isDeleted: 0,
        label: newApplication?.label,
        name: newApplication?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.preferred_username,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        applications: newApplication?.applications,
      };
      const insertApplicationRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(insertApplicationPayload),
      };
      zipkinFetch(insertApplicationUrl, insertApplicationRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setNewApplication(initialState);
          setLoad(false);
          getAllApplications(
            userReducerState.token,
            () => {
              setLoad(true);
            },
            (data) => {
              dispatch(setApplications(data?.data || []));
              setLoad(false);
              onClose();
              setError(false);
              setErrorMessage("");
            },
            (err) => {
              setLoad(false);
            }
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const handleUpdateApplication = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updateApplicationUrl = `${process.env.REACT_APP_WAServices}/api/v1/applications/modify`;
      const updateApplicationPayload = {
        id: newApplication?.id,
        createdBy: newApplication?.createdBy,
        createdOn: moment(new Date(newApplication?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        description: newApplication?.description,
        isActive: 1,
        isDeleted: 0,
        label: newApplication?.label,
        name: newApplication?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.preferred_username,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        applications: newApplication?.applications,
      };
      const updateApplicationRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(updateApplicationPayload),
      };
      zipkinFetch(updateApplicationUrl, updateApplicationRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setNewApplication(initialState);
          setLoad(false);
          dispatch(
            setApplications(
              basicReducerState?.applications?.map((application) =>
                Number(application?.id) === Number(newApplication?.id)
                  ? {
                      ...application,
                      name: newApplication?.name,
                      label: newApplication.label,
                      description: newApplication.description,
                      applications: newApplication?.applications,
                    }
                  : { ...application }
              ) || []
            )
          );
          onClose();
          setError(false);
          setErrorMessage("");
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };

  useEffect(() => {
    if (updatingApplication) {
      setNewApplication(updatingApplication);
    } else {
      setNewApplication(initialState);
    }
  }, [updatingApplication]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setNewApplication(initialState);
        setError(false);
        setErrorMessage("");
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.newApplicationDialogTitle}>
        {title}
      </DialogTitle>

      <DialogContent>
        <Loading load={load} />

        <TextField
          variant="standard"
          fullWidth
          required
          label="Name"
          value={newApplication?.name}
          onChange={(e) => {
            setNewApplication({ ...newApplication, name: e.target.value });
            if (update) {
              const applications = basicReducerState?.applications?.filter(
                (app) =>
                  app?.name?.toLowerCase() === e.target.value?.toLowerCase() &&
                  app?.id !== newApplication?.id
              );
              if (applications?.length > 0) {
                setError(true);
                setErrorMessage("Application name already exists");
              } else {
                setError(false);
                setErrorMessage("");
              }
            } else {
              const applications = basicReducerState?.applications?.filter(
                (app) =>
                  app?.name?.toLowerCase() === e.target.value?.toLowerCase()
              );
              if (applications?.length > 0) {
                setError(true);
                setErrorMessage("Application name already exists");
              } else {
                setError(false);
                setErrorMessage("");
              }
            }
          }}
          error={error}
          helperText={error && errorMessage}
        />

        <TextField
          variant="standard"
          fullWidth
          required
          label="Label"
          value={newApplication?.label}
          onChange={(e) =>
            setNewApplication({ ...newApplication, label: e.target.value })
          }
        />

        <TextField
          variant="standard"
          fullWidth
          required
          label="Description"
          value={newApplication?.description}
          onChange={(e) =>
            setNewApplication({
              ...newApplication,
              description: e.target.value,
            })
          }
        />

        {/* <Autocomplete
          multiple
          size="small"
          style={{ fontSize: 12 }}
          value={newApplication?.applications}
          onChange={(e, applications) => {
            setNewApplication({
              ...newApplication,
              applications: applications,
            });
          }}
          options={
            update
              ? basicReducerState?.applications
                  ?.filter(
                    (application) => application?.name !== newApplication?.name
                  )
                  ?.map((application) => application?.name)
              : basicReducerState?.applications?.map(
                  (application) => application?.name
                )
          }
          getOptionLabel={(option) => option}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>{option}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Allow Applications"
              style={{ fontSize: 12 }}
            />
          )}
        /> */}
      </DialogContent>

      <DialogActions className={classes.newApplicationDialogActionsContainer}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            onClose();
            setNewApplication(initialState);
            setError(false);
            setErrorMessage("");
          }}
          style={{ borderRadius: 50 }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          style={{ borderRadius: 50 }}
          onClick={() => {
            if (update) {
              handleUpdateApplication();
            } else {
              handleCreateApplication();
            }
          }}
          disabled={
            newApplication?.name?.length === 0 ||
            newApplication?.label?.length === 0 ||
            newApplication?.description?.length === 0 ||
            load ||
            error
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewApplication;
