import { authVerify } from "../Utility/auth";
import store from "../Redux/store";
import { zipkinFetch } from "../Utility/browser";

export const getAllUsers = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    // const getUsersUrl = `${process.env.REACT_APP_WAServices}/api/v1/users`;
    const getUsersUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users`;
    const getUsersRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // const getUsersUrl = `${process.env.REACT_APP_WAServices}/api`;
    // const payload = {
    //   name: "fetchUsers",
    //   type: "query",
    //   args: [],
    // };
    // const getUsersRequestParams = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify(payload),
    // };
    zipkinFetch(getUsersUrl, getUsersRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getIdpUsers = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getIdpUsersUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/SAP-IAS/getUsers`;
    const getIdpUsersRequestParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    zipkinFetch(getIdpUsersUrl, getIdpUsersRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getAllRoles = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllRolesUrl = `${process.env.REACT_APP_WAServices}/api/v1/roles`;
    const getAllRolesRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // const getAllRolesUrl = `${process.env.REACT_APP_WAServices}/api`;
    // const getAllRolesPayload = {
    //   name: "fetchRoles",
    //   type: "query",
    //   args: [],
    // };
    // const getAllRolesRequestParams = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify(getAllRolesPayload),
    // };
    zipkinFetch(getAllRolesUrl, getAllRolesRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getAllApplications = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllApplicationsUrl = `${process.env.REACT_APP_WAServices}/api/v1/applications`;
    const getAllApplicationsRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // const getAllApplicationsUrl = `/localhost/api`;
    // const getAllApplicationsPayload = {
    //   name: "fetchApplications",
    //   type: "query",
    //   args: [],
    // };
    // const getAllApplicationsRequestParams = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify(getAllApplicationsPayload),
    // };
    zipkinFetch(getAllApplicationsUrl, getAllApplicationsRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getAllEntities = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllEntitiesUrl = `${process.env.REACT_APP_WAServices}/api/v1/entities`;
    const getAllEntitiesRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // const getAllEntitiesUrl = `/localhost/api`;
    // const getAllEntitiesPayload = {
    //   name: "fetchEntity",
    //   type: "query",
    //   args: [],
    // };
    // const getAllEntitiesRequestParams = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify(getAllEntitiesPayload),
    // };
    zipkinFetch(getAllEntitiesUrl, getAllEntitiesRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getAllActivities = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllActivitiesUrl = `${process.env.REACT_APP_WAServices}/api/v1/activities`;
    const getAllActivitiesRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // const getAllActivitiesUrl = `/localhost/api`;
    // const getAllActivitiesPayload = {
    //   name: "fetchActivities",
    //   type: "query",
    //   args: [],
    // };
    // const getAllActivitiesRequestParams = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify(getAllActivitiesPayload),
    // };
    zipkinFetch(getAllActivitiesUrl, getAllActivitiesRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getKeycloakAdminToken = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    var urlencoded = new URLSearchParams();
    urlencoded.append("client_id", "admin-cli");
    urlencoded.append("username", process.env.REACT_APP_KEYCLOAK_USERNAME);
    urlencoded.append("password", process.env.REACT_APP_KEYCLOAK_PASSWORD);
    urlencoded.append("grant_type", "password");

    const requestParamsToken = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    };
    zipkinFetch(
      `/keycloakApi/realms/master/protocol/openid-connect/token`,
      requestParamsToken
    )
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getAllRealms = (adminToken, fInitial, fSuccess, fError) => {
  fInitial();
  const requestParamsRealms = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    },
  };
  zipkinFetch(`/keycloakApi/admin/realms`, requestParamsRealms)
    .then((res) => res.json())
    .then((data) => {
      fSuccess(data);
    })
    .catch((err) => {
      fError(err);
    });
};

export const getAllLicenses = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getLicensesUrl = `${process.env.REACT_APP_WAServices}/api/v1/license`;
    const getLicensesRequestParam = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    zipkinFetch(getLicensesUrl, getLicensesRequestParam)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getAllGroups = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getGroupsUrl = `${process.env.REACT_APP_WAServices}/api/v1/groups`;
    const getGroupsRequestParam = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    zipkinFetch(getGroupsUrl, getGroupsRequestParam)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getSessionCount = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const applicationName = "WORKACCESS";
    const getSessionCountUrl = `${
      process.env.REACT_APP_WAServices
    }/api/v1/keycloak/userSessionCount?username=${
      store.getState().userReducer.user.preferred_username
    }&applicationName=${applicationName}`;
    const getSessionCountPayload = {
      userName: "KeycloakAdmin",
      password: "Incture@123",
      grantType: "password",
      clientId: "admin-cli",
    };
    const getSessionCountRequestParam = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(getSessionCountPayload),
    };
    zipkinFetch(getSessionCountUrl, getSessionCountRequestParam)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getAllApis = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllApisUrl = `${process.env.REACT_APP_WAServices}/api/v1/apiPayload`;
    const getAllApisRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // const getAllApisUrl = `${process.env.REACT_APP_WAServices}/api`;
    // const getAllApisPayload = {
    //   name: "fetchEntity",
    //   type: "query",
    //   args: [],
    // };
    // const getAllApisRequestParams = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify(getAllApisPayload),
    // };
    zipkinFetch(getAllApisUrl, getAllApisRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getAllPayloads = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllPayloadsUrl = `${process.env.REACT_APP_WAServices}/api/v1/apiPayload/payload`;
    const getAllPayloadsRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // const getAllPayloadsUrl = `${process.env.REACT_APP_WAServices}/api`;
    // const getAllPayloadsPayload = {
    //   name: "fetchEntity",
    //   type: "query",
    //   args: [],
    // };
    // const getAllPayloadsRequestParams = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify(getAllPayloadsPayload),
    // };
    zipkinFetch(getAllPayloadsUrl, getAllPayloadsRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};

export const getAllSessionUser = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const applicationName = "localhost";
    // const applicationName= "WorkAccess";
    const getAllPayloadsUrl = `${process.env.REACT_APP_WAServices}/api/v1/keycloak/userSessionDetails?applicationName=${applicationName}`;
    const getAllPayloadsRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // const getAllPayloadsUrl = `${process.env.REACT_APP_WAServices}/api`;
    // const getAllPayloadsPayload = {
    //   name: "fetchEntity",
    //   type: "query",
    //   args: [],
    // };
    // const getAllPayloadsRequestParams = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify(getAllPayloadsPayload),
    // };
    zipkinFetch(getAllPayloadsUrl, getAllPayloadsRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};
