import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Delete,
  Add,
  ArrowForwardIos,
  Search,
  Create,
  Check,
  Close,
  Refresh,
  GetApp,
  Publish,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@material-ui/icons";
import {
  useHistory,
  //  useLocation,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NotFound from "../NotFound";
// import { allIdpUsers, allUsers } from "../../Data/data";
import UserDetail from "./UserDetail";
import { authVerify } from "../../Utility/auth";
import { getAllUsers } from "../../Actions/action";
import {
  setUsers,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import CustomAvatar from "../CustomAvatar";
import { downloadFile, userFileHeading } from "../../Utility/file";
import UploadFile from "../UploadFile";
import { Autocomplete } from "@material-ui/lab";
import WorkTip from "@cw/cherrywork-worktip";
import DeletionMessageBox from "../DeletionMessageBox";
import { zipkinFetch } from "../../Utility/browser";

const useStyle = makeStyles((theme) => ({
  idpUsersDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  idpUsersDialogContentSearchContainer: {
    marginBottom: 8,
    width: 200,
    position: "sticky",
    top: 0,
    zIndex: 999,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  idpUsersTableContainer: {
    height: "51vh",
    // overflow: "overlay",
    width: "100%",
  },
  idpUsersTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  idpUsersTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  idpUsersTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  idpUsersTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  idpUsersTableBodyRowSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  idpUsersTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  idpUsersTableBodyTextHide: {
    maxWidth: 180,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  idpUsersDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  usersContainer: {
    padding: 10,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  usersHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
  },
  userHeadeTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  usersHeaderAddButton: {
    marginLeft: 10,
    borderRadius: 50,
  },
  usersTableContainer: {
    height: "81vh",
    // overflow: "overlay",
    width: "100%",
  },
  usersTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  usersTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  usersTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  usersTableBodyRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  usersTableBodyRowSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  usersTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  usersTableBodyTextHide: {
    overflow: "hidden",
    maxWidth: 180,
    textOverflow: "ellipsis",
  },
}));

const IdpUsers = ({ open, onClose }) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [load, setLoad] = useState(false);
  const [idpUsers, setIdpUsers] = useState([]);
  const [filteredIdpUsers, setFilteredIdpUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectApplication, setSelectApplication] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const searchIdpUsers = (e) => {
    setFilteredIdpUsers(
      idpUsers?.filter(
        (user) =>
          user?.pid?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
          user?.userName
            ?.toLowerCase()
            ?.includes(e.target.value.toLowerCase()) ||
          user?.name?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
          user?.firstName
            ?.toLowerCase()
            ?.includes(e.target.value.toLowerCase()) ||
          user?.lastName
            ?.toLowerCase()
            ?.includes(e.target.value.toLowerCase()) ||
          user?.userEmail?.toLowerCase()?.includes(e.target.value.toLowerCase())
      ) || []
    );
  };
  const insertNewUser = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const insertUserUrl = `${process.env.REACT_APP_WAServices}/api/v1/users/insertMultipleIdpUsers?applicationName=${selectApplication}`;
      const payload =
        selectedUser?.map((user) => ({
          userId: user.pid,
          userName: user?.userName || user?.userEmail,
          name: user?.name,
          firstName: user?.firstName,
          lastName: user?.lastName,
          emailId: user?.userEmail,
          // idp: user?.userId,
          // status: "Active",
          // isDeleted: 0,
          // isActive: 1,
          // createdBy: userReducerState?.user?.preferred_username,
          // createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          // updatedBy: userReducerState?.user?.preferred_username,
          // updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        })) || [];
      const insertUserRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(payload),
      };
      // console.log(selectedUser, payload);
      zipkinFetch(insertUserUrl, insertUserRequestParam)
        .then((res) => res.json())
        .then((i_data) => {
          setLoad(false);
          setSelectedUser([]);
          setFilteredIdpUsers(basicReducerState.idpUsers);

          getAllUsers(
            userReducerState.token,
            () => {
              setLoad(true);
            },
            (data) => {
              dispatch(setUsers(data?.data || []));
              setLoad(false);
              onClose();
              setSelectedUser([]);
              setSelectApplication("");
              setFilteredIdpUsers(basicReducerState.idpUsers);
            },
            (err) => {
              setLoad(false);
              onClose();
            }
          );

          dispatch(
            setResponseMessage({
              open: true,
              status: i_data?.status ? "success" : "error",
              message: i_data?.message?.toString(),
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const isPresent = (i_user) => {
    return basicReducerState?.users?.filter(
      (user) => user?.emailId === i_user?.emailId
    )?.length > 0
      ? true
      : false;
  };
  const alreadySelectedUser = (i_user) => {
    return selectedUser?.find((user) => user?.userEmail === i_user?.userEmail)
      ? true
      : false;
  };
  const getApplicationIdByName = (applicationName) => {
    const application = basicReducerState?.applications?.find(
      (application) => application?.name === applicationName
    );
    return application?.id || -1;
  };
  const defaultRolePresent = (applicationName) => {
    basicReducerState?.roles?.find(
      (role) =>
        Number(role?.applicationId) ===
          Number(getApplicationIdByName(applicationName)) &&
        Number(role?.isDefault) === 1
    )
      ? setError(false)
      : setError(true);
  };

  useEffect(() => {
    setIdpUsers(basicReducerState.idpUsers);
    setFilteredIdpUsers(basicReducerState.idpUsers);
  }, [basicReducerState.idpUsers]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        onClose();
        setFilteredIdpUsers(basicReducerState.idpUsers);
      }}
    >
      <DialogTitle className={classes.idpUsersDialogTitle}>
        Idp Users
      </DialogTitle>

      <DialogContent>
        <Loading load={load} />

        <DialogContentText>
          <div
            className={`inputContainer ${classes.idpUsersDialogContentSearchContainer}`}
          >
            <input
              className={userReducerState.darkMode && "inputDarkMode"}
              placeholder="Search"
              onChange={searchIdpUsers}
            />

            <Search />
          </div>

          {filteredIdpUsers?.length === 0 ? (
            <NotFound />
          ) : (
            <>
              <TableContainer
                component={Paper}
                className={`${classes.idpUsersTableContainer} waScroll`}
              >
                <Table
                  size="small"
                  aria-label="simple table"
                  // className="table-sort table-arrows"
                >
                  <TableHead className={classes.idpUsersTableHead}>
                    <TableRow>
                      <TableCell
                        // align="center"
                        className={classes.idpUsersTableHeadCell}
                      >
                        Pid
                      </TableCell>
                      <TableCell
                        // align="center"
                        className={classes.idpUsersTableHeadCell}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        // align="center"
                        className={classes.idpUsersTableHeadCell}
                      >
                        Email
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    className={`${classes.idpUsersTableBody} waScroll`}
                  >
                    {/* !isPresent(idpUser) && ( */}
                    {filteredIdpUsers?.map((idpUser) => (
                      <TableRow
                        key={idpUser?.userEmail}
                        // selected={idpUser?.userId === selectedUser?.userId}
                        className={`${classes.idpUsersTableBodyRow} ${
                          alreadySelectedUser(idpUser) &&
                          classes.idpUsersTableBodyRowSelected
                        }`}
                        onClick={() => {
                          if (alreadySelectedUser(idpUser)) {
                            setSelectedUser(
                              selectedUser?.filter(
                                (user) => user?.userEmail !== idpUser?.userEmail
                              )
                            );
                          } else {
                            setSelectedUser([...selectedUser, idpUser]);
                          }
                        }}
                      >
                        <TableCell
                          // align="center"
                          className={classes.idpUsersTableBodyCell}
                        >
                          {idpUser?.pid}
                        </TableCell>

                        <TableCell
                          // align="center"
                          className={classes.idpUsersTableBodyCell}
                        >{`${idpUser?.firstName} ${idpUser?.lastName}`}</TableCell>

                        <TableCell
                          // align="center"
                          className={`${classes.idpUsersTableBodyCell} ${classes.idpUsersTableBodyTextHide}`}
                        >
                          {idpUser?.userEmail}
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* ) */}
                  </TableBody>
                </Table>
              </TableContainer>

              <FormControl
                variant="standard"
                size="small"
                fullWidth
                required
                error={error}
              >
                <InputLabel id="licenseType">Application</InputLabel>
                <Select
                  labelId="licenseType"
                  size="small"
                  label="Type"
                  style={{ fontSize: 12 }}
                  value={selectApplication}
                  onChange={(e) => {
                    setSelectApplication(e.target.value);
                    defaultRolePresent(e.target.value);
                  }}
                >
                  {basicReducerState?.applications?.map(
                    (application, index) => (
                      <MenuItem
                        value={application?.name}
                        key={index}
                        style={{ fontSize: 12 }}
                      >
                        {application?.name}
                      </MenuItem>
                    )
                  )}
                </Select>

                {error && (
                  <FormHelperText>
                    No default role present with this aplication
                  </FormHelperText>
                )}
              </FormControl>
            </>
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.idpUsersDialogActions}>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          style={{ borderRadius: 50 }}
          onClick={() => {
            setSelectedUser([]);
            onClose();
            setSelectApplication("");
            setFilteredIdpUsers(basicReducerState.idpUsers);
          }}
          disabled={load}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={insertNewUser}
          style={{ borderRadius: 50 }}
          disabled={
            selectedUser?.length === 0 ||
            selectApplication.length === 0 ||
            load ||
            error
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Users = () => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [load, setLoad] = useState(true);
  const [users, setusers] = useState(basicReducerState.users);
  const [filteredUsers, setFilteredUsers] = useState(basicReducerState.users);
  const [openIdpDialog, setOpenIdpDialog] = useState(false);
  const [openUserFileDialog, setOpenUserFileDialog] = useState(false);
  const [selectingFileUserApplications, setSelectingFileUserApplications] =
    useState([]);
  const [search, setSearch] = useState("");
  const [file, setFile] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const history = useHistory();
  // const params = useParams();
  const [params, setParams] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [empId, setEmpId] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const id = Boolean(anchorEl)
    ? `workTip-${empId ? selectedEmp : ""}`
    : undefined;
  const dispatch = useDispatch();

  const deleteUser = (userId) => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const disableUserUrl =
        "${process.env.REACT_APP_WAServices}/api/v1/users/deactivate?id=" +
        userId;
      const disableUserRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      // const disableUserUrl=`/wa_authz/api`;
      // const payload = {
      //   name:"disableUser",
      //   type:"statement",
      //   args:[userId],
      // }
      // const disableUserRequestParam={
      //   method:"POST",
      //   headers:{
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${this.props.token}`,
      //   },
      //   body:JSON.stringify(payload),
      // }
      zipkinFetch(disableUserUrl, disableUserRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          dispatch(
            setUsers(
              basicReducerState?.users?.filter(
                (user) => user?.id !== Number(userId)
              ) || []
            )
          );
          setusers(users?.filter((user) => user?.id !== Number(userId)) || []);
          setFilteredUsers(
            filteredUsers?.filter((user) => user?.id !== Number(userId)) || []
          );
          setDeletingUser(null);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.message?.toString(),
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const changeStatus = (user) => {
    setFilteredUsers(
      filteredUsers?.map((f_user) =>
        Number(f_user?.id) === Number(user?.id)
          ? {
              ...f_user,
              status: f_user?.status === "Draft" ? "Active" : "Draft",
            }
          : { ...f_user }
      )
    );
  };
  const updateUser = (user) => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updateUserUrl = `${process.env.REACT_APP_WAServices}/api/v1/users/userAndUserRoleMapping`;
      const updateUserPayload = {
        isActive: 1,
        isDeleted: 0,
        createdBy: user?.createdBy,
        createdOn: moment(new Date(user?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        displayName: user?.displayName,
        emailId: user?.emailId,
        firstName: user?.firstName,
        id: Number(user?.id),
        lastName: user?.lastName,
        status: "Active",
        updatedBy: userReducerState?.user?.preferred_username,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        userId: user?.userId,
        userName: user?.userName,
        userRoles: [],
        assignedLicenses: [],
        featuresException: [],
      };
      const updateUserRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(updateUserPayload),
      };
      zipkinFetch(updateUserUrl, updateUserRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          if (data?.statusCode === 201) {
            dispatch(
              setUsers(
                filteredUsers?.map((f_user) =>
                  Number(f_user?.id) === Number(user?.id)
                    ? {
                        ...f_user,
                        status: "Active",
                        displayName: user?.displayName,
                      }
                    : { ...f_user }
                ) || []
              )
            );
          }

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: "Display name updated successfully",
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const editUser = (userId) => {
    // history.push(`/users/${userId}`);
    setParams({ userId: userId });
  };
  const searchUsers = (e) => {
    setSearch(e.target.value);
    setFilteredUsers(
      users?.filter(
        (user) =>
          user?.displayName
            ?.toLowerCase()
            ?.includes(e.target.value.toLowerCase()) ||
          user?.userName
            ?.toLowerCase()
            ?.includes(e.target.value.toLowerCase()) ||
          user?.userId?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
          user?.emailId?.toLowerCase()?.includes(e.target.value.toLowerCase())
      )
    );
  };
  const getUsers = () => {
    getAllUsers(
      userReducerState.token,
      () => {
        setLoad(true);
      },
      (data) => {
        dispatch(setUsers(data?.data || []));
        setLoad(false);
      },
      (err) => {
        setLoad(false);
      }
    );
  };
  const uploadUsersFile = (e) => {
    if (authVerify(userReducerState.token)) {
      if (!file) {
        console.log("no file found");
        return;
      }
      setLoad(true);
      const applicationName = selectingFileUserApplications?.map(
        (application) => application?.name
      );
      const url = `${
        process.env.REACT_APP_WAServices
      }/api/v1/users/addUsersUsingCsv?applicationName=${applicationName.join(
        ","
      )}`;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      const requestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: formData,
      };
      zipkinFetch(url, requestParam)
        .then((res) => {
          console.log(res);
        })
        .then((data) => {
          setLoad(false);
          setOpenUserFileDialog(false);
          setFile(null);
          setSelectingFileUserApplications([]);
          getUsers();

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.message?.toString(),
            })
          );
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const isDefaultRole = (applicationId) => {
    return basicReducerState?.roles?.filter(
      (role) =>
        Number(role?.applicationId) === Number(applicationId) &&
        Number(role.isDefault) === 1
    )?.length > 0
      ? true
      : false;
  };

  useEffect(() => {
    setusers(basicReducerState?.users?.filter((user) => user?.isActive === 1));
    if (search?.length > 0) {
      setFilteredUsers(
        users?.filter(
          (user) =>
            (user?.displayName?.toLowerCase()?.includes(search.toLowerCase()) ||
              user?.userName?.toLowerCase()?.includes(search.toLowerCase()) ||
              user?.userId?.toLowerCase()?.includes(search.toLowerCase()) ||
              user?.emailId?.toLowerCase()?.includes(search.toLowerCase())) &&
            user?.isActive === 1
        )
      );
    } else {
      setFilteredUsers(
        basicReducerState?.users?.filter((user) => user?.isActive === 1)
      );
    }
    setLoad(false);
  }, [basicReducerState.users, search]);

  return (
    <div className={classes.usersContainer}>
      <Loading load={load} />

      <div>
        <IdpUsers
          open={openIdpDialog}
          onClose={() => {
            setOpenIdpDialog(false);
          }}
        />

        <UploadFile
          open={openUserFileDialog}
          onClose={() => {
            setOpenUserFileDialog(false);
            setFile(null);
            setSelectingFileUserApplications([]);
          }}
          onUpload={() => {
            uploadUsersFile();
          }}
          file={file}
          setFile={setFile}
          disableCondition={
            !file || selectingFileUserApplications?.length === 0
          }
          load={load}
        >
          <Autocomplete
            required
            multiple
            size="small"
            style={{ fontSize: 12 }}
            value={selectingFileUserApplications}
            onChange={(e, applications) => {
              setSelectingFileUserApplications(applications);
            }}
            options={basicReducerState?.applications?.filter((application) =>
              isDefaultRole(Number(application?.id))
            )}
            getOptionLabel={(option) => option?.name}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox color="primary" fontSize="small" />}
                  checked={selected}
                />

                <Typography style={{ fontSize: 12 }}>{option?.name}</Typography>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Applications"
                style={{ fontSize: 12 }}
              />
            )}
          />
        </UploadFile>

        <DeletionMessageBox
          open={deletingUser ? true : false}
          onClose={() => setDeletingUser(null)}
          onDelete={() => {
            deleteUser(deletingUser);
          }}
          load={load}
        />

        <div className={classes.usersHeaderContainer}>
          <Typography className={classes.userHeadeTitle}>Users</Typography>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={`inputContainer`}>
              <input
                className={userReducerState.darkMode && "inputDarkMode"}
                placeholder="Search"
                value={search}
                onChange={searchUsers}
              />

              <Search />
            </div>

            <Tooltip title="Download template">
              <IconButton
                size="small"
                // color="primary"
                style={{ marginLeft: 4 }}
                disabled={load}
                onClick={(e) => {
                  downloadFile({
                    data: userFileHeading,
                    fileName: "cw_users.csv",
                    fileType: "text/csv",
                  });
                }}
              >
                <GetApp style={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Upload file">
              <IconButton
                size="small"
                // color="primary"
                style={{ marginLeft: 4 }}
                disabled={load}
                onClick={() => {
                  setOpenUserFileDialog(true);
                }}
              >
                <Publish style={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Refresh">
              <IconButton
                size="small"
                style={{ marginLeft: 4 }}
                disabled={load}
                onClick={getUsers}
              >
                <Refresh style={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>

            <Button
              size="small"
              variant="contained"
              color="primary"
              className={classes.usersHeaderAddButton}
              onClick={() => setOpenIdpDialog(true)}
              startIcon={<Add />}
              disabled={load}
            >
              Add
            </Button>
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={params?.userId ? 6 : 12}>
            {filteredUsers?.length === 0 ? (
              load ? null : (
                <NotFound />
              ) // null
            ) : (
              <TableContainer
                component={Paper}
                className={`${classes.usersTableContainer} waScroll`}
              >
                <Table
                  size="small"
                  //   className="table-sort table-arrows"
                >
                  <TableHead className={classes.usersTableHead}>
                    <TableRow>
                      <TableCell
                        // align="center"
                        className={classes.usersTableHeadCell}
                      >
                        Display Name
                      </TableCell>

                      {/* <TableCell
                        align="center"
                        className={classes.usersTableHeadCell}
                      >
                        User Name
                      </TableCell> */}

                      <TableCell
                        // align="center"
                        className={classes.usersTableHeadCell}
                      >
                        Application
                      </TableCell>

                      <TableCell
                        // align="center"
                        className={classes.usersTableHeadCell}
                      >
                        User Id
                      </TableCell>

                      <TableCell
                        // align="center"
                        className={classes.usersTableHeadCell}
                      >
                        Email ID
                      </TableCell>

                      <TableCell
                        // align="center"
                        className={classes.usersTableHeadCell}
                      >
                        Status
                      </TableCell>

                      <TableCell
                        align="center"
                        className={classes.usersTableHeadCell}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className={`${classes.usersTableBody} waScroll`}>
                    {filteredUsers?.map((user, index) => {
                      return (
                        <TableRow
                          key={`${user?.id}-${index}`}
                          className={`${classes.usersTableBodyRow} ${
                            Number(user?.id) === Number(params?.userId) &&
                            classes.usersTableBodyRowSelected
                          }`}
                          onClick={() => editUser(user?.id)}
                        >
                          <TableCell
                            // align="center"
                            className={classes.usersTableBodyCell}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "43px", borderRadius: "50%" }}
                                onMouseLeave={(e) => {
                                  e.stopPropagation();
                                  if (user?.employeeId) {
                                    setAnchorEl(null);
                                  }
                                }}
                              >
                                <CustomAvatar
                                  name={user?.displayName}
                                  style={{ marginRight: 6 }}
                                  aria-describedby={`workTip-${user?.id}`}
                                  // onClick={(e) => {
                                  //   e.stopPropagation();
                                  //   setSelectedEmp(user?.id);
                                  //   setAnchorEl(e.currentTarget);
                                  //   setEmpId("INC01614");
                                  // }}
                                  onMouseEnter={(e) => {
                                    e.stopPropagation();
                                    if (
                                      user?.employeeId &&
                                      user?.employeeId !== "null"
                                    ) {
                                      setAnchorEl(e.currentTarget);
                                      setEmpId(user?.employeeId);
                                    } else {
                                      setEmpId(null);
                                    }
                                  }}
                                />

                                {user?.employeeId &&
                                  user?.employeeId !== "null" &&
                                  user?.employeeId === empId && (
                                    <WorkTip
                                      id={id}
                                      anchorEl={anchorEl}
                                      onClose={() => {
                                        if (empId && empId !== "null") {
                                          setAnchorEl(null);
                                        }
                                      }}
                                      placement="right-start"
                                      userId={user?.employeeId}
                                      token={userReducerState?.token}
                                    />
                                  )}
                              </div>

                              {user.status === "Draft" ? (
                                <TextField
                                  value={user?.displayName}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    setFilteredUsers(
                                      filteredUsers?.map((p_user) =>
                                        Number(user?.id) === Number(p_user?.id)
                                          ? {
                                              ...p_user,
                                              displayName: e.target.value,
                                            }
                                          : { ...p_user }
                                      )
                                    );
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                              ) : (
                                user?.displayName
                              )}
                            </div>
                          </TableCell>

                          <Tooltip
                            title={
                              user?.applicationName?.length > 0
                                ? user?.applicationName?.join(", ")
                                : "-"
                            }
                          >
                            <TableCell
                              // align="center"
                              className={`${classes.usersTableBodyCell} ${classes.usersTableBodyTextHide}`}
                            >
                              {user?.applicationName?.length > 0
                                ? user?.applicationName?.join(", ")
                                : "-"}
                            </TableCell>
                          </Tooltip>

                          <TableCell
                            // align="center"
                            className={classes.usersTableBodyCell}
                          >
                            {user?.userId}
                          </TableCell>

                          <TableCell
                            // align="center"
                            className={`${classes.usersTableBodyCell} ${classes.usersTableBodyTextHide}`}
                          >
                            {user?.emailId}
                          </TableCell>

                          <TableCell
                            // align="center"
                            className={classes.usersTableBodyCell}
                          >
                            {user?.status}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.usersTableBodyCell}
                          >
                            {/* <Tooltip title="View">
                              <IconButton
                                aria-label="View"
                                color="primary"
                                onClick={() => editUser(user?.id)}
                              >
                                <ArrowForwardIos style={{ fontSize: 16 }} />
                              </IconButton>
                            </Tooltip> */}

                            {user?.status !== "Draft" ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  aria-label="Edit"
                                  color="primary"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    changeStatus(user);
                                  }}
                                  disabled={load}
                                >
                                  <Create style={{ fontSize: 16 }} />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <>
                                <Tooltip title="Check">
                                  <IconButton
                                    aria-label="Check"
                                    color="primary"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateUser(user);
                                    }}
                                    disabled={load}
                                  >
                                    <Check style={{ fontSize: 16 }} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Discard">
                                  <IconButton
                                    aria-label="Discard"
                                    color="secondary"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      changeStatus(user);
                                      setFilteredUsers(
                                        basicReducerState?.users
                                      );
                                    }}
                                    disabled={load}
                                  >
                                    <Close style={{ fontSize: 16 }} />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}

                            {user?.status !== "Draft" && (
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  color="secondary"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // deleteUser(user?.id);
                                    setDeletingUser(user?.id);
                                  }}
                                  disabled={load}
                                >
                                  <Delete style={{ fontSize: 16 }} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>

          {params?.userId && (
            <Grid item xs={params?.userId ? 6 : false}>
              <UserDetail params={params} setParams={setParams} />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Users;
