import React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  newItemDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newItemDialogActionsContainer: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },
}));

const NewItem = ({
  open,
  onClose,
  title,
  label,
  newItem,
  setNewItem,
  onCreate,
  error,
  errorMessage,
}) => {
  const classes = useStyle();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.newItemDialogTitle}>{title}</DialogTitle>

      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          required
          label="Name"
          value={newItem?.name}
          onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
          error={error}
          helperText={error && errorMessage}
        />

        {label && (
          <TextField
            variant="standard"
            fullWidth
            required
            label="Label"
            value={newItem?.label}
            onChange={(e) => setNewItem({ ...newItem, label: e.target.value })}
          />
        )}

        <TextField
          variant="standard"
          fullWidth
          required
          label="Description"
          value={newItem?.description}
          onChange={(e) =>
            setNewItem({
              ...newItem,
              description: e.target.value,
            })
          }
        />
      </DialogContent>

      <DialogActions className={classes.newItemDialogActionsContainer}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={onClose}
          style={{ borderRadius: 50 }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          style={{ borderRadius: 50 }}
          onClick={() => {
            onCreate();
            onClose();
          }}
          disabled={
            newItem?.name?.length === 0 ||
            (label && newItem?.label?.length === 0) ||
            newItem?.description?.length === 0 ||
            error
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewItem;
