import "./App.css";
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./Components/Loading";
import WorkAccess from "./WorkAccess";
import AccessDenied from "./Components/AccessDenied";
import {
  getAllActivities,
  getAllApis,
  getAllApplications,
  getAllEntities,
  getAllGroups,
  getAllLicenses,
  getAllPayloads,
  getAllRealms,
  getAllRoles,
  getAllSessionUser,
  getAllUsers,
  getIdpUsers,
  getKeycloakAdminToken,
  getSessionCount,
} from "./Actions/action";
import {
  setActivities,
  setApis,
  setApplications,
  setEntities,
  setGroups,
  setIdpUsers,
  setLienses,
  setPayloads,
  setRealms,
  setRoles,
  setSessionUser,
  setUsers,
} from "./Redux/Reducers/basicReducer";
import {
  setKeycloak,
  setToken,
  setUser,
  setEntitiesAndActivities,
} from "./Redux/Reducers/userReducer";
import { authVerify } from "./Utility/auth";
import { darkTheme, lightTheme } from "./Utility/theme";
import { completeEntities } from "./Utility/config";
import AlreadyLogin from "./Components/AlreadyLogin";
import { zipkinFetch } from "./Utility/browser";

function App({ keycloak }) {
  const [load, setLoad] = useState(true);
  const [access, setAccess] = useState(false);
  const [session, setSession] = useState({
    active_session: 0,
    max_session: -1,
  });
  const dispatch = useDispatch();
  const userReducerState = useSelector((state) => state.userReducer);
  const [darkMode, setDarkMode] = useState(userReducerState.darkMode);
  const [userEntitiesList, setUserEntitiesList] = useState([]);

  useEffect(() => {
    saveBasicDetail();
    // getAccessResult();
    getSession();
  }, []);
  useEffect(() => {
    setDarkMode(userReducerState.darkMode);
  }, [userReducerState.darkMode]);
  useEffect(() => {
    if (session?.active_session <= session?.max_session && !access) {
      getAccessResult();
    }
  }, [session]);
  useEffect(() => {
    if (access && session?.active_session <= session?.max_session) {
      if (userEntitiesList?.includes(completeEntities?.USERMODULE)) {
        getAllUsers(
          userReducerState.token,
          () => {
            setLoad(true);
          },
          (data) => {
            dispatch(setUsers(data?.data || []));
            // setLoad(false);
          },
          (err) => {
            setLoad(false);
          }
        );
        getIdpUsers(
          userReducerState.token,
          () => {
            setLoad(true);
          },
          (data) => {
            dispatch(setIdpUsers(data?.data || []));
            setLoad(false);
          },
          (err) => {
            setLoad(false);
          }
        );
      }
      if (userEntitiesList?.includes(completeEntities?.ROLEMODULE)) {
        getAllRoles(
          userReducerState.token,
          () => {
            // setLoad(true);
          },
          (data) => {
            dispatch(setRoles(data?.data || []));
            // setLoad(false);
          },
          (err) => {
            // setLoad(false);
          }
        );
      }
      if (userEntitiesList?.includes(completeEntities?.APPLICATIONMODULE)) {
        getAllApplications(
          userReducerState.token,
          () => {
            // setLoad(true);
          },
          (data) => {
            dispatch(setApplications(data?.data || []));
            // setLoad(false);
          },
          (err) => {
            // setLoad(false);
          }
        );
        getAllEntities(
          userReducerState.token,
          () => {
            // setLoad(true);
          },
          (data) => {
            dispatch(setEntities(data?.data || []));
            // setLoad(false);
          },
          (err) => {
            // setLoad(false);
          }
        );
        getAllApis(
          userReducerState.token,
          () => {
            // setLoad(true);
          },
          (data) => {
            dispatch(setApis(data?.data || []));
            // setLoad(false);
          },
          (err) => {
            // setLoad(false);
          }
        );
        getAllActivities(
          userReducerState.token,
          () => {
            // setLoad(true);
          },
          (data) => {
            dispatch(setActivities(data?.data || []));
            // setLoad(false);
          },
          (err) => {
            // setLoad(false);
          }
        );
        getAllPayloads(
          userReducerState.token,
          () => {
            // setLoad(true);
          },
          (data) => {
            dispatch(setPayloads(data?.data || []));
            // setLoad(false);
          },
          (err) => {
            // setLoad(false);
          }
        );
      }
      if (userEntitiesList?.includes(completeEntities?.KEYCLOAKMODULE)) {
        getKeycloakAdminToken(
          userReducerState.token,
          () => {
            // setLoad(true);
          },
          (data) => {
            // setLoad(false);
            getAllRealms(
              data.access_token,
              () => {
                // setLoad(true);
              },
              (data) => {
                dispatch(setRealms(data || []));
                // setLoad(false);
              },
              (err) => {
                // setLoad(false);
              }
            );
          },
          (err) => {
            // setLoad(false);
          }
        );
      }
      if (userEntitiesList?.includes(completeEntities?.LICENSEMODULE)) {
        getAllLicenses(
          userReducerState.token,
          () => {
            // setLoad(true);
          },
          (data) => {
            dispatch(setLienses(data?.data || []));
            // setLoad(false);
          },
          (err) => {
            setLoad(false);
          }
        );
      }
      getAllGroups(
        userReducerState.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setGroups(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          setLoad(false);
        }
      );
      getAllSessionUser(
        userReducerState.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setSessionUser(data || []));
          // setLoad(false);
        },
        (err) => {
          setLoad(false);
        }
      );
    }
  }, [access]);

  const saveBasicDetail = () => {
    // dispatch(setKeycloak(keycloak));
    dispatch(setToken(keycloak.token));
    dispatch(setUser(keycloak.tokenParsed));
  };
  const getAccessResult = () => {
    if (authVerify(keycloak.token)) {
      // roles, entities and activities
      const applicationName = "WORKACCESS";
      const getEntitiesAndActivitiesUrl = `${process.env.REACT_APP_WAServices}/api/v1/applications/entitiesAndActivities?applicationName=${applicationName}`;
      const getEntitiesAndActivitiesRequestParam = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${keycloak?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      // const getEntitiesAndActivitiesUrl = `/localhost/api`;
      // const getEntitiesAndActivitiesPayload = {
      //   name: "readEntitiesAndActivities",
      //   type: "query",
      //   args: ["WorkAccess"],
      // };
      // const getEntitiesAndActivitiesRequestParam = {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${keycloak.token}`,
      //   },
      //   body: JSON.stringify(getEntitiesAndActivitiesPayload),
      // };
      setLoad(true);
      zipkinFetch(getEntitiesAndActivitiesUrl, getEntitiesAndActivitiesRequestParam)
        .then((res) => res.json())
        .then((data) => {
          dispatch(setEntitiesAndActivities(data?.data || {}));
          setUserEntitiesList(
            Object.keys(data?.data?.entitiesAndActivities?.[0] || [])
          );
          const roles = data?.data?.roles || [];
          if (roles?.length > 0) {
            setAccess(true);
            setLoad(false);
          } else {
            setAccess(false);
            setLoad(false);
          }
        })
        .catch((err) => {
          setAccess(false);
          setLoad(false);
        });
    } else {
    }
  };
  const getSession = () => {
    setSession({ active_session: 0, max_session: 2 });
    // getSessionCount(
    //   keycloak.token,
    //   () => {
    //     setLoad(true);
    //   },
    //   (data) => {
    //     setSession(data);
    //     setLoad(false);
    //   },
    //   (err) => {
    //     setLoad(false);
    //   }
    // );
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      {!load ? (
        session?.max_session === null ||
        session?.max_session === undefined ||
        session?.max_session === 0 ? (
          <AlreadyLogin
            keycloak={keycloak}
            message="You don't have license. Please contact to System Administrator."
          />
        ) : session?.active_session > session?.max_session ? (
          <AlreadyLogin keycloak={keycloak} message="Maximum login exceeded." />
        ) : access ? (
          <WorkAccess keycloak={keycloak} />
        ) : (
          <AlreadyLogin
            keycloak={keycloak}
            message="You don't have access to this application. Please contact to System Administratior."
          />
        )
      ) : (
        <Loading load={load} />
      )}
    </ThemeProvider>
  );
}

export default App;
