import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  DashboardOutlined,
  Dashboard,
  HomeOutlined,
  Home,
  ChatBubbleOutline,
  ChatBubble,
  AssessmentOutlined,
  Assessment,
  NetworkCheck,
  Build,
  BuildOutlined,
  ScatterPlot,
  ScatterPlotOutlined,
  DnsOutlined,
  Dns,
  GroupOutlined,
  Group,
} from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { completeEntities } from "../Utility/config";

const useStyle = makeStyles((theme) => ({
  sidebarItemContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(1),
    position: "relative",
    cursor: "pointer",
    // overflow: "visible",
    color: theme.palette.text.primary,
  },
  sidebarItemStrip: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    left: 0,
    top: 0,
    bottom: 0,
    width: 3,
    marginTop: 15,
    marginBottom: 15,
    borderRadius: 20,
  },
  sidebarItemSelected: {
    color: theme.palette.primary.main,
  },

  sidebarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 72,
    boxShadow: "0px 8px 24px rgba(192, 192, 192, 0.25)",
    height: "92vh",
    background: theme.palette.background.paper,
  },
  sidebarItemTitle: {
    fontSize: theme.spacing(1.5),
    textAlign: "center",
  },
  sidebarBottomContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
}));

const SidebarItem = ({ Icon, FilledIcon, title, selected, to }) => {
  const classes = useStyle();

  return (
    <Link to={to} className={`${classes.sidebarItemContainer} waScroll`}>
      {/* {selected && <div className={classes.sidebarItemStrip} />} */}
      {selected ? (
        <FilledIcon className={classes.sidebarItemSelected} />
      ) : (
        <Icon />
      )}
      <Typography
        className={`${classes.sidebarItemTitle} ${
          selected && classes.sidebarItemSelected
        }`}
      >
        {title}
      </Typography>
    </Link>
  );
};

function Sidebar() {
  const classes = useStyle();
  const location = useLocation();
  const pathname = location.pathname.split("/");
  // const role = "WORKACCESS ADMIN";
  const userReducerState = useSelector((state) => state.userReducer);
  const userEntitiesList =
    Object.keys(
      userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
    ) || [];

  return (
    <div className={`${classes.sidebarContainer}`}>
      <SidebarItem
        Icon={DashboardOutlined}
        FilledIcon={Dashboard}
        title="Dashboard"
        selected={pathname?.[1] === "dashboard"}
        to="/dashboard"
        badgeValue={0}
      />
      {userEntitiesList?.includes(completeEntities?.USERMODULE) && (
        <SidebarItem
          Icon={HomeOutlined}
          FilledIcon={Home}
          title="Users"
          selected={pathname?.[1] === "users"}
          to="/users"
          badgeValue={0}
        />
      )}
      {userEntitiesList?.includes(completeEntities?.ROLEMODULE) && (
        <SidebarItem
          Icon={ScatterPlotOutlined}
          FilledIcon={ScatterPlot}
          title="Roles"
          selected={pathname?.[1] === "roles"}
          to="/roles"
          badgeValue={0}
        />
      )}
      {userEntitiesList?.includes(completeEntities?.KEYCLOAKMODULE) && (
        <SidebarItem
          Icon={ChatBubbleOutline}
          FilledIcon={ChatBubble}
          title="Keycloak User"
          selected={pathname?.[1] === "keycloakUser"}
          to="/keycloakUser"
          badgeValue={0}
        />
      )}
      {userEntitiesList?.includes(completeEntities?.APPLICATIONMODULE) && (
        <SidebarItem
          Icon={AssessmentOutlined}
          FilledIcon={Assessment}
          title="Applications"
          selected={pathname?.[1] === "applications"}
          to="/applications"
          badgeValue={0}
        />
      )}
      <SidebarItem
        Icon={GroupOutlined}
        FilledIcon={Group}
        title="Groups"
        selected={pathname?.[1] === "groups"}
        to="/groups"
        badgeValue={0}
      />
      {userEntitiesList?.includes(completeEntities?.KEYCLOAKMODULE) && (
        <SidebarItem
          Icon={BuildOutlined}
          FilledIcon={Build}
          title="Realms"
          selected={pathname?.[1] === "realms"}
          to="/realms"
          badgeValue={0}
        />
      )}
      {userEntitiesList?.includes(completeEntities?.LICENSEMODULE) && (
        <SidebarItem
          Icon={NetworkCheck}
          FilledIcon={NetworkCheck}
          title="Licenses"
          selected={pathname?.[1] === "licenses"}
          to="/licenses"
          badgeValue={0}
        />
      )}
      
      {/* <SidebarItem
        Icon={DnsOutlined}
        FilledIcon={Dns}
        title="Rules"
        selected={pathname?.[1] === "rules"}
        to="/rules"
        badgeValue={0}
      /> */}
    </div>
  );
}

export default Sidebar;
