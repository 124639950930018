// setup tracer
import { recorder } from "./recorder";
import { Tracer, ExplicitContext } from "zipkin";

const ctxImpl = new ExplicitContext();
const localServiceName = "workacess-ui-qa";
const tracer = new Tracer({
  ctxImpl,
  recorder: recorder(localServiceName),
  localServiceName,
});

// instrument fetch
const wrapFetch = require("zipkin-instrumentation-fetch");
export const zipkinFetch = wrapFetch(fetch, { tracer });
