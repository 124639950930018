export const completeEntities = {
  USERMODULE: "UserModule",
  ROLEMODULE: "RoleModule",
  KEYCLOAKMODULE: "KeycloakModule",
  APPLICATIONMODULE: "ApplicationModule",
  LICENSEMODULE: "LicenseModule",
};

export const completeFeatures = {
  ADDUSER: "AddUser",
  DELETEUSER: "DeleteUser",
  UPDATEUSERDETAIL: "UpdateUserDetail",
  CREATEROLE: "CreateRole",
  DELETEROLE: "DeleteRole",
  UPDATEROLEDETAIL: "UpdateRoleDetail",
  CREATEREALM: "CreateRealm",
  UPDATEREALM: "UpdateRealm",
  DELETEREALM: "DeleteRealm",
  CREATELICENSE: "CreateLicense",
  CREATEAPPLICATION: "CreateApplication",
  DELETEAPPLICATION: "DeleteApplication",
  CREATEENTITY: "CreateEntity",
  DELETEENTITY: "DeleteEntity",
  CREATEACTIVITY: "CreateActivity",
  DELETEACTIVITY: "DeleteActivity",
};
