import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Keycloak from "keycloak-js";
import { Provider } from "react-redux";
import App from "./App";
import store from "./Redux/store";

const keycloak = Keycloak(
  window.location?.hostname === "localhost"
    ? "/localhostKeycloak.json"
    : window.location?.hostname === "wadev.cherryworkproducts.com"
    ? "/devAzureKeycloak.json"
    : "/qaAzureKeycloak.json"
);

keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
  return ReactDOM.render(
    authenticated ? (
      <Provider store={store}>
        {/* <ThemeProvider theme={darkTheme}> */}
        <App keycloak={keycloak} />
        {/* </ThemeProvider> */}
      </Provider>
    ) : null,
    document.getElementById("root")
  );
});
