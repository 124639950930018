import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Refresh, ExitToApp } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAllSessionUser } from "../../Actions/action";
import { authVerify } from "../../Utility/auth";
import { setSessionUser } from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import { zipkinFetch } from "../../Utility/browser";

const useStyle = makeStyles((theme) => ({
  sessionUsersContainer: {
    padding: 8,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  sessionUsersHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
  },
  sessionUserHeadeTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  sessionUsersHeaderDropdown: {
    width: 150,
    marginRight: 6,
  },
  sessionUsersHeaderAddButton: {
    marginLeft: 10,
    borderRadius: 50,
  },
  sessionUsersTableContainer: {
    height: "80vh",
    // overflow: "overlay",
    width: "100%",
  },
  sessionUsersTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  sessionUsersTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  sessionUsersTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  sessionUsersTableBodyRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  sessionUsersTableBodyRowSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  sessionUsersTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  sessionUsersTableBodyCellOverflow: {
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sessionUsersTableBodyTextHide: {
    overflow: "hidden",
    maxWidth: 180,
    textOverflow: "ellipsis",
  },
}));

function Dashboard() {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [load, setLoad] = useState(false);
  const [selectApplication, setSelectApplication] = useState(-1);
  const [filteredSessionUsers, setFilteredSessionUsers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredSessionUsers(basicReducerState?.sessionUsers);
  }, [basicReducerState?.sessionUsers]);

  const getSessionUsers = () => {
    getAllSessionUser(
      userReducerState.token,
      () => {
        setLoad(true);
      },
      (data) => {
        dispatch(setSessionUser(data || []));
        setLoad(false);
      },
      (err) => {
        setLoad(false);
      }
    );
  };
  const logoutUserAllSession = (userId, realmName) => {
    if (authVerify(userReducerState.token)) {
      if (userId && realmName) {
        setLoad(true);
        const logoutUserAllSessionUrl = `${process.env.REACT_APP_WAServices}/api/v1/keycloak/logoutUserSession?userId=${userId}&realmName=${realmName}`;
        const logoutUserAllSessionRequestParam = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userReducerState.token}`,
            "Content-Type": "application/json",
          },
        };
        zipkinFetch(logoutUserAllSessionUrl, logoutUserAllSessionRequestParam)
          .then((res) => res.json())
          .then((data) => {
            dispatch(
              setSessionUser(
                basicReducerState?.sessionUsers?.filter(
                  (s_user) => s_user?.userId !== userId
                ) || []
              )
            );
            setLoad(false);
          })
          .catch((err) => {
            setLoad(false);
          });
      }
    } else {
      window.location.reload(0);
    }
  };

  return (
    <div className={classes.sessionUsersContainer}>
      <Loading load={load} />

      <div className={classes.sessionUsersHeaderContainer}>
        <Typography className={classes.sessionUserHeadeTitle}>
          Active Users Session
        </Typography>

        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl
            variant="standard"
            size="small"
            required
            className={classes.sessionUsersHeaderDropdown}
          >
            <InputLabel>Application name</InputLabel>
            <Select
              size="small"
              style={{ fontSize: 12 }}
              value={selectApplication}
              onChange={(e) => {
                setSelectApplication(e.target.value);
                // if (e.target.value !== -1) {
                //   setFilteredRoles(
                //     roles?.filter(
                //       (role) => role?.applicationId === e.target.value
                //     ) || []
                //   );
                // } else {
                //   setFilteredRoles(roles);
                // }
              }}
            >
              <MenuItem value={-1} style={{ fontSize: 12 }}>
                All
              </MenuItem>
              {basicReducerState?.applications?.map((application, index) => (
                <MenuItem
                  key={`${application?.id}-${index}`}
                  value={application?.id}
                  style={{ fontSize: 12 }}
                >
                  {application?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Tooltip title="Refresh">
            <IconButton
              size="small"
              style={{ marginLeft: 4 }}
              disabled={load}
              onClick={getSessionUsers}
            >
              <Refresh style={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <TableContainer
        component={Paper}
        className={`${classes.sessionUsersTableContainer} waScroll`}
        // style={{width}}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.sessionUsersTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.sessionUsersTableHeadCell}
              >
                UserId
              </TableCell>

              <TableCell
                // align="center"
                className={classes.sessionUsersTableHeadCell}
              >
                Username
              </TableCell>

              <TableCell
                // align="center"
                className={classes.sessionUsersTableHeadCell}
              >
                IP Address
              </TableCell>

              <TableCell
                // align="center"
                className={classes.sessionUsersTableHeadCell}
              >
                License Number
              </TableCell>

              <TableCell
                // align="center"
                className={classes.sessionUsersTableHeadCell}
              >
                Realm
              </TableCell>

              <TableCell
                // align="center"
                className={classes.sessionUsersTableHeadCell}
              >
                Duration
              </TableCell>

              <TableCell
                align="center"
                className={classes.sessionUsersTableHeadCell}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={`${classes.sessionUsersTableBody} waScroll`}>
            {filteredSessionUsers?.map((sessionUser, index) => {
              return (
                <TableRow
                  key={`${sessionUser?.ipAddress}-${index}`}
                  className={
                    `${classes.sessionUsersTableBodyRow}`
                    //   ${
                    //     Number(sessionUser?.id) === Number(params?.userId) &&
                    //     classes.sessionUsersTableBodyRowSelected
                    //   }
                    //   onClick={() => editUser(sessionUser?.id)}
                  }
                >
                  <Tooltip title={sessionUser?.userId}>
                    <TableCell
                      // align="center"
                      className={`${classes.sessionUsersTableBodyCell} ${classes.sessionUsersTableBodyCellOverflow}`}
                    >
                      {sessionUser?.userId}
                    </TableCell>
                  </Tooltip>

                  <TableCell
                    // align="center"
                    className={classes.sessionUsersTableBodyCell}
                  >
                    {sessionUser?.username}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.sessionUsersTableBodyCell}
                  >
                    {sessionUser?.ipAddress}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={`${classes.sessionUsersTableBodyCell} ${classes.sessionUsersTableBodyTextHide}`}
                  >
                    {sessionUser?.licenseNumber || "-"}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={`${classes.sessionUsersTableBodyCell} ${classes.sessionUsersTableBodyTextHide}`}
                  >
                    {sessionUser?.realmName || "-"}
                  </TableCell>

                  <Tooltip
                    title={`${sessionUser?.start} - ${sessionUser?.lastAccess}`}
                  >
                    <TableCell
                      // align="center"
                      className={`${classes.sessionUsersTableBodyCell} ${classes.sessionUsersTableBodyCellOverflow}`}
                    >
                      {`${sessionUser?.start} - ${sessionUser?.lastAccess}`}
                    </TableCell>
                  </Tooltip>

                  <TableCell align="center">
                    <Tooltip title="Logout">
                      <IconButton
                        disabled={
                          load ||
                          !sessionUser?.userId ||
                          !sessionUser?.realmName
                        }
                        size="small"
                        color="secondary"
                        onClick={() =>
                          logoutUserAllSession(
                            sessionUser?.userId,
                            sessionUser?.realmName
                          )
                        }
                      >
                        <ExitToApp />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Dashboard;
// https://waservices.cherryworkproducts.com
