import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  ButtonGroup,
  Checkbox,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Add,
  Dashboard,
  TrendingUp,
  FilterList,
  CheckBoxOutlineBlank,
  CheckBox,
  Refresh,
  Edit,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import { findEntityById, showFeatureNumber } from "../../Utility/basic";
import { getAllLicenses } from "../../Actions/action";
import { setLienses } from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import NotFound from "../NotFound";
import GraphViewLicense from "./GraphViewLicense";
import { zipkinFetch } from "../../Utility/browser";

const useStyle = makeStyles((theme) => ({
  newLicenseDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newLicenseDialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  newLicenseDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  licenseContainer: {
    position: "relative",
    borderRadius: 20,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow:
      "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
    padding: 10,
    height: 170,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  licenseSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  licenseNumberLabel: {
    fontSize: 14,
    fontWeight: "bold",
  },
  licenseNumberValue: {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  licenseLabel: {
    fontSize: 12,
    fontWeight: "bold",
  },
  licenseValue: {
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  // licenseNumber: {
  //   fontSize: 14,
  //   fontWeight: "bold",
  //   whiteSpace: "nowrap",
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  // },
  // licenseType: {
  //   fontSize: 12,
  //   whiteSpace: "nowrap",
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  // },
  // licenseApplication: {
  //   fontSize: 12,
  //   whiteSpace: "nowrap",
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  // },
  licenseStatusContainer: {
    display: "flex",
    alignItems: "center",
  },
  licenseStatusBadge: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 4,
    backgroundColor: theme.palette.success.main,
  },
  licenseAssignedStatusBadge: {
    backgroundColor: theme.palette.error.main,
  },
  licenseStatusText: {
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  licenseAssignedUsersContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  licenseAssignedUserEmail: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: 8,
    fontSize: 12,
  },
  licenseAssignedOtherUserCount: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 20,
    padding: "4px 6px",
    fontSize: 10,
    cursor: "pointer",
  },
  licenseEditIcon: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },

  licensesHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
    marginTop: 8,
    padding: "0px 10px",
  },
  licenseHeaderTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
    marginLeft: 6,
  },
  licensesHeaderDropdown: {
    margin: "2px 0px",
    width: 150,
    marginRight: 8,
  },
  licensesHeaderFilterButton: {
    marginRight: 8,
    borderRadius: 50,
  },
  licensesHeaderAddButton: {
    marginLeft: 10,
    borderRadius: 50,
  },
  licensesCardContainer: {
    height: "84vh",
    // overflow: "overlay",
    padding: 10,
  },
}));

const NewLicense = ({ open, onClose }) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();

  const initialNewLicense = {
    applicationName: "",
    quantity: 0,
    licenseType: "",
    duration: "",
    activities: [],
    maxSession: 1,
  };
  const [newLicense, setNewLicense] = useState(initialNewLicense);
  const [load, setLoad] = useState(false);

  const handleCreateLicense = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const createLicensesUrl = `${process.env.REACT_APP_WAServices}/api/v1/license`;
      const payload = {
        applicationName: newLicense?.applicationName,
        licenseType: newLicense?.licenseType,
        quantity: Number(newLicense?.quantity),
        duration: newLicense?.duration,
        featuresId:
          newLicense?.licenseType === "Limited Feature"
            ? newLicense?.activities?.map((activity) => activity?.id).join(",")
            : "",
        maxSession: newLicense?.maxSession,
        activeSession: 0,
      };
      const createLicensesRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(payload),
      };
      zipkinFetch(createLicensesUrl, createLicensesRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          onClose();
          setNewLicense(initialNewLicense);
          // resetFilter();
          getAllLicenses(
            userReducerState.token,
            () => {
              setLoad(true);
            },
            (data) => {
              dispatch(setLienses(data?.data || []));
              setLoad(false);
            },
            (err) => {
              setLoad(false);
            }
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const isApplicationActivity = (activity, appName) => {
    const application = basicReducerState?.applications?.find(
      (application) => application?.name === appName
    );
    const entitiesId = basicReducerState?.entities
      ?.filter(
        (entity) => Number(entity?.applicationId) === Number(application?.id)
      )
      ?.map((entity) => Number(entity?.id));
    return entitiesId?.includes(Number(activity?.entityId));
  };
  const getEntityNameById = (entityId) => {
    const entity = findEntityById(
      Number(entityId),
      basicReducerState?.entities
    );
    return entity?.name || "";
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.newLicenseDialogTitle}>
        New License
      </DialogTitle>

      <DialogContent className={classes.newLicenseDialogContent}>
        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel id="applicationName">Application Name</InputLabel>
          <Select
            labelId="applicationName"
            size="small"
            label="Application Name"
            style={{ fontSize: 12 }}
            value={newLicense?.applicationName}
            onChange={(e) =>
              setNewLicense({ ...newLicense, applicationName: e.target.value })
            }
          >
            {basicReducerState?.applications?.map((application, index) => (
              <MenuItem
                key={index}
                value={application?.name}
                style={{ fontSize: 12 }}
              >
                {application?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel id="licenseType">Type</InputLabel>
          <Select
            labelId="licenseType"
            size="small"
            label="Type"
            style={{ fontSize: 12 }}
            value={newLicense?.licenseType}
            onChange={(e) =>
              setNewLicense({
                ...newLicense,
                licenseType: e.target.value,
                activities: [],
              })
            }
          >
            <MenuItem value="Full Application" style={{ fontSize: 12 }}>
              Full Application
            </MenuItem>
            <MenuItem value="Limited Feature" style={{ fontSize: 12 }}>
              Limited Feature
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          required
          min={0}
          variant="standard"
          label="Quantity"
          type="number"
          value={newLicense?.quantity}
          onChange={(e) =>
            setNewLicense({
              ...newLicense,
              quantity: Math.max(0, e.target.value),
            })
          }
        />

        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel id="duartion">Duration</InputLabel>
          <Select
            labelId="duartion"
            size="small"
            label="Duartion"
            style={{ fontSize: 12 }}
            value={newLicense?.duration}
            onChange={(e) =>
              setNewLicense({ ...newLicense, duration: e.target.value })
            }
          >
            <MenuItem value={"1 year"} style={{ fontSize: 12 }}>
              {"1 year"}
            </MenuItem>
            <MenuItem value={"6 months"} style={{ fontSize: 12 }}>
              {"6 months"}
            </MenuItem>
          </Select>
        </FormControl>

        {newLicense?.licenseType === "Limited Feature" && (
          <Autocomplete
            multiple
            size="small"
            limitTags={2}
            style={{ fontSize: 12 }}
            value={newLicense?.activities}
            onChange={(e, activities) => {
              setNewLicense({
                ...newLicense,
                activities: activities,
              });
            }}
            options={basicReducerState?.activities?.filter((activity) =>
              isApplicationActivity(activity, newLicense?.applicationName)
            )}
            getOptionLabel={(option) => {
              return `${option.name} (${getEntityNameById(option?.entityId)})`;
            }}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox color="primary" fontSize="small" />}
                  checked={selected}
                />
                <Typography style={{ fontSize: 12 }}>{`${
                  option.name
                } (${getEntityNameById(option?.entityId)})`}</Typography>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Associate Features"
                style={{ fontSize: 12 }}
              />
            )}
          />
        )}

        <TextField
          fullWidth
          min={1}
          variant="standard"
          label="Max session"
          type="number"
          value={newLicense?.maxSession}
          onChange={(e) =>
            setNewLicense({
              ...newLicense,
              maxSession: Math.max(1, e.target.value),
            })
          }
        />
      </DialogContent>

      <DialogActions className={classes.newLicenseDialogActions}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            onClose();
            setNewLicense(initialNewLicense);
          }}
          style={{ borderRadius: 50 }}
          disabled={load}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          onClick={handleCreateLicense}
          style={{ borderRadius: 50 }}
          disabled={
            newLicense?.applicationName?.length === 0 ||
            newLicense?.licenseType?.length === 0 ||
            newLicense?.quantity <= 0 ||
            newLicense?.duration?.length === 0 ||
            load
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdateLicense = ({
  open,
  onClose,
  selectedLicense,
  setSelectedLicense,
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();

  const [updateLicenseUser, setUpdateLicenseUser] = useState([]);
  const [load, setLoad] = useState(false);

  const handleUpdateLicense = () => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const updateLicenseUrl = `${process.env.REACT_APP_WAServices}/api/v1/license/mapLicenseUsers`;
      const updateLicensePayload = updateLicenseUser?.map((user) => ({
        userId: Number(user?.id),
        userName: user?.emailId,
        applicationName: selectedLicense?.applicationName,
        licenseNumber: selectedLicense?.licenseNumber,
        status: "Draft",
      }));
      const updateLicenseRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState?.token}`,
        },
        body: JSON.stringify(updateLicensePayload),
      };
      // console.log(updateLicensePayload);
      zipkinFetch(updateLicenseUrl, updateLicenseRequestParam)
        .then((res) => res.json())
        .then((data) => {
          dispatch(
            setLienses(
              basicReducerState?.licenses?.map((license) =>
                license?.licenseNumber === selectedLicense?.licenseNumber
                  ? {
                      ...license,
                      status: "Assigned",
                      usersEmail: [
                        ...license?.usersEmail,
                        ...updateLicenseUser?.map((user) => user?.emailId),
                      ],
                    }
                  : { ...license }
              )
            )
          );
          setUpdateLicenseUser([]);
          onClose();
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const isAssignedUser = (user) => {
    const licenses = basicReducerState?.licenses?.filter(
      (license) => license?.applicationName === selectedLicense?.applicationName
    );
    for (let i = 0; i < licenses?.length; i++) {
      if (licenses?.[i]?.usersEmail?.includes(user?.emailId)) {
        return true;
      }
    }
    if (user?.applicationName?.includes(selectedLicense?.applicationName)) {
      return false;
    }
    return true;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.newLicenseDialogTitle}>
        Assign Users
      </DialogTitle>

      <DialogContent className={classes.newLicenseDialogContent}>
        <Loading load={load} />

        <Autocomplete
          multiple
          size="small"
          limitTags={2}
          style={{ fontSize: 12 }}
          value={updateLicenseUser}
          onChange={(e, users) => {
            setUpdateLicenseUser(users);
          }}
          options={basicReducerState?.users?.filter(
            (user) => !isAssignedUser(user)
          )}
          getOptionLabel={(option) => {
            return `${option.displayName}`;
          }}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />

              {/* <CustomAvatar
                name={option?.displayName}
                style={{
                  marginRight: 6,
                  marginLeft: 8,
                }}
              /> */}

              <Typography
                style={{ fontSize: 12 }}
              >{`${option.displayName}`}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Users"
              style={{ fontSize: 12 }}
            />
          )}
        />
      </DialogContent>

      <DialogActions className={classes.newLicenseDialogActions}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            onClose();
            setUpdateLicenseUser([]);
          }}
          style={{ borderRadius: 50 }}
          disabled={load}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          onClick={handleUpdateLicense}
          style={{ borderRadius: 50 }}
          disabled={updateLicenseUser?.length === 0 || load}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const License = ({ license, index }) => {
  const classes = useStyle();
  const [updateLicense, setUpdateLicense] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState(null);

  return (
    <Paper
      key={index}
      elevation={0}
      className={`${classes.licenseContainer} ${
        selectedLicense?.licenseNumber === license?.licenseNumber &&
        classes.licenseSelected
      }`}
    >
      <UpdateLicense
        open={updateLicense}
        onClose={() => {
          setUpdateLicense(false);
          setSelectedLicense(null);
        }}
        selectedLicense={selectedLicense}
        setSelectedLicense={setSelectedLicense}
      />

      <Typography className={classes.licenseNumberValue}>
        <span className={classes.licenseNumberLabel}>License Number: </span>
        {license?.licenseNumber}
      </Typography>

      <Typography className={classes.licenseValue}>
        <span className={classes.licenseLabel}>Type: </span>
        {license?.licenseType} ({license?.duration})
      </Typography>

      <Typography className={classes.licenseValue}>
        <span className={classes.licenseLabel}>Application: </span>
        {`${license?.applicationName} ${showFeatureNumber(license)}`}
      </Typography>

      <Typography className={classes.licenseValue}>
        <span className={classes.licenseLabel}>Maximum Active Session: </span>
        {`${license?.maxSession}`}
      </Typography>

      <div className={classes.licenseStatusContainer}>
        <div
          className={`${classes.licenseStatusBadge} ${
            license?.status !== "Available" &&
            classes.licenseAssignedStatusBadge
          }`}
        />
        <Typography className={classes.licenseStatusText}>
          {license?.status}
        </Typography>
      </div>

      {license?.usersEmail?.length > 0 && (
        <div className={classes.licenseAssignedUsersContainer}>
          <Typography className={classes.licenseAssignedUserEmail}>
            {license?.usersEmail?.[0]}
          </Typography>

          {license?.usersEmail?.length - 1 > 0 && (
            <Tooltip arrow title={license?.usersEmail?.slice(1)?.join(", ")}>
              <Typography className={classes.licenseAssignedOtherUserCount}>
                +{license?.usersEmail?.length - 1}
              </Typography>
            </Tooltip>
          )}
        </div>
      )}

      <IconButton
        size="small"
        color="primary"
        className={classes.licenseEditIcon}
        onClick={() => {
          setUpdateLicense(true);
          setSelectedLicense(license);
        }}
      >
        <Edit style={{ fontSize: 14 }} />
      </IconButton>
    </Paper>
  );
};

const FilterLicenseDialog = ({ open, onClose, setFilterLicenses }) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const initialLicenseFilterState = {
    application: "All",
    licenseStatus: "All",
    licenseType: "All",
  };
  const [filterLicense, setFilterLicense] = useState(initialLicenseFilterState);

  const handleFilterLicense = () => {
    setFilterLicenses(basicReducerState.licenses || []);
    var filterLicenseList = basicReducerState.licenses || [];
    if (
      filterLicense?.application !== "All" &&
      filterLicense?.licenseStatus !== "All" &&
      filterLicense?.licenseType !== "All"
    ) {
      filterLicenseList = basicReducerState?.licenses?.filter(
        (license) =>
          license?.applicationName === filterLicense?.application &&
          license?.status === filterLicense?.licenseStatus &&
          license?.licenseType === filterLicense?.licenseType
      );
    }
    if (
      filterLicense?.application !== "All" &&
      filterLicense?.licenseStatus !== "All" &&
      filterLicense?.licenseType === "All"
    ) {
      filterLicenseList = basicReducerState?.licenses?.filter(
        (license) =>
          license?.applicationName === filterLicense?.application &&
          license?.status === filterLicense?.licenseStatus
      );
    }
    if (
      filterLicense?.application !== "All" &&
      filterLicense?.licenseStatus === "All" &&
      filterLicense?.licenseType !== "All"
    ) {
      filterLicenseList = basicReducerState?.licenses?.filter(
        (license) =>
          license?.applicationName === filterLicense?.application &&
          license?.licenseType === filterLicense?.licenseType
      );
    }
    if (
      filterLicense?.application === "All" &&
      filterLicense?.licenseStatus !== "All" &&
      filterLicense?.licenseType !== "All"
    ) {
      filterLicenseList = basicReducerState?.licenses?.filter(
        (license) =>
          license?.status === filterLicense?.licenseStatus &&
          license?.licenseType === filterLicense?.licenseType
      );
    } else if (
      filterLicense?.application !== "All" &&
      filterLicense?.licenseStatus === "All" &&
      filterLicense?.licenseType === "All"
    ) {
      filterLicenseList = basicReducerState?.licenses?.filter(
        (license) => license?.applicationName === filterLicense?.application
      );
    } else if (
      filterLicense?.application === "All" &&
      filterLicense?.licenseStatus !== "All" &&
      filterLicense?.licenseType === "All"
    ) {
      filterLicenseList = basicReducerState?.licenses?.filter(
        (license) => license?.status === filterLicense?.licenseStatus
      );
    } else if (
      filterLicense?.application === "All" &&
      filterLicense?.licenseStatus === "All" &&
      filterLicense?.licenseType !== "All"
    ) {
      filterLicenseList = basicReducerState?.licenses?.filter(
        (license) => license?.licenseType === filterLicense?.licenseType
      );
    }
    // console.log(filterLicense, filterLicenseList);
    setFilterLicenses(filterLicenseList);
    onClose();
  };
  const resetFilter = () => {
    setFilterLicense(initialLicenseFilterState);
    setFilterLicenses(basicReducerState?.licenses || []);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.newLicenseDialogTitle}>
        Filter License
      </DialogTitle>

      <DialogContent className={classes.newLicenseDialogContent}>
        <FormControl
          variant="standard"
          size="small"
          required
          fullWidth
          // className={classes.licensesHeaderDropdown}
        >
          <InputLabel>Application name</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={filterLicense?.application}
            onChange={(e) => {
              setFilterLicense({
                ...filterLicense,
                application: e.target.value,
              });
            }}
          >
            <MenuItem value={"All"} style={{ fontSize: 12 }}>
              All
            </MenuItem>
            {basicReducerState?.applications?.map((application, index) => (
              <MenuItem
                key={`${application?.id}-${index}`}
                value={application?.name}
                style={{ fontSize: 12 }}
              >
                {application?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          variant="standard"
          size="small"
          required
          fullWidth
          // className={classes.licensesHeaderDropdown}
        >
          <InputLabel>License Status</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={filterLicense?.licenseStatus}
            onChange={(e) => {
              setFilterLicense({
                ...filterLicense,
                licenseStatus: e.target.value,
              });
            }}
          >
            <MenuItem value={"All"} style={{ fontSize: 12 }}>
              All
            </MenuItem>
            <MenuItem value={"Available"} style={{ fontSize: 12 }}>
              Available
            </MenuItem>
            <MenuItem value={"Assigned"} style={{ fontSize: 12 }}>
              Assigned
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl
          variant="standard"
          size="small"
          required
          fullWidth
          // className={classes.licensesHeaderDropdown}
        >
          <InputLabel>License Type</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={filterLicense?.licenseType}
            onChange={(e) => {
              setFilterLicense({
                ...filterLicense,
                licenseType: e.target.value,
              });
            }}
          >
            <MenuItem value={"All"} style={{ fontSize: 12 }}>
              All
            </MenuItem>
            <MenuItem value={"Full Application"} style={{ fontSize: 12 }}>
              Full Application
            </MenuItem>
            <MenuItem value={"Limited Feature"} style={{ fontSize: 12 }}>
              Limited Feature
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions className={classes.newLicenseDialogActions}>
        <Button
          color="default"
          variant="outlined"
          onClick={() => {
            onClose();
          }}
          style={{ borderRadius: 50 }}
        >
          Close
        </Button>

        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            resetFilter();
            // onClose();
          }}
          style={{ borderRadius: 50 }}
        >
          Reset
        </Button>

        <Button
          color="primary"
          variant="outlined"
          onClick={handleFilterLicense}
          style={{ borderRadius: 50 }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function Licenses() {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [licenses, setLicenses] = useState([]);
  const [filteredLicences, setFilterLicenses] = useState([]);
  const [openLicenseDialog, setOpenLicenseDialog] = useState(false);
  const [openLicenseFilterDialog, setOpenLicenseFilterDialog] = useState(false);
  const [load, setLoad] = useState(false);
  const [licenseViewType, setLicenseViewType] = useState("Card");
  const dispatch = useDispatch();

  const formateLicenseData = () => {
    return (
      basicReducerState.applications.map((application) => {
        const totalLicense =
          licenses?.filter(
            (license) => license?.applicationName === application.name
          ) || [];
        const assignedLicense =
          totalLicense?.filter((license) => license?.status === "Assigned") ||
          [];
        return {
          applicationName: application.name,
          assigned: assignedLicense.length,
          available: totalLicense.length - assignedLicense.length,
        };
      }) || []
    );
  };
  const [formattedLicenses, setformattedLicenses] = useState(
    formateLicenseData()
  );
  const [graphType, setGraphType] = useState("Bar");

  const getLicenses = () => {
    getAllLicenses(
      userReducerState.token,
      () => {
        setLoad(true);
      },
      (data) => {
        dispatch(setLienses(data?.data || []));
        setLoad(false);
      },
      (err) => {
        setLoad(false);
      }
    );
  };

  useEffect(() => {
    setLicenses(basicReducerState.licenses);
    setFilterLicenses(basicReducerState.licenses);
    setformattedLicenses(formateLicenseData());
  }, [basicReducerState.licenses, licenseViewType]);

  return (
    <div style={{ width: "100%" }}>
      <Loading load={load} />

      <NewLicense
        open={openLicenseDialog}
        onClose={() => {
          setLoad(false);
          setOpenLicenseDialog(false);
        }}
      />

      <FilterLicenseDialog
        open={openLicenseFilterDialog}
        onClose={() => {
          setOpenLicenseFilterDialog(false);
        }}
        setFilterLicenses={setFilterLicenses}
      />

      <div className={classes.licensesHeaderContainer}>
        <Typography className={classes.licenseHeaderTitle}>
          Subscription Management
        </Typography>

        <div style={{ display: "flex", alignItems: "center" }}>
          {licenseViewType === "Card" ? (
            <Button
              size="small"
              variant="text"
              color="primary"
              className={classes.licensesHeaderFilterButton}
              onClick={() => {
                setOpenLicenseFilterDialog(true);
              }}
              startIcon={<FilterList />}
              disabled={load}
            >
              Filter
            </Button>
          ) : (
            <FormControl
              variant="standard"
              size="small"
              required
              className={classes.licensesHeaderDropdown}
            >
              <InputLabel>Graph type</InputLabel>
              <Select
                size="small"
                style={{ fontSize: 12 }}
                value={graphType}
                onChange={(e) => {
                  setGraphType(e.target.value);
                }}
              >
                <MenuItem value={"Bar"} style={{ fontSize: 12 }}>
                  Bar
                </MenuItem>
                <MenuItem value={"Line"} style={{ fontSize: 12 }}>
                  Line
                </MenuItem>
                <MenuItem value={"Area"} style={{ fontSize: 12 }}>
                  Area
                </MenuItem>
              </Select>
            </FormControl>
          )}

          <ButtonGroup size="small">
            <Button
              color="primary"
              variant={licenseViewType === "Card" ? "contained" : "outlined"}
              onClick={() => setLicenseViewType("Card")}
            >
              <Dashboard style={{ fontSize: 18 }} />
            </Button>

            <Button
              color="primary"
              variant={licenseViewType === "Graph" ? "contained" : "outlined"}
              onClick={() => setLicenseViewType("Graph")}
            >
              <TrendingUp style={{ fontSize: 18 }} />
            </Button>
          </ButtonGroup>

          <IconButton
            size="small"
            style={{ marginLeft: 4 }}
            disabled={load}
            onClick={getLicenses}
          >
            <Refresh style={{ fontSize: 20 }} />
          </IconButton>

          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.licensesHeaderAddButton}
            onClick={() => setOpenLicenseDialog(true)}
            startIcon={<Add />}
            disabled={load}
          >
            Add
          </Button>
        </div>
      </div>

      {licenseViewType === "Card" ? (
        <div className={`${classes.licensesCardContainer} waScroll`}>
          {filteredLicences?.length <= 0 ? (
            <NotFound />
          ) : (
            <Grid container spacing={1}>
              {filteredLicences?.map((license, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <License key={index} license={license} index={index} />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      ) : (
        <GraphViewLicense
          graphType={graphType}
          formattedLicenses={formattedLicenses}
        />
      )}
    </div>
  );
}

export default Licenses;
