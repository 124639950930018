import { combineReducers } from "redux";
import basicReducer from "./basicReducer";
import userReducer from "./userReducer";

const reducers = combineReducers({
  userReducer: userReducer,
  basicReducer: basicReducer,
});

export default reducers;
