import { BatchRecorder, jsonEncoder } from "zipkin";
import { HttpLogger } from "zipkin-transport-http";
const { JSON_V2 } = jsonEncoder;

// const debug =
//   "undefined" !== typeof window
//     ? window?.location?.search?.indexOf("debug") !== -1
//     : process.env.REACT_APP_DEBUG;

// Send spans to Zipkin asynchronously over HTTP
const httpLogger = new HttpLogger({
  endpoint: `${process.env.REACT_APP_ZipkinServices}/api/v2/spans`,
  jsonEncoder: JSON_V2,
});

export function recorder(serviceName) {
  //  debug
  //   ? debugRecorder(serviceName)
  //   :
  return new BatchRecorder({ logger: httpLogger });
}

export function debugRecorder(serviceName) {
  // This is a hack that lets you see the data sent to Zipkin!
  const logger = {
    logSpan: (span) => {
      const json = JSON_V2.encode(span);
      console.log(`${serviceName} reporting: ${json}`);
      httpLogger.logSpan(span);
    },
  };
  const batchRecorder = new BatchRecorder({ logger });

  // This is a hack that lets you see which annotations become which spans
  return {
    record: (rec) => {
      const { spanId, traceId } = rec.traceId;
      console.log(
        `${serviceName} recording: ${traceId}/${spanId} ${rec.annotation.toString()}`
      );
      batchRecorder.record(rec);
    },
  };
}

// module.exports.recorder = recorder;
