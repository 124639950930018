import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add, Publish, GetApp, Refresh } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  // useLocation,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import NewItem from "./NewItem";
// import { allActivities, allApplications, allEntities } from "../../Data/data";
import CustomCard from "./CustomCard";
import {
  getAllActivities,
  getAllApis,
  getAllApplications,
  getAllEntities,
  getAllPayloads,
} from "../../Actions/action";
import {
  setActivities,
  setApis,
  setApplications,
  setEntities,
  setPayloads,
} from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import NotFound from "../NotFound";
import NewPayload from "./NewPayload";
import NewApi from "./NewApi";
import {
  activityFileHeading,
  apiFileHeading,
  applicationFileHeading,
  downloadFile,
  entityFileHeading,
  payloadFileHeading,
} from "../../Utility/file";
import UploadFile from "../UploadFile";
import DeletionMessageBox from "../DeletionMessageBox";
import NewApplication from "./NewApplication";
import { zipkinFetch } from "../../Utility/browser";

const useStyle = makeStyles((theme) => ({
  applicationContainer: {
    width: "100%",
  },
  applicationHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 10px",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
  },
  applicationHeadeTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  applicationHeadeAddButton: {
    marginLeft: 10,
    borderRadius: 50,
  },
  applicationContentContainer: {
    height: "83vh",
    // overflow: "overlay",
    padding: "0 4px",
  },
  applicationDetailHeaderContainer: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
  },
  applicationDetailHeaderItem: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    cursor: "pointer",
    width: 150,
    fontSize: 14,
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  applicationDetailHeaderItemSelected: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
}));

const Applications = () => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  // const { applicationId } = useParams();
  const [params, setParams] = useState({});

  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [applications, setapplications] = useState([]);
  const [
    selectedApplicationDetailContentType,
    setSelectedApplicationDetailContentType,
  ] = useState("Modules");
  const [entities, setentities] = useState([]);
  const [apis, setapis] = useState([]);
  const [activities, setactivities] = useState([]);
  const [payloads, setpayloads] = useState([]);

  const [applicationFile, setApplicationFile] = useState(null);
  const [entityFile, setEntityFile] = useState(null);
  const [apiFile, setApiFile] = useState(null);
  const [activityFile, setActivityFile] = useState(null);
  const [payloadFile, setPayloadFile] = useState(null);

  const [onLoad, setOnLoad] = useState(false);
  const initialItem = { name: "", label: "", description: "" };
  const [item, setItem] = useState(initialItem);
  const [updatingApplication, setUpdatingApplication] = useState(null);
  const [selectEntity, setSelectedEntity] = useState(null);
  const [selectApi, setSelectedApi] = useState(null);
  const [updatingApi, setUpdatingApi] = useState(null);
  const [updatingPayload, setUpdatingPayload] = useState(null);

  const [addNewApplicationDialog, setAddNewApplicationDialog] = useState(false);
  const [addNewEntityDialog, setAddNewEntityDialog] = useState(false);
  const [addNewApiDialog, setAddNewApiDialog] = useState(false);
  const [addNewActivityDialog, setAddNewActivityDialog] = useState(false);
  const [addNewPayloadDialog, setAddNewPayloadDialog] = useState(false);

  const [openApplicationFileDialog, setOpenApplicationFileDialog] =
    useState(false);
  const [openEntityFileDialog, setOpenEntityFileDialog] = useState(false);
  const [openApiFileDialog, setOpenApiFileDialog] = useState(false);
  const [openActivityFileDialog, setOpenActivityFileDialog] = useState(false);
  const [openPayloadFileDialog, setOpenPayloadFileDialog] = useState(false);

  const [deletingApplication, setDeletingApplication] = useState(null);
  const [deletingEntity, setDeletingEntity] = useState(null);
  const [deletingApi, setDeletingApi] = useState(null);
  const [deletingActivity, setDeletingActivity] = useState(null);
  const [deletingPayload, setDeletingPayload] = useState(null);

  const getApplications = () => {
    getAllApplications(
      userReducerState.token,
      () => {
        setOnLoad(true);
      },
      (data) => {
        dispatch(setApplications(data?.data || []));
        setOnLoad(false);
      },
      (err) => {
        setOnLoad(false);
      }
    );
  };
  const getEntities = () => {
    getAllEntities(
      userReducerState.token,
      () => {
        setOnLoad(true);
      },
      (data) => {
        dispatch(setEntities(data?.data || []));
        setOnLoad(false);
      },
      (err) => {
        setOnLoad(false);
      }
    );
  };
  const getApis = () => {
    getAllApis(
      userReducerState.token,
      () => {
        setOnLoad(true);
      },
      (data) => {
        dispatch(setApis(data?.data || []));
        setOnLoad(false);
      },
      (err) => {
        setOnLoad(false);
      }
    );
  };
  const getActivities = () => {
    getAllActivities(
      userReducerState.token,
      () => {
        setOnLoad(true);
      },
      (data) => {
        dispatch(setActivities(data?.data || []));
        setOnLoad(false);
      },
      (err) => {
        setOnLoad(false);
      }
    );
  };
  const getPayloads = () => {
    getAllPayloads(
      userReducerState.token,
      () => {
        setOnLoad(true);
      },
      (data) => {
        dispatch(setPayloads(data?.data || []));
        setOnLoad(false);
      },
      (err) => {
        setOnLoad(false);
      }
    );
  };

  // const handleCreateApplication = () => {
  //   if (authVerify(userReducerState.token)) {
  //     setOnLoad(true);
  //     const insertApplicationUrl = `${process.env.REACT_APP_WAServices}/api/v1/applications`;
  //     const insertApplicationPayload = {
  //       createdBy: userReducerState?.user?.preferred_username,
  //       createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  //       description: item?.description,
  //       isActive: 1,
  //       isDeleted: 0,
  //       label: item?.label,
  //       name: item?.name,
  //       status: "Active",
  //       updatedBy: userReducerState?.user?.preferred_username,
  //       updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  //     };
  //     const insertApplicationRequestParam = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userReducerState.token}`,
  //       },
  //       body: JSON.stringify(insertApplicationPayload),
  //     };
  //     zipkinFetch(insertApplicationUrl, insertApplicationRequestParam)
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setItem(initialItem);
  //         setOnLoad(false);
  //         getAllApplications(
  //           userReducerState.token,
  //           () => {
  //             setOnLoad(true);
  //           },
  //           (data) => {
  //             dispatch(setApplications(data?.data || []));
  //             setOnLoad(false);
  //           },
  //           (err) => {
  //             setOnLoad(false);
  //           }
  //         );
  //       })
  //       .catch((err) => {
  //         setOnLoad(false);
  //       });
  //   } else {
  //     window.location.reload(0);
  //   }
  // };
  const handleCreateEntity = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const insertEntityUrl = `${process.env.REACT_APP_WAServices}/api/v1/entities`;
      const insertEntityPayload = {
        applicationId: params?.applicationId,
        createdBy: userReducerState?.user?.preferred_username,
        createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        description: item?.description,
        isActive: 1,
        isDeleted: 0,
        label: item?.label,
        name: item?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.preferred_username,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const insertEntityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(insertEntityPayload),
      };
      zipkinFetch(insertEntityUrl, insertEntityRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          setItem(initialItem);
          getAllEntities(
            userReducerState.token,
            () => {
              setOnLoad(true);
            },
            (data) => {
              dispatch(setEntities(data?.data || []));
              setOnLoad(false);
            },
            (err) => {
              setOnLoad(false);
            }
          );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const handleCreateActivity = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const insertActivityUrl = `${process.env.REACT_APP_WAServices}/api/v1/activities`;
      const insertActivityPayload = {
        createdBy: userReducerState?.user?.preferred_username,
        createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        description: item?.description,
        entityId: selectEntity,
        isActive: 1,
        isDeleted: 0,
        label: item?.label,
        name: item?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.preferred_username,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const insertActivityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(insertActivityPayload),
      };
      zipkinFetch(insertActivityUrl, insertActivityRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(true);
          setItem(initialItem);
          getAllActivities(
            userReducerState.token,
            () => {
              setOnLoad(true);
            },
            (data) => {
              dispatch(setActivities(data?.data || []));
              setOnLoad(false);
            },
            (err) => {
              setOnLoad(false);
            }
          );
        })
        .catch((err) => {
          setOnLoad(true);
        });
    } else {
      window.location.reload(0);
    }
  };

  // const handleUpdateApplication = () => {
  //   if (authVerify(userReducerState.token)) {
  //     setOnLoad(true);
  //     const updateApplicationUrl = `${process.env.REACT_APP_WAServices}/api/v1/applications/modify`;
  //     const updateApplicationPayload = {
  //       id: item?.id,
  //       createdBy: item?.createdBy,
  //       createdOn: moment(new Date(item?.createdOn)).format(
  //         "YYYY-MM-DD HH:mm:ss"
  //       ),
  //       description: item?.description,
  //       isActive: 1,
  //       isDeleted: 0,
  //       label: item?.label,
  //       name: item?.name,
  //       status: "Active",
  //       updatedBy: userReducerState?.user?.preferred_username,
  //       updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  //     };
  //     const updateApplicationRequestParam = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userReducerState.token}`,
  //       },
  //       body: JSON.stringify(updateApplicationPayload),
  //     };
  //     zipkinFetch(updateApplicationUrl, updateApplicationRequestParam)
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setItem(initialItem);
  //         setOnLoad(false);
  //         dispatch(
  //           setApplications(
  //             basicReducerState?.applications?.map((application) =>
  //               Number(application?.id) === Number(item?.id)
  //                 ? {
  //                     ...application,
  //                     name: item?.name,
  //                     label: item.label,
  //                     description: item.description,
  //                   }
  //                 : { ...application }
  //             ) || []
  //           )
  //         );
  //         // getAllApplications(
  //         //   userReducerState.token,
  //         //   () => {
  //         //     setOnLoad(true);
  //         //   },
  //         //   (data) => {
  //         //     dispatch(setApplications(data?.data || []));
  //         //     setOnLoad(false);
  //         //   },
  //         //   (err) => {
  //         //     setOnLoad(false);
  //         //   }
  //         // );
  //       })
  //       .catch((err) => {
  //         setOnLoad(false);
  //       });
  //   } else {
  //     window.location.reload(0);
  //   }
  // };
  const handleUpdateEntity = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const updateEntityUrl = `${process.env.REACT_APP_WAServices}/api/v1/entities/modify`;
      const updateEntityPayload = {
        id: item?.id,
        applicationId: params?.applicationId,
        createdBy: item?.createdBy,
        createdOn: moment(new Date(item?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        description: item?.description,
        isActive: 1,
        isDeleted: 0,
        label: item?.label,
        name: item?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.preferred_username,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const updateEntityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(updateEntityPayload),
      };
      zipkinFetch(updateEntityUrl, updateEntityRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          setItem(initialItem);
          dispatch(
            setEntities(
              basicReducerState?.entities?.map((entity) =>
                Number(entity?.id) === Number(item?.id)
                  ? {
                      ...entity,
                      name: item?.name,
                      label: item.label,
                      description: item.description,
                    }
                  : { ...entity }
              ) || []
            )
          );
          // getAllEntities(
          //   userReducerState.token,
          //   () => {
          //     setOnLoad(true);
          //   },
          //   (data) => {
          //     dispatch(setEntities(data?.data || []));
          //     setOnLoad(false);
          //   },
          //   (err) => {
          //     setOnLoad(false);
          //   }
          // );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const handleUpdateActivity = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const updateActivityUrl = `${process.env.REACT_APP_WAServices}/api/v1/activities/modify`;
      const updateActivityPayload = {
        id: item?.id,
        createdBy: item?.createdBy,
        createdOn: moment(new Date(item?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        description: item?.description,
        entityId: selectEntity,
        isActive: 1,
        isDeleted: 0,
        label: item?.label,
        name: item?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.preferred_username,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const updateActivityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(updateActivityPayload),
      };
      zipkinFetch(updateActivityUrl, updateActivityRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          setItem(initialItem);
          dispatch(
            setActivities(
              basicReducerState?.activities?.map((activity) =>
                Number(activity?.id) === Number(item?.id)
                  ? {
                      ...activity,
                      name: item?.name,
                      label: item.label,
                      description: item.description,
                    }
                  : { ...activity }
              ) || []
            )
          );
          // getAllActivities(
          //   userReducerState.token,
          //   () => {
          //     setOnLoad(true);
          //   },
          //   (data) => {
          //     dispatch(setActivities(data?.data || []));
          //     setOnLoad(false);
          //   },
          //   (err) => {
          //     setOnLoad(false);
          //   }
          // );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };

  const handleDeleteApplication = (applicationId) => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const disableApplicationUrl = `${process.env.REACT_APP_WAServices}/api/v1/applications/deactivate?id=${applicationId}`;
      const disableApplicationRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      zipkinFetch(disableApplicationUrl, disableApplicationRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          dispatch(
            setApplications(
              applications?.filter(
                (application) => application?.id !== Number(applicationId)
              ) || []
            )
          );
          setDeletingApplication(null);
          // history.push(`/applications`);
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const handleDeleteEntity = (entityId) => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const disableEntityUrl = `${process.env.REACT_APP_WAServices}/api/v1/entities/deactivate?id=${entityId}`;
      const disableEntityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      zipkinFetch(disableEntityUrl, disableEntityRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          dispatch(
            setEntities(
              entities?.filter((entity) => entity?.id !== entityId) || []
            )
          );
          setDeletingEntity(null);
          setSelectedEntity(null);
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const handleDeleteApi = (apiId) => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const disableApiUrl = `${process.env.REACT_APP_WAServices}/api/v1/apiPayload/deactivate?id=${apiId}`;
      const disableApiRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      zipkinFetch(disableApiUrl, disableApiRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          dispatch(setApis(apis?.filter((api) => api?.id !== apiId) || []));
          setDeletingApi(null);
          setSelectedApi(null);
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const handleDeleteActivity = (activityId) => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const disableActivityUrl = `${process.env.REACT_APP_WAServices}/api/v1/activities/deactivate?id=${activityId}`;
      const disableActivityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      zipkinFetch(disableActivityUrl, disableActivityRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          dispatch(
            setActivities(
              activities?.filter((activity) => activity?.id !== activityId) ||
                []
            )
          );
          setDeletingActivity(null);
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const handleDeletePayload = (payloadId) => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const disablePayloadUrl = `${process.env.REACT_APP_WAServices}/api/v1/apiPayload/payload/deactivate?id=${payloadId}`;
      const disablePayloadRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      zipkinFetch(disablePayloadUrl, disablePayloadRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          dispatch(
            setPayloads(
              payloads?.filter((payload) => payload?.id !== payloadId) || []
            )
          );
          setDeletingPayload(null);
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };

  const uploadApplicationsFile = () => {
    if (authVerify(userReducerState.token)) {
      if (!applicationFile) {
        console.log("no file found");
        return;
      }
      setOnLoad(true);
      const url = `${process.env.REACT_APP_WAServices}/api/v1/applications/addApplicationsUsingCsv`;
      let formData = new FormData();
      formData.append("file", applicationFile);
      formData.append("name", applicationFile.name);
      const requestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: formData,
      };
      zipkinFetch(url, requestParam)
        .then((res) => {
          console.log(res);
        })
        .then((data) => {
          setOnLoad(false);
          setOpenApplicationFileDialog(false);
          setApplicationFile(null);
          getApplications();
        })
        .catch((err) => {
          console.log(err);
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const uploadEntitiesFile = () => {
    if (authVerify(userReducerState.token)) {
      if (!entityFile) {
        console.log("no file found");
        return;
      }
      setOnLoad(true);
      const url = `${process.env.REACT_APP_WAServices}/api/v1/entities/addEntitiesUsingCsv?applicationId=${params?.applicationId}`;
      let formData = new FormData();
      formData.append("file", entityFile);
      formData.append("name", entityFile.name);
      const requestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: formData,
      };
      zipkinFetch(url, requestParam)
        .then((res) => {
          console.log(res);
        })
        .then((data) => {
          setOnLoad(false);
          setOpenEntityFileDialog(false);
          setEntityFile(null);
          getEntities();
        })
        .catch((err) => {
          console.log(err);
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const uploadActivitiesFile = () => {
    if (authVerify(userReducerState.token)) {
      if (!activityFile) {
        console.log("no file found");
        return;
      }
      setOnLoad(true);
      const url = `${process.env.REACT_APP_WAServices}/api/v1/activities/addActivitiesUsingCsv`;
      let formData = new FormData();
      formData.append("file", activityFile);
      formData.append("file_name", activityFile.name);
      formData.append(
        "name",
        JSON.stringify(
          basicReducerState?.entities
            ?.filter(
              (entity) =>
                Number(entity?.applicationId) === Number(params?.applicationId)
            )
            ?.map((entity) => ({
              id: entity?.id,
              name: entity?.name,
            })) || []
        )
      );
      const requestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: formData,
      };
      // console.log(formData);
      zipkinFetch(url, requestParam)
        .then((res) => {
          console.log(res);
        })
        .then((data) => {
          setOnLoad(false);
          setOpenActivityFileDialog(false);
          setActivityFile(null);
          getActivities();
        })
        .catch((err) => {
          console.log(err);
          setOnLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };

  useEffect(() => {
    setapplications(
      basicReducerState.applications?.filter(
        (application) => application?.isActive === 1
      )
    );
    setentities(
      basicReducerState.entities?.filter((entity) => entity?.isActive === 1)
    );
    setapis(basicReducerState.apis?.filter((api) => api?.isActive === 1));
    setactivities(
      basicReducerState.activities?.filter(
        (activity) => activity?.isActive === 1
      )
    );
    setpayloads(
      basicReducerState?.payloads?.filter((payload) => payload?.isActive === 1)
    );
  }, [
    basicReducerState.applications,
    basicReducerState.entities,
    basicReducerState.apis,
    basicReducerState.activities,
    basicReducerState?.payloads,
  ]);

  return (
    <div className={classes.applicationContainer}>
      <Loading load={onLoad} />

      <Grid container spacing={2}>
        <Grid item xs={params?.applicationId ? 4 : 12}>
          <div className={classes.applicationHeaderContainer}>
            <Typography className={classes.applicationHeadeTitle}>
              Applications
            </Typography>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Download template">
                <IconButton
                  size="small"
                  // color="primary"
                  style={{ marginLeft: 4 }}
                  disabled={onLoad}
                  onClick={(e) => {
                    downloadFile({
                      data: applicationFileHeading,
                      fileName: "cw_applications.csv",
                      fileType: "text/csv",
                    });
                  }}
                >
                  <GetApp style={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Upload file">
                <IconButton
                  size="small"
                  // color="primary"
                  style={{ marginLeft: 4 }}
                  disabled={onLoad}
                  onClick={() => {
                    setOpenApplicationFileDialog(true);
                  }}
                >
                  <Publish style={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Refresh">
                <IconButton
                  size="small"
                  disabled={onLoad}
                  onClick={getApplications}
                >
                  <Refresh style={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>

              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.applicationHeadeAddButton}
                onClick={() => setAddNewApplicationDialog(true)}
                startIcon={<Add />}
                disabled={onLoad}
              >
                Add
              </Button>
            </div>
          </div>

          {applications?.length === 0 ? (
            onLoad ? null : (
              <NotFound />
            )
          ) : (
            <div className={`${classes.applicationContentContainer} waScroll`}>
              {applications?.map((application, index) => (
                <CustomCard
                  key={`${application?.id}-${index}`}
                  item={application}
                  onDelete={(e) => {
                    e.stopPropagation();
                    // handleDeleteApplication(application?.id);
                    setDeletingApplication(application?.id);
                  }}
                  onClick={() => {
                    // history.push(`/applications/${application?.id}`);
                    setParams({ applicationId: application?.id });
                    setSelectedEntity(null);
                  }}
                  selected={
                    params?.applicationId &&
                    application?.id === Number(params?.applicationId)
                  }
                  load={onLoad}
                  onUpdate={(e) => {
                    e.stopPropagation();
                    setIsUpdateItem(true);
                    setAddNewApplicationDialog(true);
                    // setItem(application);
                    setUpdatingApplication(application);
                  }}
                />
              ))}
            </div>
          )}

          <NewApplication
            open={addNewApplicationDialog}
            onClose={() => {
              setAddNewApplicationDialog(false);
              setIsUpdateItem(false);
            }}
            title={`${isUpdateItem ? "Update" : "New"} Application`}
            update={isUpdateItem}
            updatingApplication={updatingApplication}
          />

          {/* <NewItem
            open={addNewApplicationDialog}
            onClose={() => {
              setAddNewApplicationDialog(false);
              setIsUpdateItem(false);
              setItem(initialItem);
            }}
            title={`${isUpdateItem ? "Update" : "New"} Application`}
            label={true}
            newItem={item}
            setNewItem={setItem}
            onCreate={
              isUpdateItem ? handleUpdateApplication : handleCreateApplication
            }
            error={
              isUpdateItem
                ? basicReducerState?.applications?.find(
                    (application) =>
                      application?.name === item?.name &&
                      Number(application?.id) !== Number(item?.id)
                  )
                : basicReducerState?.applications?.find(
                    (application) => application?.name === item?.name
                  )
            }
            errorMessage="Application name already exists"
          /> */}

          <UploadFile
            open={openApplicationFileDialog}
            onClose={() => {
              setOpenApplicationFileDialog(false);
              setApplicationFile(null);
            }}
            onUpload={() => {
              uploadApplicationsFile();
            }}
            file={applicationFile}
            setFile={setApplicationFile}
            disableCondition={!applicationFile}
            load={onLoad}
          />

          <DeletionMessageBox
            open={deletingApplication ? true : false}
            onClose={() => setDeletingApplication(null)}
            onDelete={() => {
              handleDeleteApplication(deletingApplication);
            }}
            load={onLoad}
          />
        </Grid>

        {params?.applicationId && (
          <Grid item xs={8}>
            <div className={classes.applicationDetailHeaderContainer}>
              <Typography
                className={`${classes.applicationDetailHeaderItem} ${
                  selectedApplicationDetailContentType === "Modules" &&
                  classes.applicationDetailHeaderItemSelected
                }`}
                onClick={() => {
                  setSelectedApplicationDetailContentType("Modules");
                  setSelectedEntity(null);
                  setSelectedApi(null);
                }}
              >
                Modules
              </Typography>

              <Typography
                className={`${classes.applicationDetailHeaderItem} ${
                  selectedApplicationDetailContentType === "Apis" &&
                  classes.applicationDetailHeaderItemSelected
                }`}
                onClick={() => {
                  setSelectedApplicationDetailContentType("Apis");
                  setSelectedEntity(null);
                  setSelectedApi(null);
                }}
              >
                Apis
              </Typography>
            </div>

            {selectedApplicationDetailContentType === "Modules" && (
              <div style={{ display: "flex" }}>
                <Grid item xs={selectEntity ? 6 : 12}>
                  <div className={classes.applicationHeaderContainer}>
                    <Typography className={classes.applicationHeadeTitle}>
                      Modules
                    </Typography>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Tooltip title="Download template">
                        <IconButton
                          size="small"
                          // color="primary"
                          style={{ marginLeft: 4 }}
                          disabled={onLoad}
                          onClick={(e) => {
                            downloadFile({
                              data: entityFileHeading,
                              fileName: "cw_modules.csv",
                              fileType: "text/csv",
                            });
                          }}
                        >
                          <GetApp style={{ fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Upload file">
                        <IconButton
                          size="small"
                          // color="primary"
                          style={{ marginLeft: 4 }}
                          disabled={onLoad}
                          onClick={() => {
                            setOpenEntityFileDialog(true);
                          }}
                        >
                          <Publish style={{ fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Refresh">
                        <IconButton
                          size="small"
                          disabled={onLoad}
                          onClick={getEntities}
                        >
                          <Refresh style={{ fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>

                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.applicationHeadeAddButton}
                        onClick={() => setAddNewEntityDialog(true)}
                        startIcon={<Add />}
                        disabled={onLoad}
                      >
                        Add
                      </Button>
                    </div>
                  </div>

                  {entities?.length === 0 ? (
                    onLoad ? null : (
                      <NotFound />
                    )
                  ) : (
                    <div
                      className={`${classes.applicationContentContainer} waScroll`}
                      style={{ height: "77vh" }}
                    >
                      {entities?.map(
                        (entity, index) =>
                          Number(params?.applicationId) ===
                            entity?.applicationId && (
                            <CustomCard
                              key={`${entity?.id}-${index}`}
                              item={entity}
                              onDelete={(e) => {
                                e.stopPropagation();
                                // handleDeleteEntity(entity?.id);
                                setDeletingEntity(entity?.id);
                              }}
                              onClick={() => {
                                setSelectedEntity(entity?.id);
                                setSelectedApi(null);
                              }}
                              selected={
                                params?.applicationId &&
                                selectEntity &&
                                entity?.id === Number(selectEntity)
                              }
                              load={onLoad}
                              onUpdate={(e) => {
                                e.stopPropagation();
                                setIsUpdateItem(true);
                                setAddNewEntityDialog(true);
                                setItem(entity);
                              }}
                            />
                          )
                      )}
                    </div>
                  )}

                  <NewItem
                    open={addNewEntityDialog}
                    onClose={() => {
                      setAddNewEntityDialog(false);
                      setItem(initialItem);
                    }}
                    title={`${isUpdateItem ? "Update" : "New"} Modules`}
                    label={true}
                    newItem={item}
                    setNewItem={setItem}
                    onCreate={
                      isUpdateItem ? handleUpdateEntity : handleCreateEntity
                    }
                    error={
                      isUpdateItem
                        ? basicReducerState?.entities?.find(
                            (entity) =>
                              entity?.name?.toLowerCase() ===
                                item?.name?.toLowerCase() &&
                              Number(entity?.applicationId) ===
                                Number(params?.applicationId) &&
                              Number(entity?.id) !== Number(item?.id)
                          )
                        : basicReducerState?.entities?.find(
                            (entity) =>
                              entity?.name?.toLowerCase() ===
                                item?.name?.toLowerCase() &&
                              Number(entity?.applicationId) ===
                                Number(params?.applicationId)
                          )
                    }
                    errorMessage="Module name already exists"
                  />

                  <UploadFile
                    open={openEntityFileDialog}
                    onClose={() => {
                      setOpenEntityFileDialog(false);
                      setEntityFile(null);
                    }}
                    onUpload={() => {
                      uploadEntitiesFile();
                    }}
                    file={entityFile}
                    setFile={setEntityFile}
                    disableCondition={!entityFile}
                    load={onLoad}
                  />

                  <DeletionMessageBox
                    open={deletingEntity ? true : false}
                    onClose={() => setDeletingEntity(null)}
                    onDelete={() => {
                      handleDeleteEntity(deletingEntity);
                    }}
                    load={onLoad}
                  />
                </Grid>

                {selectEntity && (
                  <Grid item xs={6}>
                    <div className={classes.applicationHeaderContainer}>
                      <Typography className={classes.applicationHeadeTitle}>
                        Features
                      </Typography>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title="Download template">
                          <IconButton
                            size="small"
                            // color="primary"
                            style={{ marginLeft: 4 }}
                            disabled={onLoad}
                            onClick={(e) => {
                              downloadFile({
                                data: activityFileHeading,
                                fileName: "cw_features.csv",
                                fileType: "text/csv",
                              });
                            }}
                          >
                            <GetApp style={{ fontSize: 20 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Upload file">
                          <IconButton
                            size="small"
                            // color="primary"
                            style={{ marginLeft: 4 }}
                            disabled={onLoad}
                            onClick={() => {
                              setOpenActivityFileDialog(true);
                            }}
                          >
                            <Publish style={{ fontSize: 20 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Refresh">
                          <IconButton
                            size="small"
                            disabled={onLoad}
                            onClick={getActivities}
                          >
                            <Refresh style={{ fontSize: 20 }} />
                          </IconButton>
                        </Tooltip>

                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          className={classes.applicationHeadeAddButton}
                          onClick={() => setAddNewActivityDialog(true)}
                          startIcon={<Add />}
                          disabled={onLoad}
                        >
                          Add
                        </Button>
                      </div>
                    </div>

                    {activities?.length === 0 ? (
                      onLoad ? null : (
                        <NotFound />
                      )
                    ) : (
                      <div
                        className={`${classes.applicationContentContainer} waScroll`}
                        style={{ height: "77vh" }}
                      >
                        {activities?.map(
                          (activity, index) =>
                            selectEntity === activity?.entityId && (
                              <CustomCard
                                key={`${activity?.id}-${index}`}
                                item={activity}
                                onDelete={(e) => {
                                  e.stopPropagation();
                                  // handleDeleteActivity(activity?.id);
                                  setDeletingActivity(activity?.id);
                                }}
                                onClick={() => {}}
                                load={onLoad}
                                onUpdate={(e) => {
                                  e.stopPropagation();
                                  setIsUpdateItem(true);
                                  setAddNewActivityDialog(true);
                                  setItem(activity);
                                }}
                              />
                            )
                        )}
                      </div>
                    )}

                    <NewItem
                      open={addNewActivityDialog}
                      onClose={() => {
                        setAddNewActivityDialog(false);
                        setItem(initialItem);
                      }}
                      title={`${isUpdateItem ? "Update" : "New"} Feature`}
                      newItem={item}
                      label={true}
                      setNewItem={setItem}
                      onCreate={
                        isUpdateItem
                          ? handleUpdateActivity
                          : handleCreateActivity
                      }
                      error={
                        isUpdateItem
                          ? basicReducerState?.activities?.find(
                              (activity) =>
                                activity?.name?.toLowerCase() ===
                                  item?.name?.toLowerCase() &&
                                Number(activity?.entityId) ===
                                  Number(selectEntity) &&
                                Number(activity?.id) !== Number(item?.id)
                            )
                          : basicReducerState?.activities?.find(
                              (activity) =>
                                activity?.name?.toLowerCase() ===
                                  item?.name?.toLowerCase() &&
                                Number(activity?.entityId) ===
                                  Number(selectEntity)
                            )
                      }
                      errorMessage="Feature name already exists"
                    />

                    <UploadFile
                      open={openActivityFileDialog}
                      onClose={() => {
                        setOpenActivityFileDialog(false);
                        setActivityFile(null);
                      }}
                      onUpload={() => {
                        uploadActivitiesFile();
                      }}
                      file={activityFile}
                      setFile={setActivityFile}
                      disableCondition={!activityFile}
                      load={onLoad}
                    />

                    <DeletionMessageBox
                      open={deletingActivity ? true : false}
                      onClose={() => setDeletingActivity(null)}
                      onDelete={() => {
                        handleDeleteActivity(deletingActivity);
                      }}
                      load={onLoad}
                    />
                  </Grid>
                )}
              </div>
            )}

            {selectedApplicationDetailContentType === "Apis" && (
              <div style={{ display: "flex" }}>
                <Grid item xs={selectApi ? 6 : 12}>
                  <div className={classes.applicationHeaderContainer}>
                    <Typography className={classes.applicationHeadeTitle}>
                      Apis
                    </Typography>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* <Tooltip title="Download template">
                        <IconButton
                          size="small"
                          // color="primary"
                          style={{ marginLeft: 4 }}
                          disabled={onLoad}
                          onClick={(e) => {
                            downloadFile({
                              data: apiFileHeading,
                              fileName: "cw_apis.csv",
                              fileType: "text/csv",
                            });
                          }}
                        >
                          <GetApp style={{ fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Upload file">
                        <IconButton
                          size="small"
                          // color="primary"
                          style={{ marginLeft: 4 }}
                          disabled={onLoad}
                          onClick={() => {
                            setOpenApiFileDialog(true);
                          }}
                        >
                          <Publish style={{ fontSize: 20 }} />
                        </IconButton>
                      </Tooltip> */}

                      <Tooltip title="Refresh">
                        <IconButton
                          size="small"
                          disabled={onLoad}
                          onClick={getApis}
                        >
                          <Refresh style={{ fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>

                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.applicationHeadeAddButton}
                        onClick={() => setAddNewApiDialog(true)}
                        startIcon={<Add />}
                        disabled={onLoad}
                      >
                        Add
                      </Button>
                    </div>
                  </div>

                  {apis?.length === 0 ? (
                    onLoad ? null : (
                      <NotFound />
                    )
                  ) : (
                    <div
                      className={`${classes.applicationContentContainer} waScroll`}
                      style={{ height: "77vh" }}
                    >
                      {apis?.map(
                        (api, index) =>
                          Number(params?.applicationId) ===
                            api?.applicationId && (
                            <CustomCard
                              key={`${api?.id}-${index}`}
                              item={api}
                              onDelete={(e) => {
                                e.stopPropagation();
                                // handleDeleteApi(api?.id);
                                setDeletingApi(api?.id);
                              }}
                              onClick={() => {
                                setSelectedEntity(null);
                                setSelectedApi(api?.id);
                              }}
                              selected={
                                params?.applicationId &&
                                selectApi &&
                                api?.id === Number(selectApi)
                              }
                              load={onLoad}
                              onUpdate={(e) => {
                                e.stopPropagation();
                                setIsUpdateItem(true);
                                setUpdatingApi(api);
                                setAddNewApiDialog(true);
                              }}
                            />
                          )
                      )}
                    </div>
                  )}

                  <NewApi
                    open={addNewApiDialog}
                    onClose={() => {
                      setIsUpdateItem(false);
                      setAddNewApiDialog(false);
                      setUpdatingApi(null);
                    }}
                    title={`${isUpdateItem ? "Update" : "New"} Api`}
                    update={isUpdateItem}
                    updatingApi={updatingApi}
                  />

                  <UploadFile
                    open={openApiFileDialog}
                    onClose={() => {
                      setOpenApiFileDialog(false);
                      setApiFile(null);
                    }}
                    onUpload={() => {}}
                    file={apiFile}
                    setFile={setApiFile}
                  />

                  <DeletionMessageBox
                    open={deletingApi ? true : false}
                    onClose={() => setDeletingApi(null)}
                    onDelete={() => {
                      handleDeleteApi(deletingApi);
                    }}
                    load={onLoad}
                  />
                </Grid>

                {selectApi && (
                  <Grid item xs={6}>
                    <div className={classes.applicationHeaderContainer}>
                      <Typography className={classes.applicationHeadeTitle}>
                        Payloads
                      </Typography>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        {/* <Tooltip title="Download template">
                          <IconButton
                            size="small"
                            // color="primary"
                            style={{ marginLeft: 4 }}
                            disabled={onLoad}
                            onClick={(e) => {
                              downloadFile({
                                data: payloadFileHeading,
                                fileName: "cw_payloads.csv",
                                fileType: "text/csv",
                              });
                            }}
                          >
                            <GetApp style={{ fontSize: 20 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Upload file">
                          <IconButton
                            size="small"
                            // color="primary"
                            style={{ marginLeft: 4 }}
                            disabled={onLoad}
                            onClick={() => {
                              setOpenPayloadFileDialog(true);
                            }}
                          >
                            <Publish style={{ fontSize: 20 }} />
                          </IconButton>
                        </Tooltip> */}

                        <Tooltip title="Refresh">
                          <IconButton
                            size="small"
                            disabled={onLoad}
                            onClick={getPayloads}
                          >
                            <Refresh style={{ fontSize: 20 }} />
                          </IconButton>
                        </Tooltip>

                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          className={classes.applicationHeadeAddButton}
                          onClick={() => setAddNewPayloadDialog(true)}
                          startIcon={<Add />}
                          disabled={onLoad}
                        >
                          Add
                        </Button>
                      </div>
                    </div>

                    {payloads?.length === 0 ? (
                      onLoad ? null : (
                        <NotFound />
                      )
                    ) : (
                      <div
                        className={`${classes.applicationContentContainer} waScroll`}
                        style={{ height: "77vh" }}
                      >
                        {payloads?.map(
                          (payload, index) =>
                            selectApi === payload?.apiId && (
                              <CustomCard
                                key={`${payload?.id}-${index}`}
                                item={payload}
                                onDelete={(e) => {
                                  e.stopPropagation();
                                  // handleDeletePayload(payload?.id);
                                  setDeletingPayload(payload?.id);
                                }}
                                onClick={() => {}}
                                load={onLoad}
                                onUpdate={(e) => {
                                  e.stopPropagation();
                                  setIsUpdateItem(true);
                                  setUpdatingPayload(payload);
                                  setAddNewPayloadDialog(true);
                                }}
                              />
                            )
                        )}
                      </div>
                    )}

                    <NewPayload
                      open={addNewPayloadDialog}
                      onClose={() => {
                        setAddNewPayloadDialog(false);
                        setUpdatingPayload(null);
                        setIsUpdateItem(false);
                      }}
                      title={`${isUpdateItem ? "Update" : "New"} Payload`}
                      selectApi={selectApi}
                      update={isUpdateItem}
                      updatingPayload={updatingPayload}
                    />

                    <UploadFile
                      open={openPayloadFileDialog}
                      onClose={() => {
                        setOpenPayloadFileDialog(false);
                        setPayloadFile(null);
                      }}
                      onUpload={() => {}}
                      file={payloadFile}
                      setFile={setPayloadFile}
                    />

                    <DeletionMessageBox
                      open={deletingPayload ? true : false}
                      onClose={() => setDeletingPayload(null)}
                      onDelete={() => {
                        handleDeletePayload(deletingPayload);
                      }}
                      load={onLoad}
                    />
                  </Grid>
                )}
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Applications;
