import React from "react";
import { IconButton, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Delete, Edit } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  customCardContainer: {
    padding: 6,
    height: 100,
    margin: "4px 0",
    cursor: "pointer",
    boxShadow:
      "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  customCardContainerSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  customCardName: {
    fontSize: 14,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  customCardLabel: {
    fontSize: 12,
    fontWeight: "normal",
    color: theme.palette.text.secondary,
  },
  customCardDescription: {
    fontSize: 12,
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  customCardActionContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: 15,
    right: 20,
  },
}));

function CustomCard({ item, onDelete, onClick, selected, load, onUpdate }) {
  const classes = useStyle();

  return (
    <Paper
      className={`${classes.customCardContainer} ${
        selected && classes.customCardContainerSelected
      }`}
      elevation={0}
      onClick={onClick}
    >
      <Typography className={classes.customCardName}>
        {item?.name}
        <span className={classes.customCardLabel}>{` ${
          item?.label ? "(" + item?.label + ")" : "(" + item?.type + ")"
        } `}</span>
      </Typography>

      <Typography className={classes.customCardDescription}>
        {item?.description}
      </Typography>

      <div className={classes.customCardActionContainer}>
        <IconButton
          size="small"
          color="primary"
          onClick={onUpdate}
          disabled={load}
        >
          <Edit style={{ fontSize: 18 }} />
        </IconButton>

        <IconButton
          size="small"
          color="secondary"
          onClick={onDelete}
          disabled={load}
        >
          <Delete style={{ fontSize: 18 }} />
        </IconButton>
      </div>
    </Paper>
  );
}

export default CustomCard;
