export const userFileHeading = "User Id,First Name,Last Name,Email Id";
export const roleFileHeading = "Name,Label,Description";
export const applicationFileHeading = "Name,Label,Description";
export const entityFileHeading = "Name,Label,Description";
export const apiFileHeading = "Name,Type,Description";
export const activityFileHeading = "Name,Label,Description,Module Name";
export const payloadFileHeading = "Name,Type,Description";
export const groupFileHeading = "Name";

export const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};
