import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Add,
  Delete,
  Search,
  Visibility,
  Remove,
  Close,
  Refresh,
  GetApp,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { getAllRealms, getKeycloakAdminToken } from "../../Actions/action";
import { authVerify } from "../../Utility/auth";
import { setRealms } from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import { downloadFile } from "../../Utility/file";
import DeletionMessageBox from "../DeletionMessageBox";
import { zipkinFetch } from "../../Utility/browser";

const useStyle = makeStyles((theme) => ({
  realmInfoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
  },
  realmInfoItemContainer: {
    margin: 4,
    alignItems: "center",
  },
  realmInfoContainerLabel: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  realmInfoContainerText: {
    fontSize: 12,
  },

  newIdpDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newIdpDialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  newIdpDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  realmIdpTableContainer: {
    height: "55vh",
    // overflow: "overlay",
    width: "100%",
  },
  realmIdpTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  realmIdpTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  realmIdpTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  realmIdpTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  realmIdpTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  realmIdpTableBodyTextHide: {
    maxWidth: 250,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  realmIdpBottomAddButton: {
    margin: "4px 10px",
    borderRadius: 50,
  },

  newClientDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newClientDialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  newClientDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  realmClientTableContainer: {
    height: "55vh",
    // overflow: "overlay",
    width: "100%",
  },
  realmClientTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  realmClientTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  realmClientTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  realmClientTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  realmClientTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  realmClientTableBodyTextHide: {
    maxWidth: 250,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  realmClientBottomAddButton: {
    margin: "4px 10px",
    borderRadius: 50,
  },

  newRelamDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newRealmDialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  newRealmDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  realmsContainer: {
    padding: 10,
    width: "100%",
  },
  realmHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
  },
  realmHeadeTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  realmsHeaderAddButton: {
    marginLeft: 10,
    borderRadius: 50,
  },
  realmsListContainer: {
    height: "81vh",
    // overflow: "overlay",
  },
  realmContent: {
    margin: "4px 0",
    padding: 6,
    display: "flex",
    align: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  realmContentSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  realmTitle: {
    fontSize: 12,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  realmDetailContainer: {
    flexDirection: "column",
    height: "81vh",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: theme.palette.background.paper,
    margin: 0,
    padding: 0,
    // overflow: "overlay",
    position: "relative",
  },
  realmDetailCloseIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 10,
    paddingTop: 10,
  },
  realmDetailHeaderContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
  },
  realmDetailHeaderItem: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    cursor: "pointer",
    width: 150,
    fontSize: 14,
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // textAlign: "center",
  },
  realmDetailHeaderItemSelected: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  realmDetailFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxShadow: "0px 0px 9px #D8D8D8",
    padding: "8px 16px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 999,
  },
  realmDetailFooterButton: {
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const RealmInfo = ({ realmDetail, setRealmDetail, load, setLoad }) => {
  const classes = useStyle();

  return (
    <div className={classes.realmInfoContainer}>
      <Grid container className={classes.realmInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.realmInfoContainerLabel}>
            Name
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            className={classes.realmInfoContainerText}
            disabled={load}
          >
            {realmDetail?.realm}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.realmInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.realmInfoContainerLabel}>
            Enabled
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Checkbox
            size="small"
            color="primary"
            checked={realmDetail?.enabled}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container className={classes.realmInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.realmInfoContainerLabel}>
            Allowed Registration
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Checkbox
            size="small"
            color="primary"
            checked={realmDetail?.registrationAllowed}
            onClick={(e) => {
              setRealmDetail({
                ...realmDetail,
                registrationAllowed: e.target.checked,
              });
            }}
            disabled={load}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.realmInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.realmInfoContainerLabel}>
            Remember me
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Checkbox
            size="small"
            color="primary"
            checked={realmDetail?.rememberMe}
            onClick={(e) => {
              setRealmDetail({
                ...realmDetail,
                rememberMe: e.target.checked,
              });
            }}
            disabled={load}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.realmInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.realmInfoContainerLabel}>
            Login with Email
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Checkbox
            size="small"
            color="primary"
            checked={realmDetail?.loginWithEmailAllowed}
            onClick={(e) => {
              setRealmDetail({
                ...realmDetail,
                loginWithEmailAllowed: e.target.checked,
              });
            }}
            disabled={load}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const NewUser = ({ open, onClose, onSave, user, setUser }) => {
  const classes = useStyle();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.newIdpDialogTitle}>New User</DialogTitle>

      <DialogContent className={classes.newIdpDialogContent}>
        <TextField
          variant="standard"
          required
          fullWidth
          label="First Name"
          value={user?.first_name}
          onChange={(e) => setUser({ ...user, first_name: e.target.value })}
        />

        <TextField
          variant="standard"
          required
          fullWidth
          label="Last Name"
          value={user?.last_name}
          onChange={(e) => setUser({ ...user, last_name: e.target.value })}
        />

        <TextField
          variant="standard"
          required
          fullWidth
          label="User Name"
          value={user?.user_name}
          onChange={(e) => setUser({ ...user, user_name: e.target.value })}
        />

        <TextField
          variant="standard"
          required
          fullWidth
          label="Email Id"
          type="email"
          value={user?.email_id}
          onChange={(e) => setUser({ ...user, email_id: e.target.value })}
        />

        <TextField
          variant="standard"
          required
          fullWidth
          label="Password"
          type="password"
          value={user?.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
        />
      </DialogContent>

      <DialogActions className={classes.newIdpDialogActions}>
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          style={{ borderRadius: 50 }}
        >
          Cancel
        </Button>

        <Button
          onClick={onSave}
          color="primary"
          variant="outlined"
          style={{ borderRadius: 50 }}
          disabled={
            user?.first_name.length === 0 ||
            user?.last_name.length === 0 ||
            user?.user_name.length === 0 ||
            user?.email_id.length === 0 ||
            user?.password.length === 0
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RealmUser = ({
  realmDetail,
  setRealmDetail,
  setAlert,
  load,
  setLoad,
}) => {
  const classes = useStyle();
  const [openNewUserDialog, setOpenNewUserDialog] = useState(false);
  const initialUser = {
    first_name: "",
    last_name: "",
    user_name: "",
    email_id: "",
    password: "",
    status: "Draft",
  };
  const [user, setUser] = useState(initialUser);

  const deleteUser = (alias) => {
    setRealmDetail({
      ...realmDetail,
      identityProviders:
        realmDetail?.identityProviders?.filter((idp) => idp?.alias !== alias) ||
        [],
    });
  };

  return (
    <>
      <NewUser
        open={openNewUserDialog}
        onClose={() => {
          setOpenNewUserDialog(false);
          setUser(initialUser);
        }}
        onSave={() => {
          setRealmDetail({
            ...realmDetail,
            users: [...realmDetail.users, user],
          });
          setOpenNewUserDialog(false);
          setUser(initialUser);
        }}
        user={user}
        setUser={setUser}
      />

      <TableContainer className={`${classes.realmIdpTableContainer} waScroll`}>
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.realmIdpTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Display Name
              </TableCell>

              <TableCell
                // align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Username
              </TableCell>

              <TableCell
                // align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Email-id
              </TableCell>

              <TableCell
                align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={`${classes.realmIdpTableBody} waScroll`}>
            {realmDetail?.users?.map((user, index) => {
              return (
                <TableRow
                  key={`${user?.id}-${index}`}
                  className={classes.realmIdpTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.realmIdpTableBodyCell}
                  >
                    {`${user?.firstName} ${user?.lastName}`}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.realmIdpTableBodyCell}
                  >
                    {user?.username}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.realmIdpTableBodyCell}
                  >
                    {user?.email}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={classes.realmIdpTableBodyCell}
                    disabled={load}
                  >
                    delete
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        size="small"
        variant="contained"
        color="primary"
        className={classes.realmIdpBottomAddButton}
        onClick={() => setOpenNewUserDialog(true)}
        startIcon={<Add />}
        disabled={load}
      >
        Add
      </Button>
    </>
  );
};

const NewIdp = ({
  open,
  onClose,
  onSave,
  identityProvider,
  setIdentityProvider,
  aliasList,
}) => {
  const classes = useStyle();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.newIdpDialogTitle}>
        New Identity Provider
      </DialogTitle>

      <DialogContent className={classes.newIdpDialogContent}>
        <FormControl variant="standard" size="small" required>
          <InputLabel>Provider Id</InputLabel>
          <Select
            size="small"
            label="Provider Id"
            value={identityProvider?.providerId}
            onChange={(e) =>
              setIdentityProvider({
                ...identityProvider,
                providerId: e.target.value,
              })
            }
          >
            <MenuItem value="keycloak-oidc" style={{ fontSize: 12 }}>
              Keycloak OpenID Connect
            </MenuItem>
            <MenuItem value="oidc" style={{ fontSize: 12 }}>
              OpenID Connect v1.0
            </MenuItem>
            {/* <MenuItem value="saml"
                  style={{ fontSize: 12 }}>SAML v2.0</MenuItem> */}
          </Select>
        </FormControl>

        <TextField
          variant="standard"
          label="Alias name"
          required
          value={identityProvider?.alias}
          onChange={(e) =>
            setIdentityProvider({ ...identityProvider, alias: e.target.value })
          }
          error={
            aliasList?.filter((alias) => alias === identityProvider?.alias)
              ?.length > 0
          }
        />

        <FormControlLabel
          label="Enable"
          control={
            <Checkbox
              color="primary"
              size="small"
              checked={identityProvider?.enabled}
              onChange={(e) =>
                setIdentityProvider({
                  ...identityProvider,
                  enabled: e.target.checked,
                })
              }
            />
          }
        />

        <FormControl variant="standard" size="small" required>
          <InputLabel>First Login Flow</InputLabel>
          <Select
            size="small"
            label="First Login Flow"
            style={{ fontSize: 12 }}
            value={identityProvider?.firstBrokerLoginFlowAlias}
            onChange={(e) =>
              setIdentityProvider({
                ...identityProvider,
                firstBrokerLoginFlowAlias: e.target.value,
              })
            }
          >
            {/* <MenuItem value="registration"
                  style={{ fontSize: 12 }}>Registration</MenuItem> */}
            <MenuItem value="first broker login" style={{ fontSize: 12 }}>
              First Broker Login
            </MenuItem>
            {/* <MenuItem value="http challenge"
                  style={{ fontSize: 12 }}>Http Challenge</MenuItem> */}
            {/* <MenuItem value="browser"
                  style={{ fontSize: 12 }}>Browser</MenuItem> */}
            {/* <MenuItem value="direct grant"
                  style={{ fontSize: 12 }}>Direct Grant</MenuItem> */}
            {/* <MenuItem value="reset credientials"
                  style={{ fontSize: 12 }}>Reset Credentials</MenuItem> */}
            {/* <MenuItem value="docker auth"
                  style={{ fontSize: 12 }}>Docker Auth</MenuItem> */}
          </Select>
        </FormControl>

        <TextField
          label="Authorization URL"
          variant="standard"
          required
          value={identityProvider?.config?.authorizationUrl}
          onChange={(e) =>
            setIdentityProvider({
              ...identityProvider,
              config: {
                ...identityProvider?.config,
                authorizationUrl: e.target.value,
              },
            })
          }
        />

        <TextField
          label="Token URL"
          variant="standard"
          required
          value={identityProvider?.config?.tokenUrl}
          onChange={(e) =>
            setIdentityProvider({
              ...identityProvider,
              config: {
                ...identityProvider?.config,
                tokenUrl: e.target.value,
              },
            })
          }
        />

        <FormControl variant="standard" size="small" required>
          <InputLabel>Client Authentication</InputLabel>
          <Select
            size="small"
            label="Client Authentication"
            style={{ fontSize: 12 }}
            value={identityProvider?.config?.clientAuthMethod}
            onChange={(e) =>
              setIdentityProvider({
                ...identityProvider,
                config: {
                  ...identityProvider?.config,
                  clientAuthMethod: e.target.value,
                },
              })
            }
          >
            <MenuItem value="client_secret_post" style={{ fontSize: 12 }}>
              Client secret sent as post
            </MenuItem>
            <MenuItem value="client_secret_basic" style={{ fontSize: 12 }}>
              Client secret sent as basic auth
            </MenuItem>
            <MenuItem value="client_secret_jwt" style={{ fontSize: 12 }}>
              Client secret as jwt
            </MenuItem>
            <MenuItem value="private_key_jwt" style={{ fontSize: 12 }}>
              JWT signed with private key
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Client ID"
          variant="standard"
          required
          value={identityProvider?.config?.clientId}
          onChange={(e) =>
            setIdentityProvider({
              ...identityProvider,
              config: {
                ...identityProvider?.config,
                clientId: e.target.value,
              },
            })
          }
        />

        <TextField
          label="Client Secret"
          variant="standard"
          type="password"
          required
          value={identityProvider?.config?.clientSecret}
          onChange={(e) =>
            setIdentityProvider({
              ...identityProvider,
              config: {
                ...identityProvider?.config,
                clientSecret: e.target.value,
              },
            })
          }
        />
      </DialogContent>

      <DialogActions className={classes.newIdpDialogActions}>
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          style={{ borderRadius: 50 }}
        >
          Cancel
        </Button>

        <Button
          onClick={onSave}
          color="primary"
          variant="outlined"
          style={{ borderRadius: 50 }}
          disabled={
            identityProvider?.alias?.length === 0 ||
            identityProvider?.providerId?.length === 0 ||
            identityProvider?.firstBrokerLoginFlowAlias?.length === 0 ||
            identityProvider?.config?.clientId?.length === 0 ||
            identityProvider?.config?.tokenUrl?.length === 0 ||
            identityProvider?.config?.authorizationUrl?.length === 0 ||
            identityProvider?.config?.clientAuthMethod?.length === 0 ||
            identityProvider?.config?.clientSecret?.length === 0 ||
            aliasList?.filter((alias) => alias === identityProvider?.alias)
              ?.length > 0
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RealmIdp = ({ realmDetail, setRealmDetail, setAlert, load, setLoad }) => {
  const classes = useStyle();
  const [openNewIdpDialog, setOpenNewIdpDialog] = useState(false);
  const initialIdentityProvider = {
    alias: "",
    providerId: "",
    enabled: false,
    authenticateByDefault: false,
    firstBrokerLoginFlowAlias: "",
    updateProfileFirstLoginMode: "on",
    storeToken: false,
    addReadTokenRoleOnCreate: false,
    trustEmail: false,
    linkOnly: false,
    status: "Draft",
    config: {
      useJwksUrl: true,
      syncMode: "IMPORT",
      authorizationUrl: "",
      tokenUrl: "",
      clientAuthMethod: "",
      clientId: "",
      clientSecret: "",
      defaultScope: "openid profile email",
    },
  };
  const [identityProvider, setIdentityProvider] = useState(
    initialIdentityProvider
  );

  const removeIdp = (alias) => {
    setRealmDetail({
      ...realmDetail,
      identityProviders:
        realmDetail?.identityProviders?.filter((idp) => idp?.alias !== alias) ||
        [],
    });
  };

  return (
    <>
      <NewIdp
        open={openNewIdpDialog}
        onClose={() => {
          setOpenNewIdpDialog(false);
          setIdentityProvider(initialIdentityProvider);
        }}
        onSave={() => {
          setRealmDetail({
            ...realmDetail,
            identityProviders: [
              ...realmDetail.identityProviders,
              identityProvider,
            ],
          });
          setOpenNewIdpDialog(false);
          setIdentityProvider(initialIdentityProvider);
        }}
        identityProvider={identityProvider}
        setIdentityProvider={setIdentityProvider}
        aliasList={realmDetail?.identityProviders?.map((idp) => idp?.alias)}
      />

      <TableContainer className={`${classes.realmIdpTableContainer} waScroll`}>
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.realmIdpTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Alias
              </TableCell>

              <TableCell
                // align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Provider Id
              </TableCell>

              <TableCell
                // align="center"
                className={classes.realmIdpTableHeadCell}
              >
                First Login Flow
              </TableCell>

              <TableCell
                // align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Authorization URL
              </TableCell>

              <TableCell
                // align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Token URL
              </TableCell>

              <TableCell
                // align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Client Authentication
              </TableCell>

              <TableCell
                // align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Client ID
              </TableCell>

              <TableCell
                align="center"
                className={classes.realmIdpTableHeadCell}
              >
                Redirect URL
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={`${classes.realmIdpTableBody} waScroll`}>
            {realmDetail?.identityProviders?.map((idp, index) => {
              return (
                <TableRow
                  key={`${idp?.alias}-${index}`}
                  className={classes.realmIdpTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.realmIdpTableBodyCell}
                  >
                    {idp?.alias}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.realmIdpTableBodyCell}
                  >
                    {idp?.providerId}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.realmIdpTableBodyCell}
                  >
                    {idp?.firstBrokerLoginFlowAlias}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={`${classes.realmIdpTableBodyCell} ${classes.realmIdpTableBodyTextHide}`}
                  >
                    {idp?.config?.authorizationUrl}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={`${classes.realmIdpTableBodyCell} ${classes.realmIdpTableBodyTextHide}`}
                  >
                    {idp?.config?.tokenUrl}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.realmIdpTableBodyCell}
                  >
                    {idp?.config?.clientAuthMethod}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.realmIdpTableBodyCell}
                  >
                    {idp?.config?.clientId}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={classes.realmIdpTableBodyCell}
                  >
                    {idp?.status !== "Draft" ? (
                      <IconButton
                        size="small"
                        onClick={() => {
                          setAlert({
                            open: true,
                            message: `${process.env.REACT_APP_KEYCLOAK_BASE_URL}/auth/realms/${realmDetail?.realm}/broker/${idp?.alias}/endpoint`,
                          });
                        }}
                        color="primary"
                        disabled={load}
                      >
                        <Visibility style={{ fontSize: 18 }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => removeIdp(idp?.alias)}
                        color="secondary"
                        disabled={load}
                      >
                        <Remove style={{ fontSize: 18 }} />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        size="small"
        variant="contained"
        color="primary"
        className={classes.realmIdpBottomAddButton}
        onClick={() => setOpenNewIdpDialog(true)}
        startIcon={<Add />}
        disabled={load}
      >
        Add
      </Button>
    </>
  );
};

const NewClient = ({
  open,
  onClose,
  onSave,
  client,
  setClient,
  clientList,
}) => {
  const classes = useStyle();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.newClientDialogTitle}>
        New Client
      </DialogTitle>

      <DialogContent className={classes.newClientDialogContent}>
        <TextField
          label="Client name"
          variant="standard"
          required
          value={client?.name}
          onChange={(e) => setClient({ ...client, name: e.target.value })}
          error={clientList?.filter((c) => c === client?.name)?.length > 0}
        />

        <FormControl variant="standard" size="small" required>
          <InputLabel>Client Protocol</InputLabel>
          <Select
            size="small"
            label="Client Protocol"
            style={{ fontSize: 12 }}
            value={client?.protocol}
            onChange={(e) => setClient({ ...client, protocol: e.target.value })}
          >
            <MenuItem value="openid-connect" style={{ fontSize: 12 }}>
              openid-connect
            </MenuItem>
            <MenuItem value="saml" style={{ fontSize: 12 }}>
              saml
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Root URL"
          variant="standard"
          required
          value={client?.rootUrl}
          onChange={(e) => setClient({ ...client, rootUrl: e.target.value })}
        />
      </DialogContent>

      <DialogActions className={classes.newClientDialogActions}>
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          style={{ borderRadius: 50 }}
        >
          Cancel
        </Button>

        <Button
          onClick={onSave}
          color="primary"
          variant="outlined"
          style={{ borderRadius: 50 }}
          disabled={
            client?.name?.length === 0 ||
            client?.protocol?.length === 0 ||
            client?.rootUrl?.length === 0 ||
            clientList?.filter((c) => c === client?.name)?.length > 0 ||
            client?.rootUrl?.substr(0, 4) !== "http"
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RealmClient = ({
  realmDetail,
  setRealmDetail,
  getClientInstallationDetail,
  load,
  setLoad,
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const [openNewClientDialog, setOpenNewClientDialog] = useState(false);
  const initialClient = {
    name: "",
    protocol: "",
    enabled: true,
    rootUrl: "",
    status: "Draft",
  };
  const [client, setClient] = useState(initialClient);

  const removeClient = (clientData) => {
    setRealmDetail({
      ...realmDetail,
      clients:
        realmDetail?.clients?.filter((client) => client?.name !== clientData) ||
        [],
    });
  };
  const exportFile = (clientId, clientName) => {
    if (clientId && realmDetail?.realm) {
      getKeycloakAdminToken(
        userReducerState.token,
        () => {
          setLoad(true);
        },
        (data) => {
          fetch(
            `/keycloakApi/admin/realms/${realmDetail?.realm}/clients/${clientId}/installation/providers/keycloak-oidc-keycloak-json`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${data.access_token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              downloadFile({
                data: JSON.stringify(data, null, 3),
                fileName: `${clientName}Keycloak.json`,
                fileType: "text/json",
              });
              setLoad(false);
            })
            .catch((err) => {
              setLoad(false);
            });
        },
        (err) => {
          setLoad(false);
        }
      );
    }
  };

  return (
    <>
      <NewClient
        open={openNewClientDialog}
        onClose={() => {
          setOpenNewClientDialog(false);
          setClient(initialClient);
        }}
        onSave={() => {
          setRealmDetail({
            ...realmDetail,
            clients: [...realmDetail?.clients, client],
          });
          setOpenNewClientDialog(false);
          setClient(initialClient);
        }}
        client={client}
        setClient={setClient}
        clientList={
          realmDetail?.clients?.map(
            (client) => client?.name || client?.clientId
          ) || []
        }
      />

      <TableContainer
        className={`${classes.realmClientTableContainer} waScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.realmClientTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.realmClientTableHeadCell}
              >
                Client Name
              </TableCell>

              <TableCell
                // align="center"
                className={classes.realmClientTableHeadCell}
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  color: "white",
                  fontSize: 14,
                }}
              >
                Protocol
              </TableCell>

              <TableCell
                // align="center"
                className={classes.realmClientTableHeadCell}
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  color: "white",
                  fontSize: 14,
                }}
              >
                Root URL
              </TableCell>

              <TableCell
                align="center"
                className={classes.realmClientTableHeadCell}
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  color: "white",
                  fontSize: 14,
                }}
              >
                Installation JSON
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={`${classes.realmClientTableBody} waScroll`}>
            {realmDetail?.clients?.map((client, index) => {
              return (
                <TableRow
                  key={`${client?.id}-${index}`}
                  className={classes.realmClientTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.realmClientTableBodyCell}
                  >
                    {client?.name || client?.clientId}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.realmClientTableBodyCell}
                  >
                    {client?.protocol}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={`${classes.realmClientTableBodyCell} ${classes.realmClientTableBodyTextHide}`}
                  >
                    {client?.rootUrl}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={classes.realmClientTableBodyCell}
                  >
                    {client?.status !== "Draft" ? (
                      <>
                        <Tooltip title="View">
                          <IconButton
                            size="small"
                            onClick={() =>
                              getClientInstallationDetail(client.id)
                            }
                            color="primary"
                            disabled={load}
                          >
                            <Visibility style={{ fontSize: 18 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Download">
                          <IconButton
                            size="small"
                            onClick={() =>
                              exportFile(
                                client.id,
                                client?.name || client?.clientId
                              )
                            }
                            color="primary"
                            disabled={load}
                          >
                            <GetApp style={{ fontSize: 18, marginLeft: 6 }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <Tooltip title="Remove">
                        <IconButton
                          size="small"
                          onClick={() =>
                            removeClient(client?.name || client?.clientId)
                          }
                          color="secondary"
                          disabled={load}
                        >
                          <Remove style={{ fontSize: 18 }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        size="small"
        variant="contained"
        color="primary"
        className={classes.realmClientBottomAddButton}
        onClick={() => setOpenNewClientDialog(true)}
        startIcon={<Add />}
        disabled={load}
      >
        Add
      </Button>
    </>
  );
};

const NewRealm = ({ open, onClose, resetSearch }) => {
  const classes = useStyle();
  const [load, setLoad] = useState(false);
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();
  const realms = basicReducerState.realms;
  const initialRealm = {
    // userName: process.env.REACT_APP_KEYCLOAK_USERNAME,
    // password: process.env.REACT_APP_KEYCLOAK_PASSWORD,
    // grantType: "password",
    // clientId: "admin-cli",
    realm: "",
    enabled: true,
    registrationAllowed: false,
    rememberMe: false,
    loginWithEmailAllowed: false,
    identityProviders: [],
    clients: [],
  };
  const [realm, setRealm] = useState(initialRealm);

  const handleSaveRealm = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const insertRealmUrl = `${process.env.REACT_APP_WAServices}/api/v1/keycloak/configKeyCloak`;
      const insertRealmPayload = {
        ...realm,
        identityProviders:
          realm?.identityProviders?.filter(
            (idp) => idp?.status && idp?.status === "Draft"
          ) || [],
        clients:
          realm?.clients?.filter(
            (client) => client?.status && client?.status === "Draft"
          ) || [],
      };
      const insertRealmRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(insertRealmPayload),
      };
      zipkinFetch(insertRealmUrl, insertRealmRequestParam)
        .then((res) => {
          res.json();
        })
        .then((data) => {
          setLoad(false);
          getKeycloakAdminToken(
            userReducerState.token,
            () => {
              setLoad(true);
            },
            (data) => {
              setLoad(false);
              getAllRealms(
                data.access_token,
                () => {
                  setLoad(true);
                },
                (data) => {
                  dispatch(setRealms(data || []));
                  resetSearch();
                  setLoad(false);
                  onClose();
                },
                (err) => {
                  setLoad(false);
                }
              );
            },
            (err) => {
              setLoad(false);
            }
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.newRelamDialogTitle}>
        New Realm
      </DialogTitle>

      <DialogContent className={classes.newRealmDialogContent}>
        <Loading load={load} />

        <TextField
          label="Realm name"
          variant="standard"
          required
          value={realm?.realm}
          onChange={(e) => setRealm({ ...realm, realm: e.target.value })}
          disabled={load}
          error={
            realms?.filter((p_realm) => p_realm?.realm === realm?.realm)
              ?.length > 0
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              size="small"
              checked={realm?.registrationAllowed}
              onChange={(e) =>
                setRealm({ ...realm, registrationAllowed: e.target.checked })
              }
            />
          }
          label="Registration Allowed"
          disabled={load}
        />

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              size="small"
              checked={realm?.rememberMe}
              onChange={(e) =>
                setRealm({ ...realm, rememberMe: e.target.checked })
              }
            />
          }
          label="Remember Me"
          disabled={load}
        />

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              size="small"
              checked={realm?.loginWithEmailAllowed}
              onChange={(e) =>
                setRealm({ ...realm, loginWithEmailAllowed: e.target.checked })
              }
            />
          }
          label="Login With Email Allowed"
          disabled={load}
        />
      </DialogContent>

      <DialogActions className={classes.newRealmDialogActions}>
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          style={{ borderRadius: 50 }}
        >
          Cancel
        </Button>

        <Button
          onClick={handleSaveRealm}
          color="primary"
          variant="outlined"
          style={{ borderRadius: 50 }}
          disabled={
            load ||
            realm?.realm?.length === 0 ||
            realms?.filter((p_realm) => p_realm?.realm === realm?.realm)
              ?.length > 0
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function Realms() {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
  });
  const [realms, setrealms] = useState([]);
  const [filteredRealms, setFilteredRealms] = useState([]);
  const [selectedRealmDetailContentType, setSelectedRealmDetailContentType] =
    useState("Basic Info");
  const [realmDetail, setRealmDetail] = useState(null);
  const [openNewRealmDialog, setOpenNewRealmDialog] = useState(false);
  const [search, setSearch] = useState("");
  const [deletingRealm, setDeletingRealm] = useState(null);

  useEffect(() => {
    setrealms(basicReducerState.realms);
    setFilteredRealms(basicReducerState.realms);
  }, [basicReducerState.realms]);

  const getRealmDetail = (realm) => {
    if (!realm?.realm) {
      return;
    }
    setRealmDetail({ ...realm });
    getKeycloakAdminToken(
      userReducerState.token,
      () => {
        setLoad(true);
      },
      (data) => {
        const getRealmClientUrl = `/keycloakApi/admin/realms/${realm?.realm}/clients`;
        const getRealmClientRequestParam = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        };
        const getRealmUsersUrl = `/keycloakApi/admin/realms/${realm?.realm}/users`;
        const getRealmUsersRequestParam = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        };
        Promise.all([
          fetch(getRealmClientUrl, getRealmClientRequestParam).then((res) =>
            res.json()
          ),
          fetch(getRealmUsersUrl, getRealmUsersRequestParam).then((res) =>
            res.json()
          ),
        ])
          .then(([clientData, userData]) => {
            setLoad(false);
            var allClients = clientData?.filter(
              (c) => !(c?.name?.length > 0 && c?.name?.[0] === "$")
            );
            setRealmDetail({
              ...realm,
              clients: allClients || [],
              users: userData,
            });
          })
          .catch((err) => {
            setLoad(false);
          });
      },
      (err) => {
        setLoad(false);
      }
    );
  };
  const showInstallationJson = (json) => {
    var value = "{";
    Object.keys(json)?.map((json_key) => {
      if (json_key !== "credentials") {
        value = value + '"' + json_key + '": "' + json[json_key] + '", ';
      }
    });
    value += "}";
    setAlert({ open: true, message: value });
  };
  const getClientInstallationDetail = (clientId) => {
    if (clientId && realmDetail?.realm) {
      getKeycloakAdminToken(
        userReducerState.token,
        () => {
          setLoad(true);
        },
        (data) => {
          setLoad(false);
          setLoad(true);
          fetch(
            `/keycloakApi/admin/realms/${realmDetail?.realm}/clients/${clientId}/installation/providers/keycloak-oidc-keycloak-json`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${data.access_token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              setLoad(false);
              showInstallationJson(data);
            })
            .catch((err) => {
              setLoad(false);
            });
        },
        (err) => {
          setLoad(false);
        }
      );
    }
  };
  const searchRealms = (e) => {
    setSearch(e.target.value);
    setFilteredRealms(
      realms?.filter((realm) =>
        realm?.realm?.toLowerCase()?.includes(e.target.value.toLowerCase())
      ) || []
    );
  };
  const handleUpdateRealm = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updateRealmUrl = `${process.env.REACT_APP_WAServices}/api/v1/keycloak/configKeyCloak`;
      const updateRealmPayload = {
        ...realmDetail,
        identityProviders:
          realmDetail?.identityProviders?.filter(
            (idp) => idp?.status && idp?.status === "Draft"
          ) || [],
        clients:
          realmDetail?.clients?.filter(
            (client) => client?.status && client?.status === "Draft"
          ) || [],
        users: realmDetail?.users
          ?.filter((user) => user?.status && user?.status === "Draft")
          ?.map((user) => ({
            firstName: user?.first_name || "",
            lastName: user?.last_name || "",
            email: user?.email_id || "",
            enabled: true,
            username: user?.user_name || "",
            credentials: [{ type: "password", value: user?.password || "" }],
          })),
      };
      const updateRealmRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(updateRealmPayload),
      };
      zipkinFetch(updateRealmUrl, updateRealmRequestParam)
        .then((res) => {
          res.json();
        })
        .then((data) => {
          setLoad(false);
          dispatch(
            setRealms(
              realms?.map((realm) =>
                realm?.realm === realmDetail?.realm ? realmDetail : realm
              ) || []
            )
          );
          getRealmDetail(realmDetail);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const deleteRealm = (realm_name) => {
    getKeycloakAdminToken(
      userReducerState.token,
      () => {
        setLoad(true);
      },
      (data) => {
        const requestParamsDeleteRealm = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        };
        fetch(
          `/keycloakApi/admin/realms/${realm_name}`,
          requestParamsDeleteRealm
        )
          .then((res) => {
            res.json();
          })
          .then((data) => {
            setLoad(false);
            setRealmDetail(null);
            setrealms(
              realms.filter((realm) => realm.realm !== realm_name) || []
            );
            setFilteredRealms(
              filteredRealms.filter((realm) => realm.realm !== realm_name) || []
            );
            dispatch(
              setRealms(
                realms.filter((realm) => realm.realm !== realm_name) || []
              )
            );
            setDeletingRealm(null);
          })
          .catch((err) => {
            setLoad(false);
          });
      },
      (err) => {
        setLoad(false);
      }
    );
  };
  const getRealms = () => {
    getKeycloakAdminToken(
      userReducerState.token,
      () => {
        setLoad(true);
      },
      (data) => {
        setLoad(false);
        getAllRealms(
          data.access_token,
          () => {
            setLoad(true);
          },
          (data) => {
            dispatch(setRealms(data || []));
            setLoad(false);
          },
          (err) => {
            setLoad(false);
          }
        );
      },
      (err) => {
        setLoad(false);
      }
    );
  };

  return (
    <div className={classes.realmsContainer}>
      <Loading load={load} />

      <NewRealm
        open={openNewRealmDialog}
        onClose={() => setOpenNewRealmDialog(false)}
        resetSearch={() => {
          setSearch("");
        }}
      />

      <Dialog
        open={alert.open}
        onClose={() => {
          setAlert({
            open: false,
            message: "",
          });
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <Typography
            variant="outlined"
            style={{ marginTop: 10 }}
            value={alert.message}
            fullWidth
          >
            {alert.message}
          </Typography>
        </DialogContent>

        <DialogActions
          style={{ height: "3rem", borderTop: "1px solid #d9d9d9" }}
        >
          <Button
            onClick={() => {
              setAlert({
                open: false,
                message: "",
              });
            }}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <DeletionMessageBox
        open={deletingRealm ? true : false}
        onClose={() => setDeletingRealm(null)}
        onDelete={() => {
          deleteRealm(deletingRealm);
        }}
        load={load}
      />

      <div className={classes.realmHeaderContainer}>
        <Typography className={classes.realmHeadeTitle}>Realms</Typography>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={`inputContainer`}>
            <input
              className={userReducerState.darkMode && "inputDarkMode"}
              placeholder="Search"
              value={search}
              onChange={searchRealms}
            />

            <Search />
          </div>

          <IconButton
            size="small"
            style={{ marginLeft: 4 }}
            disabled={load}
            onClick={getRealms}
          >
            <Refresh style={{ fontSize: 20 }} />
          </IconButton>

          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.realmsHeaderAddButton}
            onClick={() => {
              setOpenNewRealmDialog(true);
            }}
            startIcon={<Add />}
            disabled={load}
          >
            Add
          </Button>
        </div>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className={`${classes.realmsListContainer} waScroll`}>
            {filteredRealms?.map((realm, index) => (
              <Paper
                key={`${realm?.realm}-${index}`}
                className={`${classes.realmContent} ${
                  realmDetail?.realm === realm?.realm &&
                  classes.realmContentSelected
                }`}
                onClick={() => {
                  getRealmDetail(realm);
                }}
              >
                <Typography className={classes.realmTitle}>
                  {realm?.realm}
                </Typography>

                <IconButton
                  size="small"
                  color="secondary"
                  disabled={load}
                  onClick={(e) => {
                    e.stopPropagation();
                    // deleteRealm(realm?.realm);
                    setDeletingRealm(realm?.realm);
                  }}
                >
                  <Delete style={{ fontSize: 18 }} />
                </IconButton>
              </Paper>
            ))}
          </div>
        </Grid>

        <Grid item xs={9}>
          <Paper className={`${classes.realmDetailContainer} waScroll`}>
            {realmDetail && (
              <>
                <div className={classes.realmDetailCloseIconContainer}>
                  <Close
                    style={{ fontSize: 16, cursor: "pointer" }}
                    onClick={() => {
                      setRealmDetail(null);
                    }}
                  />
                </div>

                <div className={classes.realmDetailHeaderContainer}>
                  <Typography
                    className={`${classes.realmDetailHeaderItem} ${
                      selectedRealmDetailContentType === "Basic Info" &&
                      classes.realmDetailHeaderItemSelected
                    }`}
                    onClick={() =>
                      setSelectedRealmDetailContentType("Basic Info")
                    }
                  >
                    Basic Info
                  </Typography>

                  <Typography
                    className={`${classes.realmDetailHeaderItem} ${
                      selectedRealmDetailContentType === "Identity Providers" &&
                      classes.realmDetailHeaderItemSelected
                    }`}
                    onClick={() =>
                      setSelectedRealmDetailContentType("Identity Providers")
                    }
                  >
                    Identity Providers
                  </Typography>

                  <Typography
                    className={`${classes.realmDetailHeaderItem} ${
                      selectedRealmDetailContentType === "Clients" &&
                      classes.realmDetailHeaderItemSelected
                    }`}
                    onClick={() => setSelectedRealmDetailContentType("Clients")}
                  >
                    Clients
                  </Typography>

                  <Typography
                    className={`${classes.realmDetailHeaderItem} ${
                      selectedRealmDetailContentType === "Users" &&
                      classes.realmDetailHeaderItemSelected
                    }`}
                    onClick={() => setSelectedRealmDetailContentType("Users")}
                  >
                    Users
                  </Typography>
                </div>

                {selectedRealmDetailContentType === "Basic Info" && (
                  <RealmInfo
                    realmDetail={realmDetail}
                    setRealmDetail={setRealmDetail}
                    load={load}
                    setLoad={setLoad}
                  />
                )}

                {selectedRealmDetailContentType === "Identity Providers" && (
                  <RealmIdp
                    realmDetail={realmDetail}
                    setRealmDetail={setRealmDetail}
                    setAlert={setAlert}
                    load={load}
                    setLoad={setLoad}
                  />
                )}

                {selectedRealmDetailContentType === "Clients" && (
                  <RealmClient
                    realmDetail={realmDetail}
                    setRealmDetail={setRealmDetail}
                    getClientInstallationDetail={getClientInstallationDetail}
                    load={load}
                    setLoad={setLoad}
                  />
                )}

                {selectedRealmDetailContentType === "Users" && (
                  <RealmUser
                    realmDetail={realmDetail}
                    setRealmDetail={setRealmDetail}
                    load={load}
                    setLoad={setLoad}
                  />
                )}

                <div className={classes.realmDetailFooter}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.realmDetailFooterButton}
                    onClick={handleUpdateRealm}
                    disabled={load}
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Realms;
