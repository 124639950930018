import React from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Line,
  Area,
  Tooltip,
} from "recharts";

function GraphViewLicense({ graphType, formattedLicenses }) {
  return (
    <ResponsiveContainer height="50vh" aspect={3}>
      <ComposedChart width={500} height={350} data={formattedLicenses}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="applicationName" />
        <YAxis />
        <Tooltip />
        <Legend margin={{ top: 10 }} />

        {graphType === "Bar" && <Bar dataKey="available" fill="green" />}
        {graphType === "Bar" && <Bar dataKey="assigned" fill="red" />}

        {graphType === "Line" && (
          <Line
            type="monotone"
            dataKey="available"
            stroke="green"
            activeDot={{ r: 4 }}
          />
        )}
        {graphType === "Line" && (
          <Line
            type="monotone"
            dataKey="assigned"
            stroke="red"
            activeDot={{ r: 4 }}
          />
        )}

        {graphType === "Area" && (
          <Area
            type="monotone"
            dataKey="available"
            stroke="green"
            fill="green"
            activeDot={{ r: 4 }}
          />
        )}
        {graphType === "Area" && (
          <Area
            type="monotone"
            dataKey="assigned"
            stroke="red"
            fill="red"
            activeDot={{ r: 4 }}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default GraphViewLicense;
