import React, { useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

function AlreadyLogin({ message, keycloak }) {
  const userReducerState = useSelector((state) => state.userReducer);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (userReducerState?.keycloak) {
  //       userReducerState?.keycloak?.logout();
  //     }
  //   }, 3000);
  // }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src="/Cw_logo.png" alt="WorkAccess_Logo" style={{ height: 60 }} />

      <Typography
        style={{
          marginTop: 20,
          fontSize: 14,
          marginBottom: 6,
          fontWeight: "bold",
        }}
      >
        {message}
      </Typography>

      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          if (keycloak) {
            keycloak?.logout();
          }
        }}
        style={{ borderRadius: 50 }}
      >
        Login Page
      </Button>
    </div>
  );
}

export default AlreadyLogin;
