import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Users from "./Components/Users/Users";
import Roles from "./Components/Roles/Roles";
import Sidebar from "./Components/Sidebar";
import AddKeycloakUser from "./Components/Keycloak/AddKeycloakUser";
import Applications from "./Components/Applications/Applications";
import Licenses from "./Components/Licenses/Licenses";
import SignOut from "./Components/SignOut";
import Header from "./Components/Header";
import Realms from "./Components/Keycloak/Realms";
import Groups from "./Components/Groups/Groups";
import AccessDenied from "./Components/AccessDenied";
import { completeEntities } from "./Utility/config";
import Dashboard from "./Components/Dashboard/Dashboard";
// import Apis from "./Components/Applications/Apis";
// import { Authoring } from "@cw/rule-generator";
import Toast from "./Components/Toast";

const useStyle = makeStyles((theme) => ({
  appContainer: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  appBody: {
    display: "flex",
    width: "100%",
    overflow: "hidden",
  },
}));

function App({ keycloak }) {
  const classes = useStyle();
  const [openSignOut, setOpenSignOut] = useState(false);
  // const role = "WORKACCESS ADMIN";
  const userReducerState = useSelector((state) => state.userReducer);
  const userEntitiesList =
    Object.keys(
      userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
    ) || [];

  return (
    <div
      className={classes.appContainer}
      onClick={(e) => {
        setOpenSignOut(false);
      }}
    >
      <Router>
        <Header openSignOut={openSignOut} setOpenSignOut={setOpenSignOut} />

        <div className={classes.appBody}>
          <Sidebar />
          {openSignOut && <SignOut keycloak={keycloak} />}

          <Switch>
            <Route exact path="/">
              {userEntitiesList?.includes(completeEntities?.USERMODULE) ? (
                <Redirect to="/users" />
              ) : userEntitiesList?.includes(completeEntities?.ROLEMODULE) ? (
                <Redirect to="/roles" />
              ) : userEntitiesList?.includes(
                  completeEntities?.APPLICATIONMODULE
                ) ? (
                <Redirect to="/applications" />
              ) : userEntitiesList?.includes(
                  completeEntities?.LICENSEMODULE
                ) ? (
                <Licenses />
              ) : (
                <Redirect to="/groups" />
              )}
            </Route>

            <Route exact path="/dashboard">
              <Dashboard />
            </Route>

            <Route exact path="/users">
              {userEntitiesList?.includes(completeEntities?.USERMODULE) ? (
                <Users />
              ) : (
                <AccessDenied />
              )}
            </Route>

            <Route path="/users/:userId">
              {userEntitiesList?.includes(completeEntities?.USERMODULE) ? (
                <Users />
              ) : (
                <AccessDenied />
              )}
            </Route>

            <Route exact path="/roles">
              {userEntitiesList?.includes(completeEntities?.ROLEMODULE) ? (
                <Roles />
              ) : (
                <AccessDenied />
              )}
            </Route>

            <Route path="/roles/:roleId">
              {userEntitiesList?.includes(completeEntities?.ROLEMODULE) ? (
                <Roles />
              ) : (
                <AccessDenied />
              )}
            </Route>

            <Route path="/keycloakUser">
              {userEntitiesList?.includes(completeEntities?.KEYCLOAKMODULE) ? (
                <AddKeycloakUser />
              ) : (
                <AccessDenied />
              )}
            </Route>

            <Route exact path="/applications">
              {userEntitiesList?.includes(
                completeEntities?.APPLICATIONMODULE
              ) ? (
                <Applications />
              ) : (
                <AccessDenied />
              )}
            </Route>

            <Route path="/applications/:applicationId">
              {userEntitiesList?.includes(
                completeEntities?.APPLICATIONMODULE
              ) ? (
                <Applications />
              ) : (
                <AccessDenied />
              )}
            </Route>

            <Route exact path="/groups">
              <Groups />
            </Route>

            <Route exact path="/groups/:groupId">
              <Groups />
            </Route>

            <Route path="/realms">
              {userEntitiesList?.includes(completeEntities?.KEYCLOAKMODULE) ? (
                <Realms />
              ) : (
                <AccessDenied />
              )}
            </Route>

            <Route path="/licenses">
              {userEntitiesList?.includes(completeEntities?.LICENSEMODULE) ? (
                <Licenses />
              ) : (
                <AccessDenied />
              )}
            </Route>

            {/* <Route exact path="/apis">
              <Apis />
            </Route>

            <Route path="/apis/:applicationId">
              <Apis />
            </Route> */}

            {/* <Route path="/rules">
              <Authoring
                Dtdetails={{
                  RMSid: "cd13db8f98b042c68ebc6cd098447659",
                  RSid: "f9576dae6b184e1a8bec333d604e2c80",
                  DTid: "DT_000588",
                  applicationId: "55ab8902-abee-4381-b40e-a88acc95570c",
                  token: userReducerState?.token,
                }}
              />
            </Route> */}

            <Route exact path="/*">
              <AccessDenied />
            </Route>
          </Switch>
        </div>
      </Router>

      <Toast />
    </div>
  );
}

export default App;
