import React, { useState } from "react";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import { getKeycloakAdminToken } from "../../Actions/action";
import Loading from "../Loading";

const useStyle = makeStyles((theme) => ({
  addKeycloakUserContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    maxWidth: 500,
    width: "100%",
    margin: "auto",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  addKeycloakUserRealmDropdown: {
    margin: "2px 0px",
    marginRight: 6,
  },
  addKeycloakUserSubmitButton: {
    marginTop: "15px",
    borderRadius: 50,
  },
}));

function AddKeycloakUser() {
  const classes = useStyle();
  const [load, setLoad] = useState(false);
  const initialState = {
    first_name: "",
    last_name: "",
    user_name: "",
    email_id: "",
    password: "",
  };
  const [user, setUser] = useState(initialState);
  const [realm, setRealm] = useState("");
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);

  const handleCreateRealmUser = (e) => {
    if (authVerify(userReducerState.token)) {
      getKeycloakAdminToken(
        userReducerState.token,
        () => {
          setLoad(true);
        },
        (data) => {
          setLoad(false);
          const newUser = {
            firstName: user?.first_name || "",
            lastName: user?.last_name || "",
            email: user?.email_id || "",
            enabled: true,
            username: user?.user_name || "",
            credentials: [{ type: "password", value: user?.password || "" }],
          };
          const requestParam = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${data.access_token}`,
            },
            body: JSON.stringify(newUser),
          };
          setLoad(true);
          fetch(`/keycloakApi/admin/realms/${realm}/users`, requestParam)
            .then((res) => {
              // console.log(res);
            })
            .then((data) => {
              setLoad(false);
              setUser(initialState);
            })
            .catch((err) => {
              setLoad(false);
            });
        },
        (err) => {}
      );
    } else {
      window.location.reload(0);
    }
  };

  return (
    <div className={classes.addKeycloakUserContainer}>
      <Loading load={load} />

      <FormControl
        variant="standard"
        size="small"
        fullWidth
        required
        className={classes.addKeycloakUserRealmDropdown}
      >
        <InputLabel>Realm</InputLabel>
        <Select
          size="small"
          style={{ fontSize: 12 }}
          value={realm}
          onChange={(e) => {
            setRealm(e.target.value);
          }}
        >
          {basicReducerState?.realms?.map((realm, index) => (
            <MenuItem
              key={`${realm?.realm}-${index}`}
              value={realm?.realm}
              style={{ fontSize: 12 }}
            >
              {realm?.realm}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        variant="standard"
        required
        fullWidth
        label="First Name"
        value={user?.first_name}
        onChange={(e) => setUser({ ...user, first_name: e.target.value })}
      />

      <TextField
        variant="standard"
        required
        fullWidth
        label="Last Name"
        value={user?.last_name}
        onChange={(e) => setUser({ ...user, last_name: e.target.value })}
      />

      <TextField
        variant="standard"
        required
        fullWidth
        label="User Name"
        value={user?.user_name}
        onChange={(e) => setUser({ ...user, user_name: e.target.value })}
      />

      <TextField
        variant="standard"
        required
        fullWidth
        label="Email Id"
        type="email"
        value={user?.email_id}
        onChange={(e) => setUser({ ...user, email_id: e.target.value })}
      />

      <TextField
        variant="standard"
        required
        fullWidth
        label="Password"
        type="password"
        value={user?.password}
        onChange={(e) => setUser({ ...user, password: e.target.value })}
      />

      <Button
        fullWidth
        variant="outlined"
        color="primary"
        className={classes.addKeycloakUserSubmitButton}
        onClick={handleCreateRealmUser}
        disabled={
          load ||
          realm?.length === 0 ||
          user?.first_name.length === 0 ||
          user?.last_name.length === 0 ||
          user?.user_name.length === 0 ||
          user?.email_id.length === 0 ||
          user?.password.length === 0
        }
      >
        Submit
      </Button>
    </div>
  );
}

export default AddKeycloakUser;
