import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  //  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
// import {
//   allActivities,
//   allApplications,
//   allRoles,
//   allUsers,
//   selectedRoleDetail,
// } from "../../Data/data";
import {
  Add,
  Close,
  Delete,
  Remove,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@material-ui/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import { findEntityById, findRoleById, findApiByid } from "../../Utility/basic";
import Loading from "../Loading";
import { findApplicationById, findUserById } from "../../Utility/basic";
import { Autocomplete } from "@material-ui/lab";
import {
  setResponseMessage,
  setRoles,
} from "../../Redux/Reducers/basicReducer";
import DeletionMessageBox from "../DeletionMessageBox";
import { zipkinFetch } from "../../Utility/browser";

const useStyle = makeStyles((theme) => ({
  roleInfoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    height: "63vh",
  },
  roleInfoItemContainer: {
    margin: 4,
    alignItems: "center",
  },
  roleInfoContainerLabel: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  roleInfoContainerText: {
    fontSize: 12,
  },

  newRoleAssignedUserDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newRoleAssignedUserDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  roleAssignedUsersTableContainer: {
    height: "54vh",
    // overflow: "overlay",
    width: "100%",
  },
  roleAssignedUsersTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  roleAssignedUsersTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  roleAssignedUsersTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  roleAssignedUsersTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  roleAssignedUsersTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  roleAssignedUsersBottonAddButton: {
    margin: "4px 10px",
    borderRadius: 50,
  },

  newRoleAssignedActivityDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newRoleAssignedActivityDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  roleAssignedActivitiesTableContainer: {
    height: "54vh",
    // overflow: "overlay",
    width: "100%",
  },
  roleAssignedActivitiesTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  roleAssignedActivitiesTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  roleAssignedActivitiesTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  roleAssignedActivitiesTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  roleAssignedActivitiesTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  roleAssignedActivitiesBottonAddButton: {
    margin: "4px 10px",
    borderRadius: 50,
  },

  roleDetailContainer: {
    flexDirection: "column",
    height: "100%",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: theme.palette.background.paper,
    margin: 0,
    padding: 0,
    // overflow: "overlay",
    position: "relative",
  },
  roleDetailCrossButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 10,
    paddingTop: 10,
  },
  roleDetailHeaderContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
  },
  roleDetailHeaderItem: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    cursor: "pointer",
    width: 150,
    fontSize: 14,
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  roleDetailHeaderItemSelected: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  roleDetailFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxShadow: "0px 0px 9px #D8D8D8",
    padding: "8px 16px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 999,
  },
  roleDetailFooterButton: {
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const RoleInfo = ({ roleDetail, setRoleDetail, params }) => {
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const classes = useStyle();
  // const params = useParams();

  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState.applications
    );
    return application?.name || "-";
  };

  return (
    <div className={classes.roleInfoContainer}>
      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Name
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <div
            className={`inputContainer ${
              basicReducerState?.roles?.find(
                (role) =>
                  role?.name === roleDetail?.name &&
                  role?.applicationId === roleDetail?.applicationId &&
                  Number(role?.id) !== Number(params?.roleId)
              ) && "inputError"
            }`}
          >
            <input
              className={`${classes.roleInfoContainerText} ${
                userReducerState.darkMode && "inputDarkMode"
              }`}
              value={roleDetail?.name}
              onChange={(e) =>
                setRoleDetail({ ...roleDetail, name: e.target.value })
              }
            />
          </div>

          {basicReducerState?.roles?.find(
            (role) =>
              role?.name === roleDetail?.name &&
              role?.applicationId === roleDetail?.applicationId &&
              Number(role?.id) !== Number(params?.roleId)
          ) && (
            <p style={{ color: "red", fontSize: 10, marginTop: 3 }}>
              Role name already exists
            </p>
          )}
          {/* <Typography className={classes.roleInfoContainerText}>
            {roleDetail?.name}
          </Typography> */}
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Label
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <div className={`inputContainer`}>
            <input
              className={`${classes.roleInfoContainerText} ${
                userReducerState.darkMode && "inputDarkMode"
              }`}
              value={roleDetail?.label}
              onChange={(e) =>
                setRoleDetail({ ...roleDetail, label: e.target.value })
              }
            />
          </div>
          {/* <Typography className={classes.roleInfoContainerText}>
            {roleDetail?.label}
          </Typography> */}
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Description
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <div className={`inputContainer`}>
            <input
              className={`${classes.roleInfoContainerText} ${
                userReducerState.darkMode && "inputDarkMode"
              }`}
              value={roleDetail?.description}
              onChange={(e) =>
                setRoleDetail({ ...roleDetail, description: e.target.value })
              }
            />
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Application
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.roleInfoContainerText}>
            {getApplicationNameById(roleDetail?.applicationId)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Type
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.roleInfoContainerText}>
            {roleDetail?.isComposite === 1 ? "Composite" : "Single"}
          </Typography>
        </Grid>
      </Grid>

      {/* {roleDetail?.isComposite === 1 && (
        <Grid
          container
          className={classes.roleInfoItemContainer}
          style={{ alignItems: "flex-start" }}
        >
          <Grid item xs={4}>
            <Typography className={classes.roleInfoContainerLabel}>
              Associate Roles
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              multiple
              size="small"
              style={{ fontSize: 12 }}
              value={roleDetail?.associateRoles}
              onChange={(e, roles) => {
                setRoleDetail({
                  ...roleDetail,
                  associateRoles: roles,
                });
              }}
              options={basicReducerState?.roles?.filter(
                (role) =>
                  role?.isComposite !== 1 &&
                  role?.applicationId === roleDetail?.applicationId
              )}
              getOptionLabel={(option) => option.name}
              renderOption={(option, {selected}) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
                <Typography style={{ fontSize: 12 }}>{option.name}</Typography>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  style={{ fontSize: 12 }}
                />
              )}
            />
          </Grid>
        </Grid>
      )} */}

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Status
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.roleInfoContainerText}>
            {roleDetail?.status}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            No of Expiry Days
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.roleInfoContainerText}>
            {roleDetail?.noOfExpiryDays}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Expiry Mail Trigger Days
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.roleInfoContainerText}>
            {roleDetail?.expiryMailTriggerDays}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const NewRoleAssociateRole = ({
  open,
  onClose,
  roleDetail,
  setRoleDetail,
  params: { roleId },
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [newAssociateRoles, setNewAssociateRoles] = useState([]);

  const presentRole = (roleId) => {
    return roleDetail?.associateRoles?.filter(
      (role) => Number(role?.id) === Number(roleId)
    ).length > 0
      ? true
      : false;
  };
  // console.log(roleDetail.associateRoles);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newRoleAssignedActivityDialogTitle}>
        New Associate Role
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          size="small"
          style={{ fontSize: 12 }}
          value={newAssociateRoles}
          onChange={(e, roles) => {
            setNewAssociateRoles(
              roles.map((role) => ({ ...role, status: "Draft" })) || []
            );
          }}
          options={basicReducerState?.roles?.filter(
            (role) =>
              role?.isComposite !== 1 &&
              role?.applicationId === roleDetail?.applicationId &&
              !presentRole(role?.id)
          )}
          getOptionLabel={(option) => option.name}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>{option.name}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              style={{ fontSize: 12 }}
              label="Associate Role"
            />
          )}
        />
      </DialogContent>

      <DialogActions className={classes.newRoleAssignedActivityDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ borderRadius: 50 }}
          onClick={() => {
            onClose();
            setNewAssociateRoles([]);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            setRoleDetail({
              ...roleDetail,
              associateRoles: [
                ...roleDetail?.associateRoles,
                ...newAssociateRoles,
              ],
            });
            onClose();
            setNewAssociateRoles([]);
          }}
          style={{ borderRadius: 50 }}
          disabled={newAssociateRoles?.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RoleAssociateRoles = ({
  roleDetail,
  setRoleDetail,
  getRoleInfoById,
  load,
  setLoad,
  params,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const userReducerState = useSelector((state) => state.userReducer);
  // const params = useParams();
  // const [load, setLoad] = useState(false);
  const [openAssociateRoleDialog, setOpenAssociateRoleDialog] = useState(false);
  const [deletingAssociateRole, setDeletingAssociateRole] = useState(null);

  const deleteAssociateRole = (associateRole) => {
    if (associateRole?.status === "Draft") {
      setRoleDetail({
        ...roleDetail,
        associateRoles: roleDetail?.associateRoles?.filter(
          (role) => Number(role?.id) !== Number(associateRole?.id)
        ),
      });
      setDeletingAssociateRole(null);
    } else {
      if (authVerify(userReducerState.token)) {
        const updateRoleAssociateRoleUrl = `${process.env.REACT_APP_WAServices}/api/v1/roles/modify`;
        const updateRoleAssociateRolePayload = {
          applicationId: roleDetail?.applicationId,
          associateRoles:
            roleDetail?.associateRoles
              ?.filter((role) => Number(role?.id) !== Number(associateRole?.id))
              .map((role) => role?.id)
              ?.join(",") || "",
          createdBy: roleDetail?.createdBy,
          createdOn: moment(new Date(roleDetail?.createdOn)).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          description: roleDetail?.description,
          expiryMailTriggerDays: roleDetail?.expiryMailTriggerDays,
          hasExpiry: roleDetail?.hasExpiry,
          id: Number(params?.roleId),
          isActive: 1,
          isComposite: roleDetail?.isComposite,
          isDeleted: 0,
          label: roleDetail?.label,
          name: roleDetail?.name,
          noOfExpiryDays: roleDetail?.noOfExpiryDays,
          status: "Active",
          updatedBy: userReducerState?.user?.preferred_username,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
        const updateRoleAssociateRoleRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
          },
          body: JSON.stringify(updateRoleAssociateRolePayload),
        };
        setLoad(true);
        zipkinFetch(
          updateRoleAssociateRoleUrl,
          updateRoleAssociateRoleRequestParam
        )
          .then((res) => res.json())
          .then((data) => {
            // setRoleDetail({
            //   ...roleDetail,
            //   associateRoles: roleDetail?.associateRoles?.filter(
            //     (role) => Number(role.id) !== Number(associateRole?.id)
            //   ),
            // });
            getRoleInfoById();
            setLoad(false);
            setDeletingAssociateRole(null);

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.message?.toString(),
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        window.location.reload(0);
      }
    }
  };

  return (
    <>
      <Loading load={load} />

      <NewRoleAssociateRole
        open={openAssociateRoleDialog}
        onClose={() => setOpenAssociateRoleDialog(false)}
        roleDetail={roleDetail}
        setRoleDetail={setRoleDetail}
        params={params}
      />

      <DeletionMessageBox
        open={deletingAssociateRole ? true : false}
        onClose={() => setDeletingAssociateRole(null)}
        onDelete={() => {
          deleteAssociateRole(deletingAssociateRole);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.roleAssignedActivitiesTableContainer} waScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.roleAssignedActivitiesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.roleAssignedActivitiesTableHeadCell}
              >
                Role Name
              </TableCell>

              <TableCell
                align="center"
                className={classes.roleAssignedActivitiesTableHeadCell}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.roleAssignedActivitiesTableBody} waScroll`}
          >
            {roleDetail?.associateRoles?.map((associateRole, index) => {
              return (
                <TableRow
                  key={`${associateRole?.id}-${index}`}
                  className={classes.roleAssignedActivitiesTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.roleAssignedActivitiesTableBodyCell}
                  >
                    {associateRole?.name}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={classes.roleAssignedActivitiesTableBodyCell}
                  >
                    <Tooltip
                      title={
                        associateRole?.status === "Draft" ? "Remove" : "Delete"
                      }
                    >
                      <IconButton
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          // deleteAssociateRole(associateRole);
                          setDeletingAssociateRole(associateRole);
                        }}
                        disabled={load}
                      >
                        {associateRole?.status === "Draft" ? (
                          <Remove style={{ fontSize: 16 }} />
                        ) : (
                          <Delete style={{ fontSize: 16 }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        size="small"
        variant="contained"
        color="primary"
        className={classes.roleAssignedActivitiesBottonAddButton}
        onClick={() => setOpenAssociateRoleDialog(true)}
        startIcon={<Add />}
        disabled={load}
      >
        Add
      </Button>
    </>
  );
};

const NewRoleAssignedUser = ({
  open,
  onClose,
  roleDetail,
  setRoleDetail,
  params: { roleId },
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  // const location = useLocation();
  // const pathname = location.pathname.split("/");
  // const { roleId } = useParams();
  const initialNewAssignedUser = {
    userId: "",
    permissionType: "",
    isActive: 1,
    isEdited: 0,
    associationId: Number(roleId),
    associationType: "ROLE",
    createdBy: userReducerState?.user?.preferred_username,
    createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    isDeleted: 0,
    startDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    status: "Draft",
    updatedBy: userReducerState?.user?.preferred_username,
    updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    isGroupRole: 0,
    groupRole: "",
  };
  const assignedUsersId = roleDetail?.mappedUsers?.map(
    (assignedUser) => assignedUser?.userId
  );
  const [newAssignedUser, setNewAssignedUser] = useState(
    initialNewAssignedUser
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newRoleAssignedUserDialogTitle}>
        New Assigned User
      </DialogTitle>

      <DialogContent>
        <FormControl
          variant="standard"
          size="small"
          fullWidth
          required
          // style={{ margin: "2px 0px" }}
        >
          <InputLabel>User</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newAssignedUser?.userId}
            onChange={(e) =>
              setNewAssignedUser({
                ...newAssignedUser,
                userId: e.target.value,
              })
            }
          >
            {basicReducerState?.users?.map(
              (user, index) =>
                !assignedUsersId?.includes(user?.id) && (
                  <MenuItem
                    key={`${user?.id}-${index}`}
                    value={user?.id}
                    style={{ fontSize: 12 }}
                  >
                    {user?.displayName}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions className={classes.newRoleAssignedUserDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ borderRadius: 50 }}
          onClick={() => {
            onClose();
            setNewAssignedUser(initialNewAssignedUser);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            setRoleDetail({
              ...roleDetail,
              mappedUsers: [newAssignedUser, ...roleDetail?.mappedUsers],
            });
            onClose();
            setNewAssignedUser(initialNewAssignedUser);
          }}
          style={{ borderRadius: 50 }}
          disabled={
            newAssignedUser?.userId?.length === 0 ||
            roleDetail?.mappedUsers?.filter(
              (assignedUser) => assignedUser?.userId === newAssignedUser?.userId
            )?.length > 0
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RoleAssignedUsers = ({
  roleDetail,
  setRoleDetail,
  load,
  setLoad,
  params: { roleId },
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [openAssignUserDialog, setOpenAssignUserDialog] = useState(false);
  const [deletingAssignedUser, setDeletingAssignedUser] = useState(null);

  const deleteAssignedUser = (assignedUser) => {
    if (assignedUser?.status === "Draft") {
      setRoleDetail({
        ...roleDetail,
        mappedUsers:
          roleDetail?.mappedUsers?.filter(
            (user) => !(user?.userId === assignedUser?.userId)
          ) || [],
      });
      setDeletingAssignedUser(null);
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const disableRoleUserMappingUrl = `${process.env.REACT_APP_WAServices}/api/v1/users/userRoleMapping`;
        const disableRoleUserMappingPayload = {
          associationId: assignedUser?.associationId,
          associationType: assignedUser?.associationType,
          createdBy: assignedUser?.createdBy,
          createdOn: moment(new Date(assignedUser?.createdOn)).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          endDate: moment(new Date(assignedUser?.endDate)).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          isActive: 0,
          isDeleted: 1,
          startDate: moment(new Date(assignedUser?.startDate)).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          status: "Inactive",
          updatedBy: userReducerState?.user?.preferred_username,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          userId: assignedUser?.userId,
        };
        const disableRoleUserMappingRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
          },
          body: JSON.stringify(disableRoleUserMappingPayload),
        };
        zipkinFetch(
          disableRoleUserMappingUrl,
          disableRoleUserMappingRequestParam
        )
          .then((res) => res.json())
          .then((data) => {
            setRoleDetail({
              ...roleDetail,
              mappedUsers:
                roleDetail?.mappedUsers?.filter(
                  (user) => !(user?.userId === assignedUser?.userId)
                ) || [],
            });
            setDeletingAssignedUser(null);
            setLoad(false);

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.message?.toString(),
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        window.location.reload(0);
      }
    }
  };
  const getUserNameById = (userId) => {
    const user = findUserById(Number(userId), basicReducerState.users);
    return user.displayName;
  };

  return (
    <>
      <NewRoleAssignedUser
        open={openAssignUserDialog}
        onClose={() => setOpenAssignUserDialog(false)}
        roleDetail={roleDetail}
        setRoleDetail={setRoleDetail}
        params={{ roleId: roleId }}
      />

      <DeletionMessageBox
        open={deletingAssignedUser ? true : false}
        onClose={() => setDeletingAssignedUser(null)}
        onDelete={() => {
          deleteAssignedUser(deletingAssignedUser);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.roleAssignedUsersTableContainer} waScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.roleAssignedUsersTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.roleAssignedUsersTableHeadCell}
              >
                User
              </TableCell>

              <TableCell
                // align="center"
                className={classes.roleAssignedUsersTableHeadCell}
              >
                Status
              </TableCell>

              <TableCell
                align="center"
                className={classes.roleAssignedUsersTableHeadCell}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.roleAssignedUsersTableBody} waScroll`}
          >
            {roleDetail?.mappedUsers?.map((assignedUser, index) => {
              return (
                <TableRow
                  key={`${assignedUser?.id}-${index}`}
                  className={classes.roleAssignedUsersTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.roleAssignedUsersTableBodyCell}
                  >
                    {assignedUser?.status === "Draft" ? (
                      <Select
                        size="small"
                        style={{ fontSize: 12 }}
                        value={assignedUser?.userId}
                        readOnly
                      >
                        {basicReducerState?.users?.map((user, index) => (
                          <MenuItem
                            key={`${user?.id}-${index}`}
                            value={user?.id}
                            style={{ fontSize: 12 }}
                          >
                            {user?.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      getUserNameById(assignedUser?.userId)
                    )}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.roleAssignedUsersTableBodyCell}
                  >
                    {assignedUser?.status}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={classes.roleAssignedUsersTableBodyCell}
                  >
                    <Tooltip
                      title={
                        assignedUser?.status === "Draft" ? "Remove" : "Delete"
                      }
                    >
                      <IconButton
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          // deleteAssignedUser(assignedUser);
                          setDeletingAssignedUser(assignedUser);
                        }}
                        disabled={load}
                      >
                        {assignedUser?.status === "Draft" ? (
                          <Remove style={{ fontSize: 16 }} />
                        ) : (
                          <Delete style={{ fontSize: 16 }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        size="small"
        variant="contained"
        color="primary"
        className={classes.roleAssignedUsersBottonAddButton}
        onClick={() => setOpenAssignUserDialog(true)}
        startIcon={<Add />}
        disabled={load}
      >
        Add
      </Button>
    </>
  );
};

const NewRoleAssignedActivity = ({
  open,
  onClose,
  roleDetail,
  setRoleDetail,
  params: { roleId },
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  // const location = useLocation();
  // const pathname = location.pathname.split("/");
  // const { roleId } = useParams();
  const initialNewAssignedActivity = {
    isActive: 1,
    activityId: "",
    createdBy: userReducerState?.user?.preferred_username,
    createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    isDeleted: 0,
    roleId: Number(roleId),
    status: "Draft",
    updatedBy: userReducerState?.user?.preferred_username,
    updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  };
  const [newAssignedActivity, setNewAssignedActivity] = useState(
    initialNewAssignedActivity
  );
  const [entitiesId, setEntitiesId] = useState([]);

  useEffect(() => {
    setEntitiesId(getEntitiesIdByApplicationId());
  }, [roleDetail?.mappedActivities]);
  const presentAssignedActivities = (activityName) => {
    return roleDetail?.mappedActivities?.filter(
      (assignedActivity) => assignedActivity?.name === activityName
    ).length > 0
      ? true
      : false;
  };
  const getEntityNameById = (entityId) => {
    const entity = findEntityById(Number(entityId), basicReducerState.entities);
    return entity?.name || "-";
  };
  const getEntitiesIdByApplicationId = () => {
    const entitiesList =
      basicReducerState?.entities?.filter(
        (entity) =>
          entity?.applicationId === roleDetail?.applicationId && entity?.id
      ) || [];
    const entitiesIdList = entitiesList.map((entity) => entity.id);
    return entitiesIdList;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newRoleAssignedActivityDialogTitle}>
        New Assigned Feature
      </DialogTitle>

      <DialogContent>
        <FormControl
          variant="standard"
          size="small"
          fullWidth
          required
          // style={{ margin: "2px 0px" }}
        >
          <InputLabel>Features</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newAssignedActivity?.activityId}
            onChange={(e) =>
              setNewAssignedActivity({
                ...newAssignedActivity,
                activityId: e.target.value,
              })
            }
          >
            {/* && !assignedActivitiesName.includes(activity?.name) */}
            {basicReducerState?.activities?.map(
              (activity, index) =>
                entitiesId.includes(activity?.entityId) &&
                !presentAssignedActivities(activity?.name) && (
                  <MenuItem
                    key={`${activity?.id}-${index}`}
                    value={activity?.id}
                    style={{ fontSize: 12 }}
                  >
                    {`${activity?.name} (${getEntityNameById(
                      activity?.entityId
                    )})`}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions className={classes.newRoleAssignedActivityDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ borderRadius: 50 }}
          onClick={() => {
            onClose();
            setNewAssignedActivity(initialNewAssignedActivity);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            setRoleDetail({
              ...roleDetail,
              mappedActivities: [
                newAssignedActivity,
                ...roleDetail.mappedActivities,
              ],
            });
            onClose();
            setNewAssignedActivity(initialNewAssignedActivity);
          }}
          style={{ borderRadius: 50 }}
          disabled={
            newAssignedActivity?.activityId?.length === 0 ||
            roleDetail?.mappedActivities?.filter(
              (assignedActivity) =>
                assignedActivity?.activityId === newAssignedActivity?.activityId
            )?.length > 0
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RoleAssignedActivities = ({
  roleDetail,
  setRoleDetail,
  load,
  setLoad,
  params: { roleId },
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [openAssignActivityDialog, setOpenAssignActivityDialog] =
    useState(false);
  const [deletingAssignedActivity, setDeletingAssignedActivity] =
    useState(null);
  // const location = useLocation();
  // const pathname = location.pathname.split("/");
  // const { roleId } = useParams();

  const deleteAssignedActivity = (assignedActivity) => {
    if (assignedActivity.status === "Draft") {
      setRoleDetail({
        ...roleDetail,
        mappedActivities:
          roleDetail?.mappedActivities?.filter(
            (activity) =>
              !(activity?.activityId === assignedActivity?.activityId)
          ) || [],
      });
      setDeletingAssignedActivity(null);
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const disableRoleMappedctivityUrl = `${process.env.REACT_APP_WAServices}/api/v1/roles/activityRoleMapping/modify`;
        // console.log(assignedActivity);
        const disableRoleMappedctivityPayload = {
          activityId: assignedActivity?.id,
          createdBy: assignedActivity?.createdBy,
          createdOn: moment(new Date(assignedActivity?.createdOn)).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          isActive: 0,
          isDeleted: 1,
          status: "Inactive",
          updatedBy: userReducerState?.user?.preferred_username,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          roleId: Number(roleId),
        };
        const disableRoleMappedctivityRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
          },
          body: JSON.stringify(disableRoleMappedctivityPayload),
        };
        zipkinFetch(
          disableRoleMappedctivityUrl,
          disableRoleMappedctivityRequestParam
        )
          .then((res) => res.json())
          .then((data) => {
            setRoleDetail({
              ...roleDetail,
              mappedActivities:
                roleDetail?.mappedActivities?.filter(
                  (activity) => activity?.id !== assignedActivity?.id
                ) || [],
            });
            setDeletingAssignedActivity(null);
            setLoad(false);

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.message?.toString(),
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        window.location.reload(0);
      }
    }
  };
  const getEntityNameById = (entityId) => {
    const entity = findEntityById(Number(entityId), basicReducerState.entities);
    return entity?.name || "-";
  };

  return (
    <>
      <NewRoleAssignedActivity
        open={openAssignActivityDialog}
        onClose={() => setOpenAssignActivityDialog(false)}
        roleDetail={roleDetail}
        setRoleDetail={setRoleDetail}
        params={{ roleId: roleId }}
      />

      <DeletionMessageBox
        open={deletingAssignedActivity ? true : false}
        onClose={() => setDeletingAssignedActivity(null)}
        onDelete={() => {
          deleteAssignedActivity(deletingAssignedActivity);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.roleAssignedActivitiesTableContainer} waScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.roleAssignedActivitiesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.roleAssignedActivitiesTableHeadCell}
              >
                Feature
              </TableCell>

              <TableCell
                // align="center"
                className={classes.roleAssignedActivitiesTableHeadCell}
              >
                Status
              </TableCell>

              {roleDetail?.isComposite !== 1 && (
                <TableCell
                  align="center"
                  className={classes.roleAssignedActivitiesTableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.roleAssignedActivitiesTableBody} waScroll`}
          >
            {roleDetail?.mappedActivities?.map((assignedActivity, index) => {
              return (
                <TableRow
                  key={`${assignedActivity?.id}-${index}`}
                  className={classes.roleAssignedActivitiesTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.roleAssignedActivitiesTableBodyCell}
                  >
                    {assignedActivity?.status === "Draft" ? (
                      <Select
                        size="small"
                        style={{ fontSize: 12 }}
                        value={assignedActivity?.activityId}
                        readOnly
                      >
                        {basicReducerState?.activities.map(
                          (activity, index) => (
                            <MenuItem
                              key={`${activity?.id}-${index}`}
                              value={activity?.id}
                              style={{ fontSize: 12 }}
                            >
                              {`${activity?.name} (${getEntityNameById(
                                activity?.entityId
                              )})`}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    ) : (
                      `${assignedActivity?.name} (${getEntityNameById(
                        assignedActivity?.entityId
                      )})`
                    )}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.roleAssignedActivitiesTableBodyCell}
                  >
                    {assignedActivity?.status}
                  </TableCell>

                  {roleDetail?.isComposite !== 1 && (
                    <TableCell
                      align="center"
                      className={classes.roleAssignedActivitiesTableBodyCell}
                    >
                      <Tooltip
                        title={
                          assignedActivity?.status === "Draft"
                            ? "Remove"
                            : "Delete"
                        }
                      >
                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            // deleteAssignedActivity(assignedActivity)
                            setDeletingAssignedActivity(assignedActivity);
                          }}
                          disabled={load}
                        >
                          {assignedActivity?.status === "Draft" ? (
                            <Remove style={{ fontSize: 16 }} />
                          ) : (
                            <Delete style={{ fontSize: 16 }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {roleDetail?.isComposite !== 1 && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.roleAssignedActivitiesBottonAddButton}
          onClick={() => setOpenAssignActivityDialog(true)}
          startIcon={<Add />}
          disabled={load}
        >
          Add
        </Button>
      )}
    </>
  );
};

const NewRoleAssignedApi = ({
  open,
  onClose,
  roleDetail,
  setRoleDetail,
  params: { roleId },
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  // const { roleId } = useParams();
  const initialNewAssignedApi = {
    isActive: 1,
    apiId: "",
    createdBy: userReducerState?.user?.preferred_username,
    createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    isDeleted: 0,
    roleId: Number(roleId),
    status: "Draft",
    updatedBy: userReducerState?.user?.preferred_username,
    updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  };
  const [newAssignedApi, setNewAssignedApi] = useState(initialNewAssignedApi);
  const assignedApisId =
    roleDetail?.mappedApis?.map((assignedApi) => assignedApi?.apiId) || [];

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newRoleAssignedActivityDialogTitle}>
        New Assign Api
      </DialogTitle>

      <DialogContent>
        <FormControl
          variant="standard"
          size="small"
          fullWidth
          required
          // style={{ margin: "2px 0px" }}
        >
          <InputLabel>Api</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newAssignedApi?.apiId}
            onChange={(e) =>
              setNewAssignedApi({
                ...newAssignedApi,
                apiId: e.target.value,
              })
            }
          >
            {basicReducerState?.apis?.map(
              (api, index) =>
                !assignedApisId?.includes(api?.id) &&
                Number(api?.applicationId) ===
                  Number(roleDetail?.applicationId) && (
                  <MenuItem
                    key={`${api?.id}-${index}`}
                    value={api?.id}
                    style={{ fontSize: 12 }}
                  >
                    {api?.name}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions className={classes.newRoleAssignedActivityDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ borderRadius: 50 }}
          onClick={() => {
            onClose();
            setNewAssignedApi(initialNewAssignedApi);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            // console.log(newAssignedApi);
            setRoleDetail({
              ...roleDetail,
              mappedApis: [newAssignedApi, ...roleDetail?.mappedApis],
            });
            onClose();
            setNewAssignedApi(initialNewAssignedApi);
          }}
          style={{ borderRadius: 50 }}
          disabled={newAssignedApi?.apiId?.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RoleAssignedApis = ({
  roleDetail,
  setRoleDetail,
  load,
  setLoad,
  params: { roleId },
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [openAssignApiDialog, setOpenAssignApiDialog] = useState(false);
  const [deletingAssignedApi, setDeletingAssignedApi] = useState(null);
  // const { roleId } = useParams();
  // console.log(roleDetail?.mappedApis);

  const deleteAssignedApi = (assignedApi) => {
    if (assignedApi?.status === "Draft") {
      setRoleDetail({
        ...roleDetail,
        mappedApis:
          roleDetail?.mappedApis?.filter(
            (api) => !(api?.apiId === assignedApi?.apiId)
          ) || [],
      });
      setDeletingAssignedApi(null);
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const disableRoleMappedctivityUrl = `${process.env.REACT_APP_WAServices}/api/v1/apiPayload/roleApiMapping?id=${assignedApi?.id}`;
        const disableRoleMappedctivityRequestParam = {
          method: "Delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
          },
        };
        zipkinFetch(
          disableRoleMappedctivityUrl,
          disableRoleMappedctivityRequestParam
        )
          .then((res) => res.json())
          .then((data) => {
            setRoleDetail({
              ...roleDetail,
              mappedApis:
                roleDetail?.mappedApis?.filter(
                  (api) => api?.apiId !== assignedApi?.apiId
                ) || [],
            });
            setDeletingAssignedApi(null);
            setLoad(false);

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.message?.toString(),
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        window.location.reload(0);
      }
    }
  };
  const getApiNameByid = (apiId) => {
    const api = findApiByid(Number(apiId), basicReducerState?.apis);
    return api?.name || "-";
  };

  return (
    <>
      <NewRoleAssignedApi
        open={openAssignApiDialog}
        onClose={() => setOpenAssignApiDialog(false)}
        roleDetail={roleDetail}
        setRoleDetail={setRoleDetail}
        params={{ roleId: roleId }}
      />

      <DeletionMessageBox
        open={deletingAssignedApi ? true : false}
        onClose={() => setDeletingAssignedApi(null)}
        onDelete={() => {
          deleteAssignedApi(deletingAssignedApi);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.roleAssignedActivitiesTableContainer} waScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.roleAssignedActivitiesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.roleAssignedActivitiesTableHeadCell}
              >
                Api
              </TableCell>

              {roleDetail?.isComposite !== 1 && (
                <TableCell
                  align="center"
                  className={classes.roleAssignedActivitiesTableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.roleAssignedActivitiesTableBody} waScroll`}
          >
            {roleDetail?.mappedApis?.map((assignedApi, index) => {
              return (
                <TableRow
                  key={`${assignedApi?.id}-${index}`}
                  className={classes.roleAssignedActivitiesTableBodyRow}
                >
                  <TableCell
                    className={classes.roleAssignedActivitiesTableBodyCell}
                  >
                    {getApiNameByid(assignedApi?.apiId)}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={classes.roleAssignedActivitiesTableBodyCell}
                  >
                    <Tooltip
                      title={
                        assignedApi?.status === "Draft" ? "Remove" : "Delete"
                      }
                    >
                      <IconButton
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          // deleteAssignedApi(assignedApi);
                          setDeletingAssignedApi(assignedApi);
                        }}
                        disabled={load}
                      >
                        {assignedApi?.status === "Draft" ? (
                          <Remove style={{ fontSize: 16 }} />
                        ) : (
                          <Delete style={{ fontSize: 16 }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {roleDetail?.isComposite !== 1 && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.roleAssignedActivitiesBottonAddButton}
          onClick={() => setOpenAssignApiDialog(true)}
          startIcon={<Add />}
          disabled={load}
        >
          Add
        </Button>
      )}
    </>
  );
};

function RolesDetail({ params: { roleId }, setParams }) {
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const classes = useStyle();
  const [selectedRoleDetailContentType, setSelectedRoleDetailContentType] =
    useState("Basic Info");
  const [load, setLoad] = useState(false);
  const [roleDetail, setRoleDetail] = useState({});
  const history = useHistory();
  // const { roleId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (roleId) {
      getRoleInfoById();
    }
  }, [roleId]);

  const getRoleInfoById = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const getRoleByIdUrl = `${process.env.REACT_APP_WAServices}/api/v1/roles/${roleId}`;
      const getRoleByIdRequestParam = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      const getRoleMappedUsersUrl = `${process.env.REACT_APP_WAServices}/api/v1/roles/userRoleMapping/${roleId}`;
      const getRoleMappedUsersRequestParam = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      const getRoleMappedApisUrl = `${process.env.REACT_APP_WAServices}/api/v1/apiPayload/roleApiMapping/roleId/${roleId}`;
      const getRoleMappedApisRequestParam = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      Promise.all([
        zipkinFetch(getRoleByIdUrl, getRoleByIdRequestParam).then((res) =>
          res.json()
        ),
        zipkinFetch(getRoleMappedUsersUrl, getRoleMappedUsersRequestParam).then(
          (res) => res.json()
        ),
        // zipkinFetch(
        //   getRoleMappedActivitiesUrl,
        //   getRoleMappedActivitiesRequestParam
        // ).then((res) => res.json()),
        zipkinFetch(getRoleMappedApisUrl, getRoleMappedApisRequestParam).then(
          (res) => res.json()
        ),
      ])
        .then(([roleDetail, roleMappedUsersDetail, roleMappedApisDetail]) => {
          const getRoleMappedActivitiesUrl = `${process.env.REACT_APP_WAServices}/api/v1/activities/role`;
          var roleIdList = [];
          // const roleDet = findRoleById(
          //   Number(roleId),
          //   basicReducerState?.roles
          // );
          if (roleDetail?.data?.isComposite === 1) {
            const rolesIdList =
              roleDetail?.data?.associateRoles?.split(",") || [];
            rolesIdList?.map((id) => {
              if (id !== null && id !== "null") {
                roleIdList.push(id);
              }
              return null;
            });
          } else {
            roleIdList.push(roleId);
          }
          const getRoleMappedActivitiesPayload = {
            roleId: roleIdList,
          };
          const getRoleMappedActivitiesRequestParam = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userReducerState.token}`,
            },
            body: JSON.stringify(getRoleMappedActivitiesPayload),
          };
          zipkinFetch(
            getRoleMappedActivitiesUrl,
            getRoleMappedActivitiesRequestParam
          )
            .then((res) => res.json())
            .then((roleMappedActivitiesDetail) => {
              setLoad(false);
              const associateRolesId =
                roleDetail?.data?.associateRoles?.length === 0
                  ? []
                  : roleDetail?.data?.associateRoles
                      ?.split(",")
                      .map((id) => Number(id));
              setRoleDetail({
                ...roleDetail?.data,
                associateRoles:
                  associateRolesId?.map((roleId) =>
                    findRoleById(roleId, basicReducerState?.roles)
                  ) || [],
                mappedUsers: roleMappedUsersDetail?.data || [],
                mappedActivities: roleMappedActivitiesDetail?.data || [],
                mappedApis: roleMappedApisDetail?.data || [],
              });
              dispatch(
                setRoles(
                  basicReducerState?.roles?.map((role) =>
                    Number(role?.id) === Number(roleId)
                      ? {
                          ...role,
                          name: roleDetail?.data?.name,
                          label: roleDetail?.data?.label,
                          description: roleDetail?.data?.description,
                        }
                      : role
                  )
                )
              );
            })
            .catch((err) => {
              setLoad(false);
            });
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };
  const updateRoleInfo = () => {
    if (authVerify(userReducerState.token)) {
      // setLoad(true);
      const updateRoleUrl = `${process.env.REACT_APP_WAServices}/api/v1/roles/modify`;
      const updateRolePayload = {
        updatedBy: userReducerState?.user?.preferred_username,
        description: roleDetail?.description,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        label: roleDetail?.label,
        isActive: 1,
        createdOn: moment(new Date(roleDetail?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        noOfExpiryDays: roleDetail?.noOfExpiryDays,
        isDeleted: 0,
        hasExpiry: roleDetail?.hasExpiry ? 1 : 0,
        expiryMailTriggerDays: roleDetail?.expiryMailTriggerDays,
        createdBy: roleDetail?.createdBy,
        name: roleDetail?.name,
        id: Number(roleId),
        applicationId: roleDetail?.applicationId,
        status: "Active",
        isComposite: roleDetail?.isComposite,
        associateRoles: roleDetail?.associateRoles
          ?.map((role) => role?.id)
          ?.join(","),
      };
      const updateRoleRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(updateRolePayload),
      };
      const updatedMappedUsersAndActivitiesUrl = `${process.env.REACT_APP_WAServices}/api/v1/roles/mapActivitiesAndUsers`;
      const updateMappedUsersAndActivitiesPayload = {
        userRoleMappings:
          roleDetail?.mappedUsers?.filter(
            (mappedUser) => mappedUser?.status === "Draft"
          ) || [],
        activityRoleMappings:
          roleDetail?.mappedActivities?.filter(
            (mappedActivity) => mappedActivity?.status === "Draft"
          ) || [],
      };
      const updateMappedUsersAndActivitiesRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(updateMappedUsersAndActivitiesPayload),
      };
      const updateMappedApisUrl = `${process.env.REACT_APP_WAServices}/api/v1/apiPayload/roleApiMapping`;
      const updateMappedApisPayload =
        roleDetail?.mappedApis?.filter((api) => api?.status === "Draft") || [];
      const updateMappedApisRequestParams = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(updateMappedApisPayload),
      };
      // console.log(updateMappedApisPayload);
      Promise.all([
        zipkinFetch(updateRoleUrl, updateRoleRequestParam).then((res) =>
          res.json()
        ),
        zipkinFetch(
          updatedMappedUsersAndActivitiesUrl,
          updateMappedUsersAndActivitiesRequestParam
        ).then((res) => res.json()),
        zipkinFetch(updateMappedApisUrl, updateMappedApisRequestParams).then(
          (res) => res.json()
        ),
      ])
        .then(
          ([
            updateRoleData,
            updatedMappedUsersAndActivitiesData,
            updateMappedApisData,
          ]) => {
            setLoad(false);
            getRoleInfoById();

            dispatch(
              setResponseMessage({
                open: true,
                status:
                  updateRoleData?.status &&
                  updatedMappedUsersAndActivitiesData?.status &&
                  updateMappedApisData?.status
                    ? "success"
                    : "error",
                message:
                  updateRoleData?.message?.toString() +
                  ", " +
                  updatedMappedUsersAndActivitiesData?.message?.toString() +
                  ", " +
                  updateMappedApisData?.message?.toString(),
              })
            );
          }
        )
        .catch((err) => {
          setLoad(false);
        });
    } else {
      window.location.reload(0);
    }
  };

  return (
    <Paper className={`${classes.roleDetailContainer} waScroll`}>
      <Loading load={load} />

      <div className={classes.roleDetailCrossButtonContainer}>
        <Close
          style={{ fontSize: 16, cursor: "pointer" }}
          onClick={() => {
            // history.push(`/roles`);
            setParams({});
          }}
        />
      </div>

      <div className={classes.roleDetailHeaderContainer}>
        <Typography
          className={`${classes.roleDetailHeaderItem} ${
            selectedRoleDetailContentType === "Basic Info" &&
            classes.roleDetailHeaderItemSelected
          }`}
          onClick={() => setSelectedRoleDetailContentType("Basic Info")}
        >
          Basic Info
        </Typography>

        {roleDetail?.isComposite === 1 && (
          <Typography
            className={`${classes.roleDetailHeaderItem} ${
              selectedRoleDetailContentType === "Associate Roles" &&
              classes.roleDetailHeaderItemSelected
            }`}
            onClick={() => setSelectedRoleDetailContentType("Associate Roles")}
          >
            Associate Roles
          </Typography>
        )}

        <Typography
          className={`${classes.roleDetailHeaderItem} ${
            selectedRoleDetailContentType === "Assigned Users" &&
            classes.roleDetailHeaderItemSelected
          }`}
          onClick={() => setSelectedRoleDetailContentType("Assigned Users")}
        >
          Assigned Users
        </Typography>

        <Typography
          className={`${classes.roleDetailHeaderItem} ${
            selectedRoleDetailContentType === "Assigned Features" &&
            classes.roleDetailHeaderItemSelected
          }`}
          onClick={() => setSelectedRoleDetailContentType("Assigned Features")}
        >
          Assigned Features
        </Typography>

        <Typography
          className={`${classes.roleDetailHeaderItem} ${
            selectedRoleDetailContentType === "Assigned Apis" &&
            classes.roleDetailHeaderItemSelected
          }`}
          onClick={() => setSelectedRoleDetailContentType("Assigned Apis")}
        >
          Assigned Apis
        </Typography>
      </div>

      {selectedRoleDetailContentType === "Basic Info" && (
        <RoleInfo
          roleDetail={roleDetail}
          setRoleDetail={setRoleDetail}
          params={{ roleId: roleId }}
        />
      )}

      {roleDetail?.isComposite === 1 &&
        selectedRoleDetailContentType === "Associate Roles" && (
          <RoleAssociateRoles
            roleDetail={roleDetail}
            setRoleDetail={setRoleDetail}
            getRoleInfoById={getRoleInfoById}
            load={load}
            setLoad={setLoad}
            params={{ roleId: roleId }}
          />
        )}

      {selectedRoleDetailContentType === "Assigned Users" && (
        <RoleAssignedUsers
          roleDetail={roleDetail}
          setRoleDetail={setRoleDetail}
          load={load}
          setLoad={setLoad}
          params={{ roleId: roleId }}
        />
      )}

      {selectedRoleDetailContentType === "Assigned Features" && (
        <RoleAssignedActivities
          roleDetail={roleDetail}
          setRoleDetail={setRoleDetail}
          load={load}
          setLoad={setLoad}
          params={{ roleId: roleId }}
        />
      )}

      {selectedRoleDetailContentType === "Assigned Apis" && (
        <RoleAssignedApis
          roleDetail={roleDetail}
          setRoleDetail={setRoleDetail}
          load={load}
          setLoad={setLoad}
          params={{ roleId: roleId }}
        />
      )}

      <div className={classes.roleDetailFooter}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.roleDetailFooterButton}
          onClick={updateRoleInfo}
          disabled={
            load ||
            basicReducerState?.roles?.find(
              (role) =>
                role?.name === roleDetail?.name &&
                role?.applicationId === roleDetail?.applicationId &&
                Number(role?.id) !== Number(roleId)
            )
          }
        >
          Submit
        </Button>
      </div>
    </Paper>
  );
}

export default RolesDetail;
